import { collection, onSnapshot, query, doc, addDoc, deleteDoc, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { CanteenContext } from "./CanteenContext";
import { EventsContext } from "./EventsContext";

export interface ISliderProps {
  loading: boolean;
  slidesEvents: ISlide[];
  updateSliderStatus: (itemId: string, type: string) => Promise<void>;
  deleteItemToSlider: (itemId: string) => Promise<void>;
}

type ISlide = {
  id: string;
  eventId: string;
  src: any;
  title: string;
  subtitle: string;
  offer: string;
  price: any;
  place: string;
  route: string;
  btnText: string;
  type: "eventBmc" | "lobby" | "offer"| "business";
};

export const SliderContext = createContext<ISliderProps>(null);

export const SliderProvider: any = ({ children }: any) => {
  const [loading, setLoading] = useState(true);
  const [slides, setSlides] = useState(null);
  const [slidesEvents, setSlidesEvents] = useState<ISlide[]>([]);
  var yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());

  const now = new Date(yesterday).setHours(23, 59, 0, 0);

  const { db } = useContext(FirebaseContext);
  const { eventsBmc } = useContext(EventsContext);
  const { events } = useContext(EventsContext);
  const { offers } = useContext(CanteenContext);
  
  // const checkIfExist = (data) => {
  //   switch (data?.type) {
  //     case "offer":
  //       if (offers.find((offer) => offer.id === data.eventId)) return true;
  //       else return false;
  //     case "lobby":
  //       if (lobbyEvents.find((offer) => offer.id === data.eventId)) return true;
  //       else return false;
  //     case "eventBmc":
  //       if (eventsBmc.find((offer) => offer.id === data.eventId)) return true;
  //       else return false;
  //   }
  // };

  useEffect(() => {
    try {
      const q = query(collection(db, "slider-events"));
      onSnapshot(q, async (querySnapshot) => {
        const arr = [];

        querySnapshot.forEach(async (doc: any) => {
          const data = { ...doc.data(), id: doc.id };
          // const exist = checkIfExist(data);

          // if (exist) {
          arr.push(data);
          // }
        });

        setSlidesEvents(arr);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, [db]);

  const getSlidesToShow = (type: string, eventId: string) => {
    switch (type) {
      case "offer":
        const offersToAdapt = offers.find((doc) => doc.id === eventId);

        if (offersToAdapt) {
          const slideAdapted = {
            eventId: eventId,
            src: offersToAdapt?.image,
            title: offersToAdapt?.title,
            subtitle: "¡No te lo pierdas!",
            offer: "Oferta Cafetería",
            price: null,
            place: "Cafetería",
            route: "/canteen",
            btnText: "Ver ofertas",
          };
          return slideAdapted;
        }

        break;
      case "eventBmc":
        const eventToAdapt = eventsBmc.find((doc) => doc.id === eventId);

        if (eventToAdapt) {
          const slideAdapted = {
            eventId: eventToAdapt?.id,
            src: eventToAdapt?.image,
            title: eventToAdapt?.title,
            subtitle: eventToAdapt?.description,
            offer: "Evento BMC",
            price: null,
            place: "Edificio BMC",
            route: "/bmc-events",
            btnText: "Ver eventos",
          };
          return slideAdapted;
        }

        break;
      case "lobby":
        const lobbyToAdapt = events.find((doc) => doc.id === eventId);
        if (lobbyToAdapt) {
          const slideAdapted = {
            eventId: lobbyToAdapt?.id,
            src: lobbyToAdapt?.image,
            title: lobbyToAdapt?.title,
            subtitle: lobbyToAdapt?.description,
            offer: "Evento Lobby",
            price: null,
            place: "Restaurante",
            route: "/events-business",
            btnText: "Ver eventos",
          };
          return slideAdapted;
        }

        break;
      case "business":
        const businessToAdapt = events.find((doc) => doc.id === eventId);
        if (businessToAdapt) {
          const slideAdapted = {
            eventId: businessToAdapt?.id,
            src: businessToAdapt?.image,
            title: businessToAdapt?.title,
            subtitle: businessToAdapt?.description,
            offer: "Evento Empresa",
            price: null,
            place: "BMC",
            route: "/events-business",
            btnText: "Ver eventos",
          };
          return slideAdapted;
        }

        break;
    }

    setLoading(false);
  };

  const updateSliderStatus = async (itemId: string, type: string) => {
    const event: any = getSlidesToShow(type, itemId);
    try {
      await addDoc(collection(db, "slider-events"), { ...event, type: type });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItemToSlider = async (itemId: string) => {
    try {
      await deleteDoc(doc(db, "slider-events", itemId));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SliderContext.Provider
      value={{
        loading,
        slidesEvents,
        updateSliderStatus,
        deleteItemToSlider,
      }}
    >
      {children}
    </SliderContext.Provider>
  );
};
