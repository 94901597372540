import Layout from "../components/Layout";
import styled from "@emotion/styled";
import HomeHero from "../components/Home/HomeHero";
import { Text } from "../genericComponents";
import Step1 from "../icons/step1";
import Step2 from "../icons/step2";
import Step3 from "../icons/step3";
import Segurity from "../icons/segurity";
import Colaboration from "../icons/colaboration";
import Services from "../icons/services";
import Networking from "../icons/networking";
import { CardRow } from "./Services";
import CardWithImages from "../components/CardWithImages";
import { useContext } from "react";
import { SliderContext } from "../context/SliderContext";
import CarouselHome from "../components/Home/CarouselHome";
import Banner from "../components/Banner";
import { useNavigate } from "react-router";
import LazyImage from "../components/LazyImage";
import axios from "axios";
import { userPassword, usersBMC, userSended } from "../interfaces/constants/business";
import { BusinessContext } from "../context/BusinessContext";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../fb/config";
import { getFunctions, httpsCallable } from "firebase/functions";

const Container = styled.div``;
const NoSliderImage = styled.div<{ img }>`
  height: 414px;
  width: 100%;
  background: ${(props) => props && `url(${props.img})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
export const Footer = styled.div`
  padding-bottom: 250px;
  background: url("https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/home%2Fpie_home.jpg?alt=media&token=f5617aa8-7c9f-4fed-91a6-0fb99a139822");
  background-size: contain;
  width:100%;
  background-repeat:no-repeat;
`;
const Card = styled.div`
  background-color: #fff;
  padding: 40px 20px;
`;
const StepsCard = styled.div`
  background-color: #f6f6f6;

  min-height: 306px;
  width: 100%;
  padding-bottom: 30px;
`;
const OportunitiesCard = styled.div`
  background-color: #fff;

  min-height: 306px;
  width: 100%;
  padding-bottom: 30px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 15px auto;

  svg {
    margin-right: 15px;
  }
`;
const OpporItem = styled.div<{ reversed?: boolean }>`
  width: 40%;
  min-height: 127px;
  margin: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.reversed ? "#FFFFFF" : "#5B656E")};
  color: ${(props) => (props.reversed ? "#5B656E" : "#FFFFFF")};
  border: ${(props) => props.reversed && "0.3px solid #5B656E"};
  box-shadow: 0px 1px 3px 1px #00000012;
`;
const ImageContainer = styled.div<{ img }>`
  min-height: 203px;
  width: 100%;
  background: ${(props) => props && `url(${props.img})`};
  background-size: cover;
  background-repeat: no-repeat;
`;
const ContainerOppor = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;
const ContainerServices = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: #f6f6f6;
`;

const Home = () => {
  const { slidesEvents } = useContext(SliderContext);
  const navigation = useNavigate();

  const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);

  const sendNewUserMail = httpsCallable(functions, "sendNewUserMail");

  const steps = [
    {
      title: "1. Entra en la app con tu usuario registrado.",
      icon: <Step1 width="35px" />,
    },
    {
      title: "2. Abre el lector de la app BMC y lee el QR ubicado en las entradas.",
      icon: <Step2 width="35px" />,
    },
    {
      title: "3. Espera a que el mecanismo se abra. ¡Es inmediato!",
      icon: <Step3 width="35px" />,
    },
  ];
  const opportunies = [
    { title: "Mayor seguridad en el edificio", icon: <Segurity />, id: 1 },
    {
      title: "Colaboración entre empresas",
      icon: <Colaboration />,
      id: 2,
    },
    {
      title: "Accesibilidad a los servicios",
      icon: <Services />,
      id: 3,
    },
    {
      title: "Oportunidad de networking",
      icon: <Networking />,
      id: 4,
    },
  ];

  const sections = [
    {
      title: "PAQUETERÍA",
      subtitle: "Consultar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/home%2Fdes_paqueteria.jpg?alt=media&token=c7af1092-e716-4fe5-b8a6-8547b13c49cb",
      route: "/parcel",
    },
    {
      title: "MANTENIMIENTO",
      subtitle: "Solicitar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/des_mantenimiento.jpg?alt=media&token=724c90f9-d507-47b7-8019-3423cf1af87e",
      route: "/maintenance",
    },
    {
      title: "CAFETERÍA",
      subtitle: "Ver ofertas",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2FCAFETERIA.jpg?alt=media&token=f7ca548c-e0c9-498e-86d3-47894954ec48",
      route: "/canteen",
    },
    {
      title: "CARSHARING",
      subtitle: "Apuntarse",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/home%2Fdes_carsharing.jpg?alt=media&token=cad95dff-176e-43b3-adc6-cc769c2204cf",
      route: "/carsharing",
    },
  ];

  const getArrayLocal = (arrayLocal: string) => {
    let array = arrayLocal.split("-");
    return array[0];
  };

  const createTestUsers = async () => {
    // [`LOCAL`,`PLANTA`,`EMPRESA`,`CIF`,`AUTORITZAT`,`EMAIL`,`RESPONSABLE`]

  

    userPassword.map(async (dataUserPassword, idx) => {
      const detected = userSended.find((userSend) => userSend.indexOf(dataUserPassword[1]) !== -1);
      if (!detected) {
        const newUser = {
          name: dataUserPassword[0],
          email: dataUserPassword[1],
          password: dataUserPassword[2],
        };

        const userDB = await sendNewUserMail(newUser);
        // const userDB = await axios.post("https://us-central1-bmc-app-87a78.cloudfunctions.net/createUser", newUser);
        // const userDB = await axios.post("https://us-central1-bmc-app-87a78.cloudfunctions.net/sendNewUserMail", newUser);
        console.log(`Enviado usuario ${idx}`, userDB);
      } else {
        console.log("Detected: ", dataUserPassword);
      }
    });
  };

  return (
    <Layout>
      <Container>
        <HomeHero />
        {slidesEvents?.length > 0 ? (
          <CarouselHome />
        ) : (
          <LazyImage
            animation="wave"
            variant="rectangular"
            width="100%"
            height="414px"
            src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/home%2Fslide_estatico.jpg?alt=media&token=a92238b9-b0e0-42ca-82a1-8ba77b097032"
          />
        )}
        <Card>
          <Text size="20px" align="center" weight="200" color="#5F5F5F">
            TU EMPRESA, EN EL LUGAR MÁS SEGURO
          </Text>
        </Card>
        <StepsCard>
          <LazyImage
            animation="wave"
            variant="rectangular"
            width="100%"
            height="203px"
            src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/home%2Ffoto1.jpg?alt=media&token=0fd83c83-ad0f-45d8-87eb-56367efbda92"
          />
          <Text width="83%" left="auto" right="auto" top="30px" size="20px" align="center" color="#5F5F5F" weight="200">
            ¿CÓMO ACCEDER AL EDIFICIO Y A LOS SERVICIOS?
          </Text>
          {steps.map((step, idx) => {
            return (
              <Row key={step.title + idx}>
                {step.icon}
                <Text width="80%" weight="200" size="14px" left="auto" align="left">
                  {step.title}
                </Text>
              </Row>
            );
          })}
        </StepsCard>
        <Banner title="¿Necesitas invitar a alguien a entrar al edificio?" onClickCTA={() => navigation("/create-invitation")} textCta="Crear invitación" />
        <OportunitiesCard>
          <Text width="83%" left="auto" right="auto" top="30px" size="20px" align="center" color="#5F5F5F" weight="200">
            ¿QUÉ ME APORTA LA APP BMC?
          </Text>
          <ContainerOppor>
            {opportunies.map((doc) => {
              return (
                <OpporItem key={doc.title + doc.id} reversed={doc.id === 2 || doc.id === 3 ? true : false}>
                  {doc.icon}
                  <Text width="83%" left="auto" right="auto" top="10px" size="14px" align="center">
                    {doc.title}
                  </Text>
                </OpporItem>
              );
            })}
          </ContainerOppor>
        </OportunitiesCard>
        <ContainerServices>
          <Text width="83%" left="auto" right="auto" top="30px" size="20px" align="center" color="#5F5F5F" weight="200">
            NUESTROS SERVICIOS DESTACADOS
          </Text>
          <CardRow>
            {sections.map((section, i) => {
              return <CardWithImages key={`id-${section.route}-${i}`} item={section} />;
            })}
          </CardRow>
        </ContainerServices>
      
      </Container>
    </Layout>
  );
};

export default Home;
