import styled from "@emotion/styled";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";

import ParcelWorker from "./ParcelWorker";

const ParcelContainer = styled.div`
  padding-bottom: 150px;
  background-color:#F6F6F6;
  height:100%;
`;

const Parcel = () => {

  return (
    <Layout>
      <ParcelContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fcabecera_paqueteria.jpg?alt=media&token=b5dc7fef-8e0d-43c8-882b-e57e97da1937"
          title="PAQUETERÍA"
          subtitle="Tus pedidos están seguros en nuestras manos. Aquí tienes los paquetes pendientes de recoger en recepción."
          showBackButton
        />
       <ParcelWorker/>
      </ParcelContainer>
    </Layout>
  );
};

export default Parcel;
