import React from "react";

const BmcNavIcon = ({fill} :{fill?:string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.128"
      height="17.524"
      viewBox="0 0 32.128 17.524"
      fill={fill && fill}
    >
      <g id="Grupo_7" data-name="Grupo 7" transform="translate(-114 -772)">
        <g id="Grupo_6" data-name="Grupo 6" transform="translate(114 772)">
          <g
            id="Rectángulo_86"
            data-name="Rectángulo 86"
            transform="translate(7.789 2.921)"
            fill="none"
            stroke="#acacac"
            strokeWidth="2"
          >
            <rect width="16.551" height="14.604" stroke="none" />
            <rect x="1" y="1" width="14.551" height="12.604" fill="none" />
          </g>
          <rect
            id="Rectángulo_87"
            data-name="Rectángulo 87"
            width="10.709"
            height="17.524"
            fill={fill && fill}
          />
          <rect
            id="Rectángulo_88"
            data-name="Rectángulo 88"
            width="10.709"
            height="17.524"
            transform="translate(21.419)"
            fill={fill && fill}
          />
          <rect
            id="Rectángulo_90"
            data-name="Rectángulo 90"
            width="1.947"
            height="0.974"
            transform="translate(3.894 2.921)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_108"
            data-name="Rectángulo 108"
            width="1.947"
            height="0.974"
            transform="translate(22.392 2.921)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_92"
            data-name="Rectángulo 92"
            width="1.947"
            height="0.974"
            transform="translate(3.894 5.841)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_107"
            data-name="Rectángulo 107"
            width="1.947"
            height="0.974"
            transform="translate(22.392 5.841)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_94"
            data-name="Rectángulo 94"
            width="1.947"
            height="0.974"
            transform="translate(3.894 8.762)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_104"
            data-name="Rectángulo 104"
            width="1.947"
            height="0.974"
            transform="translate(22.392 8.762)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_96"
            data-name="Rectángulo 96"
            width="1.947"
            height="0.974"
            transform="translate(3.894 11.683)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_103"
            data-name="Rectángulo 103"
            width="1.947"
            height="0.974"
            transform="translate(22.392 11.683)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_98"
            data-name="Rectángulo 98"
            width="1.947"
            height="0.974"
            transform="translate(3.894 14.604)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_100"
            data-name="Rectángulo 100"
            width="1.947"
            height="0.974"
            transform="translate(22.392 14.604)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_91"
            data-name="Rectángulo 91"
            width="1.947"
            height="0.974"
            transform="translate(7.789 2.921)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_109"
            data-name="Rectángulo 109"
            width="1.947"
            height="0.974"
            transform="translate(26.286 2.921)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_93"
            data-name="Rectángulo 93"
            width="1.947"
            height="0.974"
            transform="translate(7.789 5.841)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_106"
            data-name="Rectángulo 106"
            width="1.947"
            height="0.974"
            transform="translate(26.286 5.841)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_95"
            data-name="Rectángulo 95"
            width="1.947"
            height="0.974"
            transform="translate(7.789 8.762)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_105"
            data-name="Rectángulo 105"
            width="1.947"
            height="0.974"
            transform="translate(26.286 8.762)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_97"
            data-name="Rectángulo 97"
            width="1.947"
            height="0.974"
            transform="translate(7.789 11.683)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_102"
            data-name="Rectángulo 102"
            width="1.947"
            height="0.974"
            transform="translate(26.286 11.683)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_99"
            data-name="Rectángulo 99"
            width="1.947"
            height="0.974"
            transform="translate(7.789 14.604)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_101"
            data-name="Rectángulo 101"
            width="1.947"
            height="0.974"
            transform="translate(26.286 14.604)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default BmcNavIcon;
