import { collection, onSnapshot, query, where, addDoc, orderBy, updateDoc, doc, getDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { IBusiness } from "../interfaces/IBusiness";
import { FirebaseContext } from "./FirebaseContext";

export interface IMaintenanceProps {
  tickets: any;
  allTickets: {
    id: any;
    tickets: {
      pending: IDoc[];
      progress: IDoc[];
      closed: IDoc[];
    };
    businessData: IBusiness;
  }[];
  allTicketsList: IDoc[];
  loading: boolean;
  addTicket: (data: IDoc) => Promise<void>;
  updateTicketStatus: (status: string, id: string) => Promise<void>;
}
export type IDoc = {
  id?: string;
  reason: string;
  localNum: string;
  userId: string;
  businessId: string;
  timestamp: number;
  lastUpdate?: number;
  status: "pending" | "progress" | "closed" | "fault" | "accept" | "material" | "budget";
};

export const MaintenanceContext = createContext<IMaintenanceProps>(null);

export const MaintenanceProvider: any = ({ children }: any) => {
  const [tickets, setTickets] = useState(null);
  const [allTickets, setAllTickets] = useState([]);
  const [allTicketsList, setAllTicketsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const { db, user } = useContext(FirebaseContext);

  const getTicketsByBusiness = async () => {
    if (user) {
      const q = query(collection(db, "tickets"), where("businessId", "==", user.businessId), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const progressTickets = arr.filter((doc) => doc.status === "progress" || doc.status === "fault" || doc.status === "material" || doc.status === "accept" || doc.status === "budget");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setTickets({
          pending: pendingTickets,
          progress: progressTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getAllTickets = async () => {
    if (user) {
      const q = query(collection(db, "tickets"), orderBy("timestamp", "desc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        const businessId = [];
        arr.forEach((doc) => {
          if (businessId?.length === 0) businessId.push(doc.businessId);
          if (businessId?.length > 0 && !businessId.find((id) => id === doc.businessId)) {
            businessId.push(doc.businessId);
          }
        });

        const ticketsByBusiness =
          businessId?.length > 0 &&
          (await Promise.all(
            businessId.map(async (id) => {
              const filtered = arr.filter((doc) => id === doc.businessId);
              const pendingTickets = filtered.filter((doc) => doc.status === "pending");
              const progressTickets = filtered.filter((doc) => doc.status === "progress" || doc.status === "fault" || doc.status === "material" || doc.status === "accept" || doc.status === "budget");
              const closedTickets = filtered.filter((doc) => doc.status === "closed");
              const businessData = await getBusinessData(id);

              return {
                id: id,
                tickets: {
                  pending: pendingTickets,
                  progress: progressTickets,
                  closed: closedTickets,
                },
                businessData: businessData,
              };
            })
          ));

        setAllTickets(ticketsByBusiness);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getAllTicketsList = async () => {
    if (user) {
      const q = query(collection(db, "tickets"), orderBy("timestamp", "desc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        setAllTicketsList(arr);
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const addTicket = async (data: IDoc) => {
    try {
      await addDoc(collection(db, "tickets"), {
        reason: data.reason,
        localNum: data.localNum,
        userId: data.userId,
        businessId: data.businessId,
        timestamp: data.timestamp,
        status: data.status,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getBusinessData = async (id: string) => {
    const docRef = doc(db, "business", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  };

  const updateTicketStatus = async (status: string, id: string) => {
    try {
      await updateDoc(doc(db, "tickets", id), {
        status: status,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTicketsList();
    getTicketsByBusiness();
    getAllTickets();
  }, [user]);

  return (
    <MaintenanceContext.Provider
      value={{
        tickets,
        allTickets,
        allTicketsList,
        loading,
        addTicket,
        updateTicketStatus,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};
