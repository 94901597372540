const ServicesIcon = ({fill} :{fill?:string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.341"
      height="20.341"
      viewBox="0 0 20.341 20.341"
      fill={fill && fill}
    >
      <path
        id="dataset_FILL0_wght400_GRAD0_opsz48"
        d="M7.7,26.341a1.738,1.738,0,0,1-1.7-1.7V7.7A1.738,1.738,0,0,1,7.7,6H24.646a1.738,1.738,0,0,1,1.7,1.7V24.646a1.738,1.738,0,0,1-1.7,1.7Zm0-1.7H24.646V7.7H7.7V24.646ZM7.7,7.7h0V24.646h0V7.7ZM10.8,14.758h3.955V10.8H10.8Zm6.78,0h3.955V10.8H17.583Zm-6.78,6.78h3.955V17.583H10.8Zm6.78,0h3.955V17.583H17.583Z"
        transform="translate(-6 -6)"
        fill={fill && fill}
      />
    </svg>
  );
};

export default ServicesIcon;
