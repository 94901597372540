import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { BorderBottom, Text } from "../../../genericComponents";

const ContainerMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  bottom: -75px;

  right: 0;
  width: 124px;
  height: 80px;
  background-color: #215f8d;
  box-shadow: 0px 2px 5px 2px #00000029;
`;
const Item = styled.div`
  padding: 10px;
`;

const Submenu = ({
  showSubmenu,
  onClickOutside,
}: {
  showSubmenu: boolean;
  onClickOutside: any;
}) => {
  const navigation = useNavigate();

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);
  return (
    <>
      {showSubmenu && (
        <ContainerMenu ref={ref}>
          <Item onClick={() => navigation("/account")}>
            <Text size="14px" color="#fff" weight="200">
              Mi cuenta
            </Text>
          </Item>
          <BorderBottom
            style={{ width: "90%", margin: "0 auto", paddingBottom: "0" }}
            color="#fff"
          />

          <Item onClick={() => navigation("/notis")}>
            <Text size="14px" color="#fff" weight="200">
              Notificaciones
            </Text>
          </Item>
        </ContainerMenu>
      )}
    </>
  );
};

export default Submenu;
