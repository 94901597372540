import { useContext } from "react";
import CardWithImages from "../../components/CardWithImages";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { FirebaseContext } from "../../context/FirebaseContext";
import { CardRow } from "../Services";
import styled from "@emotion/styled";

const Container = styled.div`
  padding-bottom: 150px;
`;

const AdminPanel = () => {
  const { user } = useContext(FirebaseContext);

  const services = [
    user?.roles?.super && {
      title: "USUARIOS",
      subtitle: "Gestionar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_usuarios.jpg?alt=media&token=91900500-8394-423e-a34c-a911df21af00",
      route: "/users-panel",
    },
    user?.roles?.super && {
      title: "EMPRESAS",
      subtitle: "Gestionar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_empresas.jpg?alt=media&token=98449861-0948-4fa7-bb47-7163c65dff69",
      route: "/business-panel",
    },
    user?.roles?.super && {
      title: "ESTADÍSTICAS",
      subtitle: "Ver",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_estadistica.jpg?alt=media&token=4dce7745-5a44-4a4c-86e6-5dbbaa78bb05",
      route: "/statistics",
    },
    (user?.roles?.maintenance || user?.roles?.super) && {
      title: "MANTENIMIENTO",
      subtitle: "Gestionar citas",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/des_mantenimiento.jpg?alt=media&token=724c90f9-d507-47b7-8019-3423cf1af87enpm",
      route: "/maintenance-admin",
    },
    user?.roles?.super && {
      title: "RESERVA SALAS",
      subtitle: "Ver reservas",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fdes_salas.jpg?alt=media&token=3f4a1ecb-ab67-483b-b6b7-8f26f3647920",
      route: "/salas-admin",
    },
    (user?.roles?.super || user?.roles?.canteen) && {
      title: "SLIDER",
      subtitle: "Configurar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_slide.jpg?alt=media&token=1a7fcf7f-d768-4bb1-9ef6-6ca6a43e7b9b",
      route: "/slider-admin",
    },
    (user?.roles?.super) && {
      title: "CATEGORIAS",
      subtitle: "Configurar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/categorias.png?alt=media&token=51ffa220-a2a4-474d-98d1-63d3278d6cbb",
      route: "/categories",
    },
    user?.roles?.super && {
      title: "EVENTOS BMC",
      subtitle: "Gestionar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_eventosBMC.jpg?alt=media&token=152cca1c-f778-43a6-bcdd-09adcd2a71bd",
      route: "/events-bmc-admin",
    },
    // user?.roles?.super && {
    //   title: "EVENTOS WEB BMC",
    //   subtitle: "Gestionar",
    //   img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_eventosBMC.jpg?alt=media&token=152cca1c-f778-43a6-bcdd-09adcd2a71bd",
    //   route: "/events-bmc-web-admin",
    // },
    (user?.roles?.parcel || user?.roles?.super) && {
      title: "PAQUETERÍA",
      subtitle: "Gestionar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fdes_paqueteria.jpg?alt=media&token=46e4b2eb-cd9a-49af-af1f-022876130b63",
      route: "/parcel-admin",
    },
    (user?.roles?.parcel || user?.roles?.super) && {
      title: "PAQUETERÍA CLIENTES",
      subtitle: "Gestionar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fdes_paqueteria.jpg?alt=media&token=46e4b2eb-cd9a-49af-af1f-022876130b63",
      route: "/parcel-admin-clients",
    },

    (user?.roles?.canteen || user?.roles?.super) && {
      title: "CAFETERÍA",
      subtitle: "Gestionar Ofertas",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2FCAFETERIA.jpg?alt=media&token=f7ca548c-e0c9-498e-86d3-47894954ec48",
      route: "/canteen-admin",
    },
    // roles?.super && {
    //   title: "RESTAURANTE",
    //   subtitle: "Ver menú",
    //   img: "/restaurant.jpeg",
    //   route: "/restaurant",
    // },
    (user?.roles?.gym || user?.roles?.super) && {
      title: "GIMNASIO",
      subtitle: "Gestionar eventos",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_gimnasio.jpg?alt=media&token=a169ae55-d6a4-4721-b355-1f065ffffef0",
      route: "/gym-admin",
    },
  ];
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fcabecera_administrador.jpg?alt=media&token=d09aabd2-6adb-4349-9350-8dec61786e26"
          title="ADMINISTRADOR"
          subtitle="Bienvenido a tu pantalla de administrador. Gestiona, modifica, crea o elimina cualquier dato que necesites."
          showBackButton
        />
        <CardRow>
          {services.map((service, i) => {
            return (
              <CardWithImages
                key={`id-${service?.route}-${i}`}
                item={service}
              />
            );
          })}
        </CardRow>
      </Container>
    </Layout>
  );
};

export default AdminPanel;
