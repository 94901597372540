import styled from "@emotion/styled";
import { Text } from "../../../genericComponents";
import SalasDisplayer from "../SalasDisplayer";

const Container = styled.div``;

const SalaAdmin = () => {
  const enabled = true;
  return (
    <Container>
      {enabled ? <SalasDisplayer admin={true} />: <Text align="center" top='20px' weight="200">PRÓXIMAMENTE...</Text>}
    </Container>
  );
};

export default SalaAdmin;
