import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { Text } from "../../../genericComponents";

const Container = styled.div`
  background-color: white;
  position: relative;
  padding-bottom: 150px;
`;

const ContainerTexts = styled.div`
  padding: 10px 25px;
`;

const LegalLSSI = () => {
  return (
    <Layout>
      <Container>
        <SectionHeader title="AVISO LEGAL LSSI" showBackButton />
        <ContainerTexts>
          <Text size="14px" weight="300" top="10px">
            Con el fin de cumplir con la legalidad vigente y otorgar seguridad y confianza a todos los usuarios, informamos de nuestros datos identificativos, en virtud de la Ley 34/2002 de Servicios
            de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE).{" "}
          </Text>
          <Text size="14px" top="20px" weight="700">
            TITULAR
          </Text>
          <Text size="14px" weight="300" top="10px">
            - EXPO CENTER BMC, S.L. <br />
            - CIF: B60789922 <br />
            - Dirección: Ronda Maiols, 1 <br />
            - Edificio BMC <br />- Correo electrónico: <Link to="mailto:bmc@bmc-moda.com">bmc@bmc-moda.com</Link> <br />- Teléfono: 93 745 45 00
          </Text>
          <Text size="14px" weight="300" top="10px">
            Esta web app se rige por la normativa exclusivamente aplicable en España, quedando sometida a ella, tanto nacionales como extranjeros que utilicen esta Web. En el caso de que se produzca
            cualquier tipo de discrepancia o diferencia entre las partes en relación con la interpretación y contenido de la presente página web, todas las partes se someten, con renuncia expresa a
            cualquier otro fuero, a los Juzgados y Tribunales de Barcelona.
          </Text>
          <Text size="14px" weight="300" top="10px">
            La utilización de la Web App te otorga la condición de Usuario, e implica la aceptación completa de todas las cláusulas y condiciones de uso incluidas en las páginas: <br />
            • Aviso Legal <br />
            • Política de Privacidad <br />
            • Política de Cookies <br />
            Si no estás conforme con todas y cada una de estas cláusulas y condiciones no utilices esta Web App.
          </Text>
          <Text size="14px" weight="300" top="10px">
            El acceso a este sitio no supone, en modo alguno, el inicio de una relación comercial con BMC.{" "}
          </Text>
          <Text size="14px" top="20px" weight="700">
            1. CONDICIONES DE USO
          </Text>
          <Text size="14px" weight="300" top="10px">
            El acceso a nuestra Web app es gratuito y exige previa suscripción o registro. No obstante, para acceder a determinados servicios es necesario cumplimentar formularios a través del mismo.
            Todos estos procesos, estarán debidamente identificados con indicaciones de cómo proceder en cada caso. El envío de datos personales implica la aceptar por parte del Usuario nuestra{" "}
            <Link to="/privacity">Política de Privacidad</Link>.
          </Text>
          <Text size="14px" weight="300" top="10px">
            El usuario debe acceder a nuestra Web App conforme a la buena fe, las normas de orden público, a las presentes Condiciones Generales de uso. El acceso a nuestro sitio se realiza bajo la
            propia y exclusiva responsabilidad del usuario, que responderá en todo caso de los daños y perjuicios que pueda causar a terceros o a nosotros mismos.
          </Text>
          <Text size="14px" weight="300" top="10px">
            El usuario tiene expresamente prohibido la utilización y obtención de los servicios, productos y contenidos ofrecidos en la presente web app, por procedimientos distintos a los estipulados
            en las presentes condiciones de uso, y en su caso en las condiciones particulares que regulen la adquisición de determinados productos.{" "}
          </Text>
          <Text size="14px" weight="300" top="10px">
            El Titular se reserva el derecho de ejercitar las acciones legales oportunas contra aquellos que vulneren las presentes condiciones generales de uso, aceptando el Usuario que la no
            iniciación de estas acciones no constituye una renuncia formal a los mismas, permaneciendo éstas vigentes hasta los plazos de prescripción de las infracciones.
          </Text>
          <Text size="14px" top="20px" weight="700">
            2. MAYORÍA DE EDAD
          </Text>
          <Text size="14px" weight="300" top="10px">
            El suministro de datos personales para actividades a través de nuestro portal requiere una edad mínima de 18 años, o en su caso, disponer de capacidad jurídica suficiente para contratar.
            En el caso de los menores de dieciocho años se requiere el consentimiento de los padres o tutores para el tratamiento de sus datos personales. En ningún caso se recabarán del menor de edad
            datos relativos a la situación profesional, económica o a la intimidad de los otros miembros de la familia, sin el consentimiento de éstos.
          </Text>
          <Text size="14px" top="20px" weight="700">
            3. MEDIDAS DE SEGURIDAD
          </Text>
          <Text size="14px" weight="300" top="10px">
            Los datos personales que facilites no serán almacenados en bases de datos automatizadas. La responsabilidad corresponde en exclusiva al Titular que asume todas las medidas de índole
            técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo con lo establecido en la normativa
            vigente en protección de datos. No obstante, debes ser consciente de que las medidas de seguridad de los sistemas informáticos en Internet no son enteramente fiables y que, por tanto el
            Titular no puede garantizar la inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas informáticos (software y hardware) del Usuario o en sus documentos
            electrónicos y ficheros contenidos en los mismos aunque pongamos todos los medios necesarios y tomemos las medidas de seguridad oportunas para evitar la presencia de estos elementos
            dañinos.
          </Text>
          <Text size="14px" top="20px" weight="700">
            4. CONTENIDOS
          </Text>
          <Text size="14px" weight="300" top="10px">
            El Titular ha obtenido la información, el contenido multimedia y los materiales incluidos en la Web app de fuentes que considera fiables, pero, si bien ha tomado todas las medidas
            razonables para asegurar que la información contenida es correcta, no se garantiza que sea exacta, completa o actualizada. El Titular declina expresamente cualquier responsabilidad por
            error u omisión en la información contenida en la misma. Queda prohibido transmitir o enviar a través del sitio cualquier contenido ilegal o ilícito, virus informáticos, o mensajes que, en
            general, afecten o violen derechos del Titular o de terceros.
          </Text>
          <Text size="14px" weight="300" top="10px">
            El Titular se reserva el derecho a modificar, suspender, cancelar o restringir el contenido de la Web App, los vínculos o la información obtenida a través del sitio, sin necesidad de
            previo aviso.
          </Text>
          <Text size="14px" top="20px" weight="700">
            5. ENLACES DE INTERÉS A OTROS SITIOS WEB
          </Text>
          <Text size="14px" weight="300" top="10px">
            El Titular puede proporcionarte acceso a sitios Web de terceros mediante enlaces con la finalidad de informar sobre la existencia de otras fuentes de información en Internet en las que
            podrás ampliar los datos ofrecidos en la web app. Estos enlaces a otros sitios Web no suponen en ningún caso una sugerencia o recomendación para que visites las páginas web de destino, que
            están fuera del control del Titular, por lo que Titular no es responsable del contenido de los sitios web vinculados ni del resultado que obtengas al seguir los enlaces. Asimismo, el
            Titular no responde de los links o enlaces ubicados en los sitios web vinculados a los que le proporciona acceso. El establecimiento del enlace no implica en ningún caso la existencia de
            relaciones entre Titular y el propietario del sitio en el que se establezca el enlace, ni la aceptación o aprobación por parte del Titular de sus contenidos o servicios. Si se accede a un
            sitio Web externo desde un enlace que encuentre en la Web App se deberá leer la propia política de privacidad del otro sitio web que puede ser diferente de la de este sitio.
          </Text>
          <Text size="14px" top="20px" weight="700">
            6. PROPIEDAD INTELECTUAL
          </Text>
          <Text size="14px" weight="300" top="10px">
            Todos los contenidos, textos, imágenes, y códigos fuente son propiedad del Titular o de terceros que a los que se han adquirido sus derechos de explotación, y están protegidos por los
            derechos de Propiedad Intelectual e Industrial. El usuario únicamente tiene derecho a un uso privado de los mismos, sin ánimo de lucro, y necesita autorización expresa del Titular y el
            titular de los derechos para modificarlos, reproducirlos, explotarlos, distribuirlos o cualquier derecho perteneciente a su titular. BMC es una marca registrada, y está prohibida su
            reproducción, imitación, utilización o inserción de esta marca sin nuestra debida autorización. El establecimiento de enlaces a nuestro portal no confiere ningún derecho sobre el mismo.
            Asimismo, el simple hecho de establecer un enlace a nuestra web app no da derecho a otorgarse la categoría de colaborador o socio. Está absolutamente prohibida la imitación ya sea total o
            parcial de nuestro portal.
          </Text>
          <Text size="14px" top="20px" weight="700">
            7. PROTECCIÓN DE DATOS PERSONALES
          </Text>
          <Text size="14px" weight="300" top="10px">
            En esta web se respetan y cuidan los datos personales de los usuarios. Como usuario debes saber que tus derechos están garantizados. La confidencialidad y la seguridad son valores
            primordiales de BMC y, en consecuencia, asumimos el compromiso de garantizar la privacidad del Usuario en todo momento y de no recabar información innecesaria sobre el mismo. Para más
            información consulta nuestra <Link to="/privacity">Política de Privacidad.</Link>
          </Text>
          <Text size="14px" top="20px" weight="700">
            8. UTILIZACIÓN DE COOKIES
          </Text>
          <Text size="14px" weight="300" top="10px">
            Este sitio web utiliza cookies y/o tecnologías que almacenan y recuperan información cuando navegas. En general, pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte
            como Usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido. Sólo obtiene y conserva la siguiente información acerca de los
            visitantes del Sitio Web:
          </Text>
          <Text size="14px" weight="300" top="10px">
            • El nombre de dominio del proveedor (PSI) y/o dirección IP que les da acceso a la red. <br />
            • La fecha y hora de acceso al sitio Web.
            <br />
            • La dirección de Internet origen del enlace que dirige al sitio Web.
            <br />
            • El número de visitantes diarios de cada sección.
            <br />
          </Text>
          <Text size="14px" weight="300" top="10px">
            La información obtenida es totalmente anónima, y en ningún caso puede ser asociada a un Usuario concreto e identificado. Los usos concretos que hacemos de estas tecnologías se describen en
            nuestra Política de Cookies
          </Text>
          <Text size="14px" top="20px" weight="700">
            9. RESPONSABILIDADES
          </Text>
          <Text size="14px" weight="300" top="10px">
            Al poner a disposición del usuario esta Web App queremos ofrecerte toda una serie de contenidos y servicios de calidad, utilizando la máxima diligencia en la prestación de los mismos, así
            como en los medios tecnológicos utilizados. No obstante, no responderemos por otros elementos que de algún modo puedan dañar el sistema informático del usuario.
          </Text>
          <Text size="14px" weight="300" top="10px">
            El Usuario asume la exención de cualquier responsabilidad de BMC, por causas de fuerza mayor, incluidos los fallos tecnológicos propios o ajenos derivados del estado actual de la
            tecnología.
          </Text>
          <Text size="14px" weight="300" top="10px">
            El usuario asume toda la responsabilidad derivada del uso de nuestra web app , siendo el único responsable de todo efecto directo o indirecto que sobre la web se derive, incluyendo, de
            forma enunciativa y no limitativa, todo resultado económico, técnico y/o jurídico adverso, así como la defraudación de las expectativas generadas por nuestro portal, obligándose el usuario
            a mantener indemne al Titular, por cualesquiera reclamaciones derivadas, directa o indirectamente de tales hechos.
          </Text>
          <Text size="14px" weight="300" top="10px">
            BMC, queda exonerada de cualquier responsabilidad derivada de cualquier reclamación, incluido el pago de honorarios a abogados, por las demandas y reclamaciones originadas por terceros por
            el incumplimiento por parte del Usuario de nuestras condiciones de uso, acceso y política de privacidad, o cualesquiera otras reclamaciones por el incumplimiento de la legislación vigente.
          </Text>
          <Text size="14px" top="20px" weight="700">
            10. VIGENCIA DE LAS CONDICIONES GENERALES DE ACCESO A LA WEB
          </Text>
          <Text size="14px" weight="300" top="10px">
            Las presentes Condiciones Generales de Uso han sido modificadas con fecha 14/06/2022. En cualquier momento podemos proceder a su modificación: por favor, comprueba la fecha de emisión en
            cada ocasión en que te conectes a nuestra Web App y así tendrás la certeza de que no se ha producido modificación alguna que te afecte. Para cualquier cuestión respecto a las Condiciones
            de Uso de nuestra web app, puedes ponerte en contacto con nosotros en los datos arriba indicados.
          </Text>
        </ContainerTexts>
      </Container>
    </Layout>
  );
};

export default LegalLSSI;
