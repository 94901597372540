import styled from "@emotion/styled";
import { useContext, useState } from "react";
import SelectorElementActive from "../../../components/SelectorElementActive";
import { BusinessContext } from "../../../context/BusinessContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { SalasContext } from "../../../context/SalasContext";
import { Text } from "../../../genericComponents";
import { IBookForBusiness } from "../SalaPicker";
import SalasDisplayer from "../SalasDisplayer";
import MyBooks from "./myBooks";

const Container = styled.div`
height:100%;
`;

const SalaWorker = () => {
  const [elementActive, setElementActive] = useState(1);
  const { allBusinessBooks } = useContext(SalasContext);

  const firstElement = {
    text: "Reserva de salas",
    ref: 1,
  };

  const secondElement = {
    text: "Mis reservas",
    ref: 2,
  };

  return (
    <Container>
      <SelectorElementActive
        firstElement={firstElement}
        secondElement={secondElement}
        setActiveElement={setElementActive}
        activeElement={elementActive}
      />
      {elementActive === 1 && <SalasDisplayer admin={false} />}
      {elementActive === 2 && allBusinessBooks.length > 0 ? (
        <MyBooks />
      ) : elementActive === 2 && !allBusinessBooks.length &&  (
        <Text
          size="13px"
          width="80%"
          left="auto"
          right="auto"
          top="20px"
          weight="200"
          align="center"
        >
          No tienes ninguna reserva de sala.
        </Text>
      )}
      {/* {enabled ? <SalasDisplayer admin={false} />: <Text align="center" top='20px' weight="200">PRÓXIMAMENTE...</Text>} */}
    </Container>
  );
};

export default SalaWorker;
