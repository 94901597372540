import { Box, Dialog, Modal } from "@mui/material";
import { Text } from "../../genericComponents";
import { modalStyle } from "../../utils/modalStyle";
import InputButtonWithProps from "../InputButton";
import styled from "@emotion/styled";
import InputWithLegend from "../InputWithLegend";

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

const ModalAccept = ({
  open,
  close,
  resetFunct,
  clearFunct,

  text,
}) => {
  return (
    <Modal
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      open={open}
      onClose={close}
    >
      <Box sx={modalStyle}>
        <Text top="10px" bottom="10px" align="center" size="16px">
          {text}
        </Text>
        <Row>
          <InputButtonWithProps
            onClick={() => resetFunct()}
            right="5px"
            type="submit"
            text="SÍ"
            top="20px"
          />
          <InputButtonWithProps
            type="submit"
            onClick={() => {
              clearFunct();
              close();
            }}
            text="NO"
            top="20px"
            left="5px"
            color="#fff"
            textColor="#000"
            border="0.5px solid #000"
          />
        </Row>
      </Box>
    </Modal>
  );
};

export default ModalAccept;
