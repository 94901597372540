const DoubleArrowsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.071"
      height="17.961"
      viewBox="0 0 36.071 17.961"
    >
      <g
        id="Grupo_552"
        data-name="Grupo 552"
        transform="translate(-168.964 -450.039)"
      >
        <path
          id="keyboard_backspace_FILL0_wght400_GRAD0_opsz48"
          d="M5.987,11.974,0,5.987,5.987,0,7.064,1.077,2.906,5.235h15.13v1.5H2.906L7.064,10.9Z"
          transform="translate(205.036 462.013) rotate(180)"
          fill="#f6f6f6"
        />
        <path
          id="keyboard_backspace_FILL0_wght400_GRAD0_opsz48-2"
          data-name="keyboard_backspace_FILL0_wght400_GRAD0_opsz48"
          d="M5.987,0,0,5.987l5.987,5.987L7.064,10.9,2.906,6.738h15.13v-1.5H2.906L7.064,1.077Z"
          transform="translate(168.964 456.026)"
          fill="#f6f6f6"
        />
      </g>
    </svg>
  );
};
export default DoubleArrowsIcon;
