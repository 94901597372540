import { List } from "@mui/material";
import { useContext, useState } from "react";
import SelectorElementActive from "../../../components/SelectorElementActive";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { GymContext } from "../../../context/GymContext";
import { Text } from "../../../genericComponents";
import EventsDisplayer from "../EventsDisplayer";
import Event from "../EventsDisplayer/Event";

const GymWorker = () => {
  const [elementActive, setElementActive] = useState(1);
  const { user } = useContext(FirebaseContext);
  const { events } = useContext(GymContext);
  const myEvents = events.filter((event) => event.participants.includes(user?.id));


  const firstElement = {
    text: "Actividades",
    ref: 1,
  };

  const secondElement = {
    text: "Mis clases",
    ref: 2,
  };

  return (
    <>
      <SelectorElementActive firstElement={firstElement} secondElement={secondElement} setActiveElement={setElementActive} activeElement={elementActive} />
      {elementActive === 1 && <EventsDisplayer admin={false} />}
      {elementActive === 2 && myEvents?.length > 0 ? (
       <List>
         {myEvents.map((event, idx) => {
           return (<Event event={event} key={`${event.id}-${idx}`} admin={false} userInTheEvent={true} />)
         })}
       </List>
      ) : (
        elementActive === 2 &&
        !myEvents?.length && (
          <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
            No estás apuntado a ninguna actividad.
          </Text>
        )
      )}
    </>
  );
};

export default GymWorker;
