import styled from "@emotion/styled";
const Searchbar = styled.input`
  padding: 5px 0;
  width: 90%;
`;

const ContainerSearchbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: Center;
  width: 90%;
  margin: 10px auto;
`;
const List = styled.ul`
  text-decoration: none;
  list-style: none;
  width: 90%;
  margin:0 auto;
  box-shadow: 1px 1px 5px 0px #0000006b;

  li {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid gray;
    cursor: pointer;
  }
`;
const SearchBarCustom = ({ filterFunction, data, selectFunction, value }) => {
  return (
    <ContainerSearchbar>
      <Searchbar
        placeholder="Buscar..."
        type="text"
        name="client"
        value={value}
        onChange={(e) => filterFunction(e)}
      />
      <List>
        {data.length > 0 &&
          data.map((doc, idx) => {
            return (
              <li onClick={() => selectFunction(doc)} key={`${doc.id}–${idx}`}>
                {doc.name}
              </li>
            );
          })}
      </List>
    </ContainerSearchbar>
  );
};

export default SearchBarCustom;
