import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CardsColumn, CardTicket } from "..";
import { MaintenanceContext } from "../../../context/MaintenanceContext";
import { Text } from "../../../genericComponents";
import styled from "@emotion/styled";
import { formatText } from "../../../utils/functions";
import { Image } from "../../Network/Business/BusinessCard";
import InputButtonWithProps from "../../../components/InputButton";

const Searchbar = styled.input`
  padding: 5px 0;
  width: 80%;
  margin: 0 auto;
`;
const ContainerSearchbar = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;
const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 61px;
  height: auto;
`;
const LeftContainer = styled.div`
  width: 45%;
  min-height:171px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding:20px 10px;
  border-right:0.6px solid #000;
`;
const RightContainer = styled.div`
  width: 55%;
  min-height:171px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
  text-align: left;
  padding:20px 10px;
  
`;
export const Card = styled.div`
  width: 90%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  box-shadow: 1px 1px 5px 0px #0000006b;
  margin:20px 0;
  position: relative;
  min-height:171px;
`;
export const Bubble = styled.div<{bgColor}>`
  width: 90%;
  height:25px;
  background-color: #ffffff;
  background-color:${props => props.bgColor && props.bgColor};
  border-radius:13px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-top:10px;
`;


const TicketsAdmin = () => {
  const { allTickets } = useContext(MaintenanceContext);
  const [filteredTickets, setFilteredTickets] = useState([]);

  const navigation = useNavigate();

  useEffect(() => {
    !filteredTickets.length && setFilteredTickets(allTickets);
  }, [allTickets]);

  const seeBusiness = async (ticket: { id: any; businessData: any }) => {
    navigation(`/maintenance-details`, {
      state: {
        id: ticket?.id,
        name: ticket?.businessData?.name,
      },
    });
  };

  const filterBusiness = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copyData = JSON.parse(JSON.stringify(allTickets));
    if (e.target.value.length > 0) {
      const businessFiltered = copyData.filter((ticket) => {
        const formattedValue = formatText(e.target.value);
        const business = formatText(ticket.businessData.name);

        if (business.includes(formattedValue)) return ticket;
        else console.log("not found");
      });
      setFilteredTickets(businessFiltered);
    } else {
      setFilteredTickets(copyData);
    }
  };

  return (
    <CardsColumn style={{paddingBottom: '150px'}}>
      <ContainerSearchbar>
        <Searchbar
          type="text"
          name="client"
          placeholder="Buscar una empresa..."
          onChange={(e) => filterBusiness(e)}
          disabled={!allTickets.length}
        />
      </ContainerSearchbar>
      {filteredTickets?.length > 0 ? (
        filteredTickets.map((ticket, idx) => {
          return (
            <Card
              onClick={() => seeBusiness(ticket)}
              key={`${ticket.id}–${idx}`}
            >
              <LeftContainer>
                <ContainerImage>
                  <img src={ticket.businessData.logo} alt='' />
                </ContainerImage>

                <Text
                  weight="200"
                  align="left"
                  size="16px"
                  pointer
                  top="auto"
                  bottom="auto"
                >
                  {ticket.businessData.name}
                </Text>
                <Text
                  weight="200"
                  align="left"
                  size="14px"
                  pointer
                  top="auto"
                  bottom="auto"
                >
                {ticket?.businessData?.locals.length > 1 ? 'Locales:' : 'Local:'} {ticket?.businessData?.locals.map((local, idx) => {
                    if (ticket?.businessData?.locals.length > 1) {
                      if (idx + 1 <= ticket?.businessData?.locals.length - 1) {
                        return local + ", ";
                      } else return local;
                    }
                    return local;
                  })}
                </Text>
              </LeftContainer>
              <RightContainer>
                <Text width="90%" align="left">TICKETS:</Text>
              <Bubble bgColor='#a84646'>
                <Text size="12px" weight="200" width="100%" align="center" color="#fff">{`Pendientes (${ticket.tickets?.pending?.length})`}</Text>
              </Bubble>
              <Bubble bgColor='#faf398'>
                <Text size="12px" weight="200" width="100%" align="center" color="#000">{`En curso (${ticket.tickets?.progress?.length})`}</Text>
              </Bubble>
              <Bubble bgColor='#389238'>
                <Text size="12px" weight="200" width="100%" align="center" color="#fff">{`Cerrados (${ticket.tickets?.closed?.length})`}</Text>
              </Bubble>
              </RightContainer>
            </Card>
          );
        })
      ) : (
        <Text
          size="13px"
          width="80%"
          left="auto"
          right="auto"
          top="20px"
          weight="200"
          align="center"
        >
          No tienes ningún mantenimiento pendiente.
        </Text>
      )}
    </CardsColumn>
  );
};

export default TicketsAdmin;
