import styled from "@emotion/styled";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { CardRow } from "../Services";
import CardWithImages from "../../components/CardWithImages";

const Container = styled.div`
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;


const Network = () => {
  const sections = [
    {
      title: "EMPRESAS",
      subtitle: "Ver",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_empresas.jpg?alt=media&token=81575bc2-64d6-4a2c-b8fa-25b9d6808ed7",
      route: "/business",
    },
    // {
    //   title: "COLABORACIONES",
    //   subtitle: "Ver",
    //   img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_colaboraciones.jpg?alt=media&token=9b69cda8-f384-4ac8-bb58-36afc451412e",
    //   route: "/partners",
    // },
    {
      title: "CAR SHARING",
      subtitle: "Apuntarse",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_carsharing.jpg?alt=media&token=69563ce1-3f46-4f2b-adcf-058744741359",
      route: "/carsharing",
    },
    {
      title: "ACTIVITY SHARING",
      subtitle: "Apuntarse",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_activity.jpg?alt=media&token=6bdf36c7-01a3-430b-b04a-187d290f0a34",
      route: "/activitysharing",
    },
    {
      title: "EVENTOS",
      subtitle: "Ver",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_eventos.jpg?alt=media&token=56e6544b-d078-4fae-b882-3ac6309c8bb0",
      route: "/events-business",
    },
    {
      title: "OFERTAS",
      subtitle: "Ver",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_eventos.jpg?alt=media&token=56e6544b-d078-4fae-b882-3ac6309c8bb0",
      route: "/offers-business",
    },
  
  ];
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fcabecera_network.jpg?alt=media&token=21aa051c-4652-4d18-ad22-b442fa9e71df"
          title="NETWORK"
          subtitle="Con nuestra app, crear comunidad entre todos está garantizado. Ofrecemos un valor añadido para ti y tu empresa."
          
        />
        <CardRow>
          {sections.map((section, i) => {
            return (
              <CardWithImages key={`id-${section.route}-${i}`} item={section} />
            );
          })}
        </CardRow>
      </Container>
    </Layout>
  );
};

export default Network;
