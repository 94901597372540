import styled from "@emotion/styled";
import { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { SliderContext } from "../../../context/SliderContext";
import CarouselItem from "../CarouselItem";

const ContainerCarousel = styled.div`
  flex-basis: 33%;
`;
const CustomCarousel = styled(Carousel)`
  height: 414px;
`;
const CarouselHome = () => {
  const {slidesEvents} = useContext(SliderContext)

  // const carouselData = [
  //   {
  //     src: "/1.jpeg",
  //     title: "¿No has desayunado?",
  //     subtitle: " No te pierdas nuestra oferta de la semana.",
  //     offer: "Tostada + café",
  //     price: "3,45¢",
  //     place: "Cafeteria",
  //     route: "/canteen",
  //     btnText: "Ver ofertas",
  //   },
  //   {
  //     place: "Gimnasio",
  //     src: "/02.jpeg",
  //     title: "¿Listo para el ejercicio?",
  //     subtitle: "Conoce nuestro gimnasio",
  //     offer: "Yoga & Pilates",
  //     price: "GRATIS",
  //     route: "/gym",
  //     btnText: "Apuntarse",
  //   },
  // ];
  return (
    <ContainerCarousel>
      <CustomCarousel
        dynamicHeight={true}
        interval={5000}
        autoPlay
        infiniteLoop
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={slidesEvents.length > 1}
      >
        {slidesEvents?.length > 0 && slidesEvents?.map((item, i) => {
          return (
            <CarouselItem
              key={`id-${item.route}-${i}`}
              src={item.src}
              place={item.place}
              price={item.price }
              title={item.title}
              subtitle={item.subtitle}
              offer={item.offer}
              btnText={item.btnText}
              route={item.route}
              id={item?.eventId}
            />
          );
        })}
      </CustomCarousel>
    </ContainerCarousel>
  );
};

export default CarouselHome;
