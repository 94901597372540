import styled from "@emotion/styled";
import { Modal, Box } from "@mui/material";
import { Text } from "../../genericComponents";
import { modalStyle } from "../../utils/modalStyle";

const ModalCusstom = ({ open, message }) => {
  return (
    <Modal
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      open={open}
    >
      <Box sx={modalStyle}>
        <Text top="10px" bottom="10px" align="center" size="20px">
          {message}
        </Text>
      </Box>
    </Modal>
  );
};

export default ModalCusstom;
