import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

import styled from "@emotion/styled";
import FloatButton from "../../../components/FloatButton";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { EventsContext, IEventsBmc } from "../../../context/EventsContext";
import ModalAccept from "../../../components/ModalAccept";
import ModalCusstom from "../../../components/ModalCustom";
import EventCardAdmin from "../../../components/EventCardAdmin";
import { userInfo } from "os";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { Text } from "../../../genericComponents";
import { createSearchParams } from "react-router-dom";

const Container = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
`;
const ContainerEvents = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 150px;
`;

const MyEvents = () => {
  const navigation = useNavigate();
  const { events, deleteEvent } = useContext(EventsContext);
  const { user } = useContext(FirebaseContext);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [message, setMessage] = useState("");
  const [modalOpenAccept, setModalOpenAccept] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpenAccept(false);
  };
  const clearSelections = () => {
    setSelectedDoc(null);
  };
  const showModal = (doc: IEventsBmc) => {
    setSelectedDoc(doc);
    setModalOpenAccept(true);
  };

  const deleteDoc = async () => {
    setModalOpenAccept(false);
    try {
      setModalOpen(true);
      setMessage("Borrando evento...");
      await deleteEvent(selectedDoc.id);
      setMessage("Evento borrado con éxito");
    } catch (error) {
      setModalOpen(true);
      setMessage("Error borrando evento, prueba de nuevo más tarde");
    } finally {
      setTimeout(() => {
        setMessage("");
        setModalOpen(false);
        clearSelections();
      }, 2000);
    }
  };
  const goToEdit = async (id:string) => {
    navigation({
      pathname: "/edit-event-business",
      search: createSearchParams({
        eventId: id,
      }).toString(),
    });
  };
  
const businessEvents = events?.filter((event) => event?.businessId === user?.businessId)
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fcabecera_eventos.jpg?alt=media&token=1068ac47-25d3-4e9a-89db-a2142fde50bb"
          title="MIS EVENTOS"
          subtitle="Crea y consulta eventos programados en el ecosistema de BMC."
          showBackButton
        />
        <FloatButton
          onClick={() => {
            navigation("/new-event");
          }}
        />
        <ContainerEvents>
          {businessEvents?.length > 0 ?
            businessEvents.map((doc, idx) => {
              return (
                <EventCardAdmin
                  deleteFunct={() => {
                    showModal(doc);
                  }}
                  key={doc.title + idx}
                  editFunct={() => goToEdit(doc.id)}
                  eventData={doc}
                />
              );
            }): <Text top="30px" align="center">No tienes ningún evento activo...</Text>}
        </ContainerEvents>
      </Container>
      <ModalAccept
        resetFunct={() => deleteDoc()}
        clearFunct={clearSelections}
        open={modalOpenAccept}
        close={closeModal}
        text={`¿Seguro que quieres eliminar ${selectedDoc?.title}`}
      />
      <ModalCusstom message={message} open={modalOpen} />
    </Layout>
  );
};

export default MyEvents;
