import styled from "@emotion/styled";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import CanteenWorker from "./CanteenWorker";
var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;
const CanteenContainer = styled.div`
  padding-bottom: 150px;
`;

const Canteen = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");

  useEffect(() => {
    eventId && scroller.scrollTo(eventId, { duration: 1500, delay: 100, smooth: true, offset: 50 });
  }, [eventId]);
  return (
    <Layout>
      <CanteenContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/cabecera_cafeteria.jpg?alt=media&token=adaac93c-d4d1-4e6d-b659-27800b937346"
          title="CAFETERÍA"
          subtitle="¿No has desayunado? Descubre nuestras ofertas y haz que tu día empiece con buen pie."
          showBackButton
        />
        <CanteenWorker />
      </CanteenContainer>
    </Layout>
  );
};

export default Canteen;
