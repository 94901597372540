import styled from "@emotion/styled";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

const Container = styled.div``;

const Partners = () => {
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="/notis.jpeg"
          title="COLABORACIONES"
          subtitle="Consulta tus notificaciones y todos tus servicios."
          showBackButton
        />
      </Container>
    </Layout>
  );
};

export default Partners;
