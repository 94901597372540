import styled from "@emotion/styled";
import { Text } from "../../genericComponents";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 20px auto;
`;

const ItemMenu = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: ${(props) =>
    props.active ? "2px solid #000" : "0.5px solid #000"};
  margin: 0 5px;
  width: 45%;
`;

interface ISelectorElementActive {
  firstElement: {
    text: string;
    ref: number;
  };
  secondElement: {
    text: string;
    ref: number;
  };
  setActiveElement: any;
  activeElement: number;
}

const SelectorElementActive = ({
  firstElement,
  secondElement,
  setActiveElement,
  activeElement,
}: ISelectorElementActive) => {
  return (
    <Row>
      <ItemMenu
        onClick={() => {
          setActiveElement(firstElement.ref);
        }}
        active={activeElement === firstElement.ref}
      >
        <Text weight={activeElement === firstElement.ref ? "bolder" : "normal"}>
          {firstElement.text}
        </Text>
      </ItemMenu>
      <ItemMenu
        onClick={() => {
          setActiveElement(secondElement.ref);
        }}
        active={activeElement === secondElement.ref}
      >
        <Text
          weight={activeElement === secondElement.ref ? "bolder" : "normal"}
        >
          {secondElement.text}
        </Text>
      </ItemMenu>
    </Row>
  );
};
export default SelectorElementActive;
