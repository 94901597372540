import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { useContext } from "react";
import { FirebaseContext } from "../../../context/FirebaseContext";
import TicketsAdmin from "../ticketsAdmin";

const MaintenanceContainer = styled.div`
  height: 100vh;
  position: relative;
  padding-bottom:150px;
`;
export const CardsColumn = styled.div`
  width: 100%;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

`;
export const CardTicket = styled.div`
  width: 300px;
  min-height: 146px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 0px #0000006b;
  margin: 20px 0;
  position: relative;
`;
export const HeaderTicket = styled.div<{ bg: string }>`
  height: 31px;
  background-color: ${(props) => props.bg && props.bg};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const TextTicket = styled.div`
  height: 100%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
`;

const AdminMaintenance = () => {
  const navigate = useNavigate();
  const { user } = useContext(FirebaseContext);

 
  return (
    <Layout>
      <MaintenanceContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/cabecera_mantenimiento.jpg?alt=media&token=fe9dc191-d2ac-43b6-a3ef-ff639fe75b33"
          title="MANTENIMIENTO"
          subtitle="Haz la solicitud a nuestros técnico explicando el problema y nos pondremos en contacto contigo lo antes posible."
          showBackButton
        />
       
        
          {user?.roles.maintenance && <TicketsAdmin /> }
        
      </MaintenanceContainer>
    </Layout>
  );
};

export default AdminMaintenance;
