import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

import styled from "@emotion/styled";
import FloatButton from "../../../components/FloatButton";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { BusinessContext } from "../../../context/BusinessContext";
import InputWithLegend from "../../../components/InputWithLegend";
import InputButtonWithProps from "../../../components/InputButton";
import { Text } from "../../../genericComponents";
import CircleIcon from "../../../components/CircleIcon";
import ModalAccept from "../../../components/ModalAccept";
import ModalCusstom from "../../../components/ModalCustom";

const Container = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
`;

const DeleteIcon = styled(CircleIcon)`
  margin-left: 10px;
`;
const CategoryContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  border-bottom: 1px solid #bbbbbb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ContainerCategories = styled.div`
  background-color: #fff;
  padding-bottom: 150px;
`;
const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  input {
    margin: 0 auto;
  }
`;

const Categories = () => {
  
  const { createCategory, deleteCategory, categories } = useContext(BusinessContext);

  const [newCategory, setNewCategory] = useState("");
  const [message, setMessage] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const closeModalConfirm = () => {
    setModalConfirm(false);
  };

  const handleDelete = async () => {
    setModalConfirm(false);
    setModalStatus(true);
    setMessage("Borrando categoría...");
    await deleteCategory(selectedDoc?.id);
    setMessage("Categoría borrada con éxito");
    setTimeout(() => {
      setMessage("");
      setModalStatus(false);
      setSelectedDoc(null);
    }, 2000);
  };

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/categorias.png?alt=media&token=51ffa220-a2a4-474d-98d1-63d3278d6cbb"
          title="CATEGORIAS DE EMPRESA"
          subtitle="Crea, edita o elimina categorias de empresa"
          showBackButton
        />

        <ContainerCategories>
          <InputWithLegend type="text" name="nameToShow" title="Nueva categoría" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
          <ContainerButton>
            <InputButtonWithProps
              onClick={async () => {
                setModalStatus(true);
                setMessage("Creando categoría...");
                await createCategory(newCategory);
                setMessage("Categoría creada con éxito");
                setTimeout(() => {
                  setMessage("");
                  setModalStatus(false);
                  setNewCategory("");
                }, 2000);
              }}
              disabled={!newCategory.length}
              text="Añadir categoría"
            />
          </ContainerButton>
          {categories.length &&
            categories.map((category, idx) => {
              return (
                <CategoryContainer key={`id-${category.id}-${idx}`}>
                  <Text>{category.nameToShow}</Text>
                  <DeleteIcon
                    color="#BF2A2A"
                    src="/bin.png"
                    funcClick={() => {
                      setModalConfirm(true);
                      setSelectedDoc(category);
                    }}
                  />
                </CategoryContainer>
              );
            })}
        </ContainerCategories>
        <ModalAccept
          text={`¿Seguro que quieres eliminar la categoría "${selectedDoc?.nameToShow}"?`}
          close={closeModalConfirm}
          clearFunct={() => {
            setModalConfirm(false);
          }}
          open={modalConfirm}
          resetFunct={handleDelete}
        />
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default Categories;
