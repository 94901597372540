import styled from "@emotion/styled";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { Text } from "../../../genericComponents";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom:150px;
  background: #f6f6f6;
`;
const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
`;
const ItemProfile = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: #f6f6f6;
  justify-content: center;
  border-bottom: 0.5px solid #000;
`;
const ButtonContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;

  background: #f6f6f6;
  justify-content: center;
`;
const ButtonLogout = styled.button`
  width: auto;
  margin: 0 auto;
  padding: 10px 40px;
  background: #000;
  color: #fff;
  border: 1px solid #000;
`;

const MyProfile = () => {
  const { signout, user } = useContext(FirebaseContext);
  const navigation = useNavigate();

  const items = [
    { id: 1, text: "Modificar mi perfil", route: "/edit-profile" },
    { id: 3, text: "Cambio de contraseña", route: "/change-password" },
  ];

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/my-profile%2Fcabecera_miPerfil.jpg?alt=media&token=fc869a49-055f-44f3-9f01-be8a1f11a509"
          title="MI PERFIL"
          subtitle="Todos tus datos en un mismo espacio. Consulta tus notificaciones y todos tus servicios."
          showBackButton
        />
        <ItemsContainer>
          {items.map((item) => {
            return (
              <ItemProfile onClick={() => navigation(item.route)} key={item.id}>
                <Text weight="200" size="14px" left="25px">
                  {item.text}
                </Text>
              </ItemProfile>
            );
          })}
        </ItemsContainer>
        {/* {user?.responsable && <ItemProfile onClick={() => navigation("/edit-my-business")}>
          <Text weight="200" size="14px" left="25px">
            Modificar mi empresa
          </Text>
        </ItemProfile>} */}
        <ButtonContainer>
          <ButtonLogout
            onClick={() => {
              signout();
            }}
          >
            Desconectar
          </ButtonLogout>
        </ButtonContainer>
      </Container>
    </Layout>
  );
};

export default MyProfile;
