import moment from "moment";
import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Layout from "../../../../components/Layout";
import SectionHeader from "../../../../components/SectionHeader";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { Text } from "../../../../genericComponents";
import { HeaderTicket } from "../../../Maintenance/Admin";
import { CardsColumn } from "../../../Maintenance";
import { createSearchParams } from "react-router-dom";

const Container = styled.div`
  padding-bottom: 150px;
`;
export const CardTicket = styled.div`
  width: 90%;
  min-height: 110px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 0px #0000006b;
  margin: 20px auto;
  position: relative;
`;
const NotisDetails = () => {
  const { userNotis, updateNotifications, deleteNotifications } = useContext(FirebaseContext);
  const [notisToShow, setNotisToShow] = useState(null);
  const [title, setTitle] = useState("");
  const [route, setRoute] = useState("");
  const location: any = useLocation();
  const navigation = useNavigate();
  const type = location?.state?.type;

  const getInitialData = () => {
    switch (type) {
      case "canteen":
        setNotisToShow(userNotis.canteen);
        setTitle("CAFETERÍA");
        setRoute("/canteen");

        break;
      case "events-bmc":
        setNotisToShow(userNotis.eventsBmc);
        setTitle("EVENTOS BMC");
        setRoute("/bmc-events");
        break;
      case "events-business":
        setNotisToShow(userNotis.events);
        setTitle("EVENTOS EMPRESAS");
        setRoute("/events-business");
        break;
      case "packages":
        setNotisToShow(userNotis.packages);
        setTitle("PAQUETERÍA");
        setRoute("/parcel");
        break;
      case "tickets":
        setNotisToShow(userNotis.tickets);
        setTitle("MANTENIMIENTO");
        setRoute("/maintenance");
        break;
      case "carsharing":
        setNotisToShow(userNotis.carsharing);
        setTitle("CARSHARING");
        setRoute("/carsharing");
        break;
      case "activitysharing":
        setNotisToShow(userNotis.activitysharing);
        setTitle("ACTIVITYSHARING");
        setRoute("/activitysharing");
        break;
      case "sala":
        setNotisToShow(userNotis.sala);
        setTitle("SALAS");
        setRoute("/salas");
        break;
    }
  };

  const updateNotis = () => {
    updateNotifications(notisToShow?.unviewed);
  };
  const deleteNotis = () => {
    notisToShow?.unviewed?.length > 0 && deleteNotifications(notisToShow?.unviewed);
    notisToShow?.viewed?.length > 0 && deleteNotifications(notisToShow?.viewed);
    navigation("/notis");
  };

  useEffect(() => {
    type && userNotis && getInitialData();
  }, [type, userNotis]);

  useEffect(() => {
    notisToShow && updateNotis();
  }, [notisToShow]);

  return (
    <Layout>
      <SectionHeader showBackButton title={title} />
      <Container>
        {(notisToShow?.unviewed?.length > 0 || notisToShow?.viewed?.length > 0) && (
          <Text onClick={() => deleteNotis()} weight="200" size="14px" decoration="underline" align="right" width="90%" right="auto" left="auto" top="20px" pointer>
            X Borrar todas
          </Text>
        )}
        <CardsColumn>
          {notisToShow?.unviewed?.length > 0 &&
            notisToShow?.unviewed?.map((notification, idx) => {
              return (
                <CardTicket key={`${notification.info}-${idx}`}>
                  <HeaderTicket bg="#5B656E">
                    <Text left="10px" size="14px" color="white" weight="200">
                      {moment(notification?.createdAtt).format("DD/MM/YYYY HH:mm")}
                    </Text>
                  </HeaderTicket>
                  <Text width="90%" left="20px" weight="200" top="10px">
                    {notification?.info}
                  </Text>
                  <Text
                    pointer
                    decoration="underline"
                    onClick={() => {
                      notification?.type === "tickets" && notification?.admin ? navigation("/maintenance-admin") : navigation(route);
                      navigation({
                        pathname: route,
                        search: createSearchParams({
                          eventId: notification?.id,
                        }).toString(),
                      });
                    }}
                    weight="200"
                    top="10px"
                  >
                    Ver detalle
                  </Text>
                </CardTicket>
              );
            })}

          {notisToShow?.viewed?.length > 0 &&
            notisToShow?.viewed?.map((notification, idx) => {
              console.log(notification)
              return (
                <CardTicket key={`${notification.info}-${idx}`}>
                  <HeaderTicket bg="#5B656E">
                    <Text left="10px" size="14px" color="white" weight="200">
                      {moment(notification?.createdAtt).format("DD/MM/YYYY HH:mm")}
                    </Text>
                  </HeaderTicket>
                  <Text width="90%" left="20px" weight="200" top="10px">
                    {notification?.info}
                  </Text>
                  <Text
                    pointer
                    decoration="underline"
                    onClick={() => {
                      notification?.type === "tickets" && notification?.admin ? navigation("/maintenance-admin") : navigation(route);
                      navigation({
                        pathname: route,
                        search: createSearchParams({
                          eventId: notification?.id,
                        }).toString(),
                      });
                    }}
                    weight="200"
                    top="auto"
                    bottom="10px"
                    left="auto"
                    right="20px"
                  >
                    Ir a la página
                  </Text>
                </CardTicket>
              );
            })}
        </CardsColumn>
      </Container>
    </Layout>
  );
};

export default NotisDetails;
