import { useContext, useState } from "react";
import styled from "@emotion/styled";

import { IMyBook, SalasContext } from "../../../../context/SalasContext";
import { Text, Title } from "../../../../genericComponents";
import moment from "moment";
import { CardTicket, HeaderTicket } from "../../../Maintenance";
import ModalAccept from "../../../../components/ModalAccept";
import ModalCusstom from "../../../../components/ModalCustom";
import InputButtonWithProps from "../../../../components/InputButton";
import { CSVLink } from "react-csv";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../../fb/config";
import { getFunctions, httpsCallable } from "firebase/functions";
import { filterColumns } from "../../../../utils/functions";

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  input {
    margin: 10px auto;
  }
`;
const ExportsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  input {
    width: 60%;
  }
  margin-top: auto;
`;
const CSVLinkCustom = styled(CSVLink)`
  text-decoration: none;
  color: black;
  border: 1px solid black;
  width: 65%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  p {
    text-align: center;
  }
`;

const AllBooks = () => {
  const { deleteBook, loadingById, books } = useContext(SalasContext);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [bookToDelete, setBookToDelete] = useState<IMyBook>(null);
  const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);

  const sendNotificationCancelBook = httpsCallable(functions, "sendNotificationCancelBook");
  const closeModalConfirm = () => {
    setModalConfirm(false);
  };
  const handleDelete = async () => {
    setModalConfirm(false);
    setModalStatus(true);
    try {
      setMessage("Cancelando reserva...");
      if (!bookToDelete.type) {
        await sendNotificationCancelBook({ book: bookToDelete, user: bookToDelete.userData });
        await deleteBook(bookToDelete.id);
        setMessage("Reserva cancelada con éxito");
      }
    } catch (error) {
      setMessage("Error cancelando la reserva, prueba de nuevo más tarde");
    } finally {
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
      }, 2000);
    }
  };
  const sorted = books.length && books.sort((a, b) => a.timeSelected - b.timeSelected);
  const booksToExport =
    sorted.length &&
    sorted.map((doc) => {
      return { sala: doc?.salaData?.name, dia: moment(doc.daySelected).format("DD/MM/YYYY"), hora: moment(doc.timeSelected).format("HH:mm"), empresa: doc?.businessData?.name, };
    });
  return (
    <Container>
      <ExportsContainer>
        {booksToExport?.length > 0 && (
          <CSVLinkCustom data={booksToExport} headers={filterColumns(booksToExport)} filename={`reservas-sala-${booksToExport[0]?.sala}-${booksToExport[0]?.dia}.csv`}>
            <Text>Descargar reservas - {booksToExport[0]?.dia} </Text>
          </CSVLinkCustom>
        )}
      </ExportsContainer>
      {loadingById ? (
        <Text top="10px" bottom="10px" weight="200" width="100%" align="center">
          Cargando...
        </Text>
      ) : sorted.length > 0 ? (
        sorted.map((book, idx) => {
          const dateTime = new Date(book.timeSelected);
          const finalHour = dateTime.setTime(dateTime.getTime() + 1 * 60 * 60 * 1000);
          return (
            <CardTicket key={`${book.id}-idx${idx}`}>
              <HeaderTicket bg="#333333">
                <Text left="10px" size="14px" color="white" weight="200">
                  {moment(book?.daySelected).format("DD/MM/YYYY")}
                </Text>
              </HeaderTicket>
              <Text left="10px" weight="200" top="10px" size="14px" color="#000000">
                Sala: <strong>{book?.salaData?.name}</strong>
              </Text>
              <Text left="10px" weight="200" top="10px" size="14px" color="#000000">
                Hora:{" "}
                <strong>
                  {moment(book?.timeSelected).format("HH:mm")}–{moment(finalHour).format("HH:mm")}
                </strong>
              </Text>

              <Text left="10px" weight="200" top="10px" size="14px" color="#000000">
                Empresa: <strong>{book?.businessData?.name}</strong>
              </Text>

              <Text left="10px" weight="200" top="10px" size="14px" color="#000000">
                Reservado por: <strong>{book.type ? book.bookedBy : book?.userData?.name}</strong>
              </Text>
              {finalHour >= new Date().getTime() ? (
                <ContainerButton>
                  <InputButtonWithProps
                    onClick={() => {
                      setModalConfirm(true);
                      setBookToDelete(book);
                    }}
                    disabled={book.type ? true : false}
                    right="5px"
                    type="button"
                    text="Cancelar"
                    top="20px"
                  />
                </ContainerButton>
              ) : (
                <Text color="green" top="10px" bottom="10px" weight="200" width="100%" align="center">
                  Finalizada
                </Text>
              )}
            </CardTicket>
          );
        })
      ) : (
        <Text top="10px" bottom="10px" weight="200" width="100%" align="center">
          No hay reservas para el día seleccionado.
        </Text>
      )}
      <ModalAccept
        text={`¿Seguro que quieres cancelar tu reserva?`}
        close={closeModalConfirm}
        clearFunct={() => {
          setBookToDelete(null);
          setModalConfirm(false);
        }}
        open={modalConfirm}
        resetFunct={handleDelete}
      />

      <ModalCusstom message={message} open={modalStatus} />
    </Container>
  );
};

export default AllBooks;
