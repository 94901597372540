import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { es } from "date-fns/locale";
import styled from "@emotion/styled";

import { Input, Text } from "../../genericComponents";

const CustomDatePicker = styled(DatePicker)``;
type IContainerInput = {
  width?: string;
  disabled?: boolean;
};
const ContainerDiv = styled.div<IContainerInput>`
  width: ${(props) => props.width};
  padding: 15px;
  opacity: ${(props) => props.disabled && 0.5};
`;
export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 10px;
  }
`;
type IDatePicker = {
  data: any;
  label: string;
  setFunct: any;
  disableMin?: boolean;
  initSelected?: number;
  title?: string;
  preIcon?: any;
  legendColor?: string;
  width?: string;
  disabled?: boolean;
};

const DatePickerCustom = ({
  data,
  label,
  setFunct,
  disableMin,
  initSelected,
  preIcon,
  title,
  legendColor,
  width,
  disabled,
}: IDatePicker) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todaySelected = initSelected && new Date(initSelected);

  return (
    <ContainerDiv>
      <ContainerTitle>
        {preIcon}
        <Text color={legendColor} size="14px" top="10px" bottom="10px">
          {title}
        </Text>
      </ContainerTitle>
      <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
        <CustomDatePicker
          value={new Date(data?.[label])}
          minDate={!disableMin ? (initSelected ? todaySelected : today) : ""}
          onChange={(newValue: any) => {
            setFunct({ ...data, [label]: newValue.getTime() });
          }}
          
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Input
              placeholder="Selecciona una fecha..."
              ref={inputRef}
              {...inputProps}
            />
          )}
        />
      </LocalizationProvider>
    </ContainerDiv>
  );
};

export default DatePickerCustom;
