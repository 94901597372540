import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { Text, Title } from "../../genericComponents";
const SectionHeaderContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
`;

const TextContainerHeader = styled.div`
  padding: 20px;
`;

const SectionHeaderLogin = ({
  title,
  
}: {
  title: string;

}) => {
  const navigation = useNavigate();
  return (
    <SectionHeaderContainer>
      <TextContainerHeader>
        <Text size="18px" color="#000" onClick={() => navigation(-1)}>
          ←
        </Text>

        <Title top="0" weight="200" size="25px">
          {title}
        </Title>
      </TextContainerHeader>
    </SectionHeaderContainer>
  );
};

export default SectionHeaderLogin;
