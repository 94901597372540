import styled from "@emotion/styled";
import { useContext, useState } from "react";
import FloatButton from "../../../components/FloatButton";
import { useNavigate } from "react-router";
import { Title, Text, BorderBottom } from "../../../genericComponents";
import moment from "moment";
import { CanteenContext } from "../../../context/CanteenContext";
import {  Element } from "react-scroll";

const Button = styled.button<{ reversed?: boolean }>`
  color: ${(props) => (props.reversed ? "#000" : "#fff")};
  background-color: ${(props) => (props.reversed ? "#fff" : "#000")};
  padding: 0 20px;

  opacity: ${(props) => props.disabled && 0.6};
  width: auto;
  margin: 0 auto;
  margin-top: 20px;
`;

const OffersColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  input {
    margin-bottom: 20px;
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 302px;
  height: 200px;
  margin-top: 20px;
  box-shadow: 1px 1px 5px 0px #0000006b;
  span {
    padding: 0;
  }
`;

const Image = styled.div<{ src: string }>`
  background: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-position: center;
  width: 40%;
  height: 100%;
`;
const CardText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding: 0 20px;
`;
const OffersDisplayer = ({ admin }: { admin: boolean }) => {
  const { offers, deleteOffer } = useContext(CanteenContext);

  const navigation = useNavigate();

  return (
    <OffersColumn>
      {offers?.length > 0 ? (
        offers.map((offer, idx) => {
          return (
            <Element key={`${offer.id}-${idx}`} name={offer?.id}>
              <Card >
                <Image src={offer.image}></Image>
                <CardText>
                  <Title top="20px" size="14px">
                    {offer.title}
                  </Title>
                  <Text weight="200" top="10px" size="14px" color="#000000">
                    Inicio: {moment(offer.initTimestamp).format("DD/MM/YYYY")}
                  </Text>
                  <Text weight="200" top="10px" size="14px" color="#000000">
                    Fin: {moment(offer.finishTimestamp).format("DD/MM/YYYY")}
                  </Text>
                  <BorderBottom color="#A3A2A7"></BorderBottom>
                  <Text weight="200" top="10px" size="14px" color="#000000">
                    {offer.price}
                  </Text>

                  {admin && <Button onClick={() => deleteOffer(offer.id)}>Borrar</Button>}
                </CardText>
              </Card>
            </Element>
          );
        })
      ) : (
        <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
          Todavía no hay ninguna oferta programada. Estáte atento, ¡pronto tendrás noticias nuestras!
        </Text>
      )}
      {admin && <FloatButton onClick={() => navigation("/new-canteen-offer")} />}
    </OffersColumn>
  );
};

export default OffersDisplayer;
