import { useContext } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import { IDoc, MaintenanceContext } from "../../context/MaintenanceContext";
import { Text } from "../../genericComponents";

import 'moment/locale/es' 

import {
  CardsColumn,
  CardTicket,
  HeaderTicket,
  TextTicket,
} from "../../pages/Maintenance";
import { ticketColorStatus, ticketStatusPhrase } from "../../utils/functions";
import Select from "../Select";
import moment from 'moment';
moment.locale('es')

const TicketsDisplayer = ({ tickets, admin }: { tickets: IDoc[], admin?:boolean }) => {
  const { user } = useContext(FirebaseContext);
  const { updateTicketStatus } = useContext(MaintenanceContext);

  const handleChange = async (e, id: string) => {
    await updateTicketStatus(e.target.value, id);
  };
  return (
    <CardsColumn>
      {tickets?.length > 0 &&
        tickets.map((ticket, idx) => {
          return (
            <CardTicket key={`${ticket.id}-idx${idx}`}>
              <HeaderTicket bg={ticketColorStatus(ticket.status)}>
                <Text left="10px" size="14px" color="white" weight="200">
                  Solicitud {moment(ticket.timestamp).format('DD/MM/YYYY')} 
                </Text>
              </HeaderTicket>
              <TextTicket>
                <Text size="14px" weight="200">
                  - Local {ticket.localNum}
                </Text>
                <Text top="5px" size="14px" weight="200">
                  - {ticket.reason}
                </Text>
                <Text
                  color={ticketColorStatus(ticket.status)}
                  top="5px"
                  size="14px"
                >
                  - {ticketStatusPhrase(ticket.status)}
                </Text>
                {admin && (
                  <Select
                    options={[
                      { value: "pending", name: "Pendiente" },
                      { value: "progress", name: "En curso" },
                      { value: "fault", name: "Seguimiento de avería" },
                      { value: "budget", name: "Pendiente de presupuesto" },
                      { value: "accept", name: "Pendiente de aceptación presupuesto" },
                      { value: "material", name: "Pendiente de material" },
                      { value: "closed", name: "Cerrado" },
                    ]}
                    ticket={ticket}
                    handleChange={handleChange}
                  />
                )}
                
              </TextTicket>
            </CardTicket>
          );
        })}
    </CardsColumn>
  );
};

export default TicketsDisplayer;
