import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { Text } from "../../genericComponents";

const Container = styled.div`
  background-color: white;
  position: relative;
  padding-bottom: 150px;
`;

const ContainerTexts = styled.div`
  padding: 10px 25px;
`;

const CookiesPolicy = () => {
  return (
    <Layout>
      <Container>
        <SectionHeader title="POLÍTICA DE COOKIES" showBackButton />
        <ContainerTexts>
          <Text size="14px" weight="300" top="10px">
            En cumplimiento de las obligaciones previstas en el apartado segundo del artículo 22 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio
            electrónico (en adelante, LSSI), en relación con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, General de Protección de Datos (en adelante,
            RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos y garantía de los derechos digitales (en adelante, LOPDGDD), ponemos a su disposición información clara y completa
            sobre la utilización de cookies, en particular, sobre los fines de tratamiento de datos personales.
          </Text>
          <Text size="14px" top="20px" weight="700">
            1. RESPONSABLE
          </Text>
          <Text size="14px" weight="300" top="10px">
            - EXPO CENTER BMC, S.L. <br />
            - CIF: B60789922 <br />
            - Dirección: Ronda Maiols, 1 <br />
            - Edificio BMC <br />- Correo electrónico: <Link to="mailto:bmc@bmc-moda.com">bmc@bmc-moda.com</Link> <br />- Teléfono: 93 745 45 00
          </Text>
          <Text size="14px" weight="300" top="10px">
            Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. Las finalidades son diversas, como, por ejemplo, reconocerte como usuario,
            obtener información sobre hábitos de navegación, o personalizar la forma en que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.
          </Text>
          <Text size="14px" top="20px" weight="700">
            2. NUESTRAS COOKIES
          </Text>
          <Text size="14px" weight="300" top="10px">
            <b>Cookies técnicas</b>: Son aquellas que permiten al usuario la navegación a través del área restringida y la utilización de sus diferentes funciones, como, por ejemplo, llevar a cambio
            el proceso de compra de un artículo.
          </Text>
          <Text size="14px" weight="300" top="10px">
            <b>Cookies de personalización</b>: Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de
            criterios en el terminal del usuario como por ejemplo serian el idioma o el tipo de navegador a través del cual se conecta al servicio.
          </Text>
          <Text size="14px" top="20px" weight="700">
            3. COOKIES QUE HASTA EL MOMENTO NO UTILIZAMOS
          </Text>
          <Text size="14px" weight="300" top="10px">
            <b>Cookies de análisis:</b> Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de
            la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le
            ofrecemos.
          </Text>
          <Text size="14px" weight="300" top="10px">
            <b>Cookies publicitarias:</b> Son aquéllas que, bien tratadas por esta web o por terceros, permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay
            en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar los hábitos de navegación
            en Internet y podemos mostrarte publicidad relacionada con tu perfil de navegación.
          </Text>
          <Text size="14px" weight="300" top="10px">
            <b>Cookies de publicidad comportamental:</b>Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en
            una página web, aplicación o plataforma desde la que presta el servicio solicitado. Este tipo de cookies almacenan información del comportamiento de los visitantes obtenida a través de la
            observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar avisos publicitarios en función del mismo.
          </Text>
          <Text size="14px" top="20px" weight="700">
            4. COOKIES DE TERCEROS
          </Text>
          <Text size="14px" weight="300" top="10px">
            Permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza tu
            navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que te ofrecemos. Link a política cookies Google Analytics{" "}
            <Link to="https://policies.google.com/technologies/cookies?hl=es#types-of-cookies">https://policies.google.com/technologies/cookies?hl=es#types-of-cookies</Link>
          </Text>
          <Text size="14px" top="20px" weight="700">
            Transferencias a terceros países
          </Text>
          <Text size="14px" weight="300" top="10px">
            Puedes informarte de las transferencias a terceros países que, en tu caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (ver los
            enlaces facilitados en el apartado “Cookies de terceros”). Periodo de conservación de datos de usuarios y eventos asociados a las cookies Los identificadores de usuario (por ejemplo,
            User-ID) y los identificadores publicitarios (por ejemplo, las cookies de DoubleClick, el ID de publicidad de Android [AAID o AdID] y el identificador de anunciantes de Apple [IDFA]).
            Google Analytics, elimina de forma predeterminada algunos datos identificativos (por ejemplo, la edad, el sexo y los intereses) de los usuarios que hayan estado inactivos durante más de 6
            meses (en el caso de las propiedades Universal Analytics) o durante más de 2 meses (en el caso de las propiedades Google Analytics 4). El tiempo máximo durante el que Analytics conservará
            los datos de Google signals es de 26 meses, independientemente de la configuración que elijas. De forma predeterminada, los datos de los usuarios que han iniciado sesión en Google caducan
            pasados 26 meses. Sin embargo, si se asigna un valor inferior a ese al ajuste Conservación de datos, Google respetará el periodo más corto que se haya definido. En el caso de las
            propiedades Google Analytics 4 (versión distinta a Analytics 360), la conservación de los datos a nivel de usuario, incluidas las conversiones, puede fijarse en un máximo de 14 meses. En
            el resto de los datos de eventos, puedes elegir la duración de la conservación: 2 meses 14 meses
          </Text>
          <Text size="14px" top="20px" weight="700">
            Derechos de los interesados
          </Text>
          <Text size="14px" weight="300" top="10px">
            Puedes obtener más información al respecto en nuestra <Link to="/privacity">Política de privacidad</Link>
          </Text>
          <Text size="14px" top="20px" weight="700">
            Consentimiento
          </Text>
          <Text size="14px" weight="300" top="10px">
            El consentimiento prestado por un usuario para el uso de una determinada cookie no tendrá una duración superior a 24 meses y durante este tiempo se conservará la selección realizada por el
            usuario sobre sus preferencias, sin que se le solicite un nuevo consentimiento cada vez que visite nuestra web.
          </Text>
          <Text size="14px" top="20px" weight="700">
            Legitimación
          </Text>
          <Text size="14px" weight="300" top="10px">
            El interés legítimo del tratamiento de datos radica en la optimización de la página web, el análisis del uso de la página web y la adaptación del contenido. Los intereses del usuario están
            garantizados adecuadamente mediante la pseudominización.
          </Text>
          <Text size="14px" top="20px" weight="700">
            Seguridad
          </Text>
          <Text size="14px" weight="300" top="10px">
            Google LLC está certificado conforme al llamado <Link to="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI">Privacy Shield</Link> y, por tanto, queda garantizado un adecuado
            nivel de protección de datos. El registro realizado por Google Analytics puede impedirse si el visitante de la página configura los ajustes de Cookies para esta página web. El registro y
            almacenamiento de la dirección IP y de los datos generados por las Cookies pueden anularse en cualquier momento sin efectos retroactivos. El correspondiente plugin del navegador puede
            descargarse e instalarse a través del siguiente enlace <Link to="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</Link>. Más información sobre el uso de
            datos por Google, las opciones de configuración y anulación se puede encontrar en la <Link to="https://policies.google.com/privacy">Política de Privacidad de Google</Link>, así como en los{" "}
            <Link to="https://adssettings.google.com/authenticated">ajustes para la publicidad de Google</Link>. El usuario puede rechazar el tratamiento de los datos o la información rechazando el
            uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, si lo hace puede ser que no pueda usar la plenamentede la website. Al utilizar la website
            se consiente el tratamiento de información por Google en la forma y para los fines arriba indicados.
          </Text>
          <Text size="14px" top="20px" weight="700">
            Desactivación de las cookies.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Puedes permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en su ordenador. En la mayoría de los navegadores
            web se ofrece la posibilidad de permitir, bloquear o eliminar las cookies instaladas en tu equipo. A continuación, puedes acceder a la configuración de los navegadores webs más frecuentes
            para aceptar, instalar o desactivar las cookies:
            <br />
            <Link to="https://support.google.com/chrome/answer/95647?hl=es">Configurar cookies en Google Chrome</Link>
            <br />
            <Link to="https://pevitech.com/windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">Configurar cookies en Microsoft Internet Explorer</Link>
            <br />
            <Link to="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
              Configurar cookies en Mozilla Firefox
            </Link>
            <br />
            <Link to="https://support.apple.com/es-es/HT201265">Configurar cookies en Safari (Apple)</Link>
            <br />
          </Text>
          <Text size="14px" top="20px" weight="700">
            Advertencia sobre eliminar cookies
          </Text>
          <Text size="14px" weight="300" top="10px">
            Puedes eliminar y bloquear todas las cookies de este sitio, pero parte del sitio no funcionará o la calidad de la página web puede verse afectada. Si tienes cualquier duda acerca de
            nuestra política de cookies, puedes contactar con esta app.
          </Text>
        </ContainerTexts>
      </Container>
    </Layout>
  );
};

export default CookiesPolicy;
