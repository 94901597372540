import { collection, onSnapshot, query, where, addDoc, orderBy, updateDoc, doc, getDoc } from "firebase/firestore";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { IBusiness } from "../interfaces/IBusiness";
import { packageStatusPhrase } from "../utils/functions";
import { BusinessContext } from "./BusinessContext";
import { FirebaseContext } from "./FirebaseContext";

export interface IParcelProps {
  tickets: any;
  ticketsClients: any;
  selectedTickets: any;
  selectedTicketsClients: any;
  allTickets: {
    id: any;
    tickets: {
      pending: IDoc[];
      progress: IDoc[];
      closed: IDoc[];
    };

    businessData: IBusiness;
  }[];
  loading: boolean;
  packagesToDownload: {
    recibido: string;
    entregado: string;
    comentarios: string;
    usuario: string;
    estado: string;
  }[];
  packagesToDownloadClient: {
    recibido: string;
    entregado: string;
    comentarios: string;
    usuario: string;
    estado: string;
  }[];
  addTicket: (data: IDoc) => Promise<void>;
  addTicketClient: (data: IDoc) => Promise<void>;
  addTicketBusiness: (data: IDocB) => Promise<void>;
  addTicketBusinessClient: (data: IDocB) => Promise<void>;
  updateTicketStatus: (status: string, id: string) => Promise<void>;
  updateTicketStatusClient: (status: string, id: string) => Promise<void>;
  responsableTickets: {
    closed: IDocB[];
    pending: IDocB[];
  };
  getTicketsByBusinessId: (id: string) => Promise<void>;
  getTicketsByUserId: (id: string) => Promise<void>;
  getTicketsByBusinessIdClient: (id: string) => Promise<void>;
  getTicketsByUserIdClient: (id: string) => Promise<void>;
}
export type IDoc = {
  id?: string;
  reason: string;
  userId: string;
  businessId: string;
  comments: string;
  timestamp: number;
  type: "user";
  status: "pending" | "closed";
  lastUpdate?: number;
};
export type IDocB = {
  id?: string;
  reason: string;
  businessId: string;
  comments: string;
  timestamp: number;
  type: "business";
  status: "pending" | "closed";
};

export const ParcelContext = createContext<IParcelProps>(null);

export const ParcelProvider: any = ({ children }: any) => {
  const [tickets, setTickets] = useState(null);
  const [ticketsClients, setTicketsClients] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState(null);
  const [selectedTicketsClients, setSelectedTicketsClients] = useState(null);
  const [packagesToDownload, setPackagesToDownload] = useState([]);
  const [packagesToDownloadClient, setPackagesToDownloadClient] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const { getUserData } = useContext(FirebaseContext);
  const { getBusinessById } = useContext(BusinessContext);
  const [responsableTickets, setResponsableTickets] = useState<{
    closed: IDocB[];
    pending: IDocB[];
  }>(null);
  const [loading, setLoading] = useState(true);

  const { db, user } = useContext(FirebaseContext);

  const getTicketsByBusiness = async () => {
    if (user) {
      const q = query(collection(db, "packages"), where("userId", "==", user.id), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };

          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setTickets({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getTicketsByBusinessClient = async () => {
    if (user) {
      const q = query(collection(db, "client-packages"), where("userId", "==", user.id), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };

          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setTicketsClients({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getTicketsByUserId = async (id: string) => {
    if (id) {
      const q = query(collection(db, "packages"), where("userId", "==", id), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };

          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setSelectedTickets({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getTicketsByBusinessId = async (id: string) => {
    if (id) {
      const q = query(collection(db, "packages"), where("businessId", "==", id), where("type", "==", "business"), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };

          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setSelectedTickets({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getTicketsByUserIdClient = async (id: string) => {
    if (id) {
      const q = query(collection(db, "client-packages"), where("userId", "==", id), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };

          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setSelectedTicketsClients({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getTicketsByBusinessIdClient = async (id: string) => {
    if (id) {
      const q = query(collection(db, "client-packages"), where("businessId", "==", id), where("type", "==", "business"), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };

          arr.push(data);
        });
        const pendingTickets = arr.filter((doc) => doc.status === "pending");
        const closedTickets = arr.filter((doc) => doc.status === "closed");

        setSelectedTicketsClients({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getTicketsByBusinessResponsable = async () => {
    if (user && user?.responsable) {
      const q = query(collection(db, "packages"), where("businessId", "==", user.businessId), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const responsablePackages: IDocB[] = [];

        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          responsablePackages.push(data);
        });
        const pendingTickets = responsablePackages.filter((doc) => doc.status === "pending");
        const closedTickets = responsablePackages.filter((doc) => doc.status === "closed");

        setResponsableTickets({
          pending: pendingTickets,
          closed: closedTickets,
        });
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getAllTickets = async () => {
    if (user) {
      const q = query(collection(db, "packages"), orderBy("timestamp", "desc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        const businessId = [];
        arr.forEach((doc) => {
          if (businessId?.length === 0) businessId.push(doc.businessId);
          if (businessId?.length > 0 && !businessId.find((id) => id === doc.businessId)) {
            businessId.push(doc.businessId);
          }
        });

        const ticketsByBusiness =
          businessId?.length > 0 &&
          (await Promise.all(
            businessId.map(async (id) => {
              const filtered = arr.filter((doc) => id === doc.businessId);
              const pendingTickets = filtered.filter((doc) => doc.status === "pending");

              const closedTickets = filtered.filter((doc) => doc.status === "closed");
              const businessData = await getBusinessData(id);

              return {
                id: id,
                tickets: {
                  pending: pendingTickets,
                  closed: closedTickets,
                },
                businessData: businessData,
              };
            })
          ));

        setAllTickets(ticketsByBusiness);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const returnFormatedDocToDowload = async (doc: IDoc) => {
    switch (doc.type) {
      case "user":
        const userDoc = await getUserData(doc.userId);
        const formatedDocUser = {
          recibido: moment(doc.timestamp).format("DD/MM/YYYY HH:mm"),
          entregado: doc.status === "closed" ? moment(doc.lastUpdate).format("DD/MM/YYYY HH:mm") : "",
          comentarios: doc.comments,
          usuario: userDoc.name,
          estado: packageStatusPhrase(doc.status),
        };
        return formatedDocUser;

      default:
        const businessDoc = await getBusinessById(doc.businessId);
        const formatedDocBusiness = {
          recibido: moment(doc.timestamp).format("DD/MM/YYYY HH:mm"),
          entregado: doc.status === "closed" ? moment(doc.lastUpdate).format("DD/MM/YYYY HH:mm") : "",
          comentarios: doc?.comments,
          usuario: businessDoc?.name,
          estado: packageStatusPhrase(doc?.status),
        };
        return formatedDocBusiness;
    }
  };
  const getAllTicketsForDownload = async () => {
    if (user) {
      const q = query(collection(db, "packages"), orderBy("timestamp", "desc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        const closedToday = [];
        const pendingPackages = [];
        await Promise.all(
          arr.map(async (doc: IDoc) => {
            const todayInit = new Date().setHours(0, 0, 0, 0);
            const todayFinish = new Date().setHours(23, 59, 0, 0);
            switch (doc.status) {
              case "closed":
                if (doc.lastUpdate >= todayInit && doc.lastUpdate <= todayFinish) {
                  const docToPush = await returnFormatedDocToDowload(doc);
                  closedToday.push(docToPush);
                }
                break;
              default:
                const docToPush = await returnFormatedDocToDowload(doc);
                pendingPackages.push(docToPush);
                break;
            }
          })
        );
        const arrayFinal = pendingPackages.concat(closedToday);
        setPackagesToDownload(arrayFinal);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getAllTicketsForDownloadClient = async () => {
    if (user) {
      const q = query(collection(db, "client-packages"), orderBy("timestamp", "desc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: IDoc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        const closedToday = [];
        const pendingPackages = [];
        await Promise.all(
          arr.map(async (doc: IDoc) => {
            const todayInit = new Date().setHours(0, 0, 0, 0);
            const todayFinish = new Date().setHours(23, 59, 0, 0);
            switch (doc.status) {
              case "closed":
                if (doc.lastUpdate >= todayInit && doc.lastUpdate <= todayFinish) {
                  const docToPush = await returnFormatedDocToDowload(doc);
                  closedToday.push(docToPush);
                }
                break;
              default:
                const docToPush = await returnFormatedDocToDowload(doc);
                pendingPackages.push(docToPush);
                break;
            }
          })
        );
        const arrayFinal = pendingPackages.concat(closedToday);
        setPackagesToDownloadClient(arrayFinal);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const addTicket = async (data: IDoc) => {
    try {
      await addDoc(collection(db, "packages"), {
        reason: data.reason,
        userId: data.userId,
        timestamp: data.timestamp,
        comments: data.comments,
        status: data.status,
        type: data.type,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addTicketClient = async (data: IDoc) => {
    try {
      await addDoc(collection(db, "client-packages"), {
        reason: data.reason,
        userId: data.userId,
        timestamp: data.timestamp,
        comments: data.comments,
        status: data.status,
        type: data.type,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addTicketBusiness = async (data: IDocB) => {
    try {
      await addDoc(collection(db, "packages"), {
        reason: data.reason,
        businessId: data.businessId,
        timestamp: data.timestamp,
        comments: data.comments,
        status: data.status,
        type: data.type,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addTicketBusinessClient = async (data: IDocB) => {
    try {
      await addDoc(collection(db, "client-packages"), {
        reason: data.reason,
        businessId: data.businessId,
        timestamp: data.timestamp,
        comments: data.comments,
        status: data.status,
        type: data.type,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBusinessData = async (id: string) => {
    if (id) {
      const docRef = doc(db, "business", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  };

  const updateTicketStatus = async (status: string, id: string) => {
    try {
      await updateDoc(doc(db, "packages", id), {
        status: status,
        lastUpdate: new Date().getTime(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateTicketStatusClient = async (status: string, id: string) => {
    try {
      await updateDoc(doc(db, "client-packages", id), {
        status: status,
        lastUpdate: new Date().getTime(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTicketsByBusiness();
    getTicketsByBusinessClient();
    getAllTicketsForDownload();
    getAllTicketsForDownloadClient();
    getTicketsByBusinessResponsable();
    getAllTickets();
  }, [user]);

  return (
    <ParcelContext.Provider
      value={{
        tickets,
        ticketsClients,
        selectedTickets,
        packagesToDownload,
        packagesToDownloadClient,
        responsableTickets,
        allTickets,
        loading,
        addTicket,
        addTicketBusiness,
        updateTicketStatus,
        updateTicketStatusClient,
        getTicketsByBusinessId,
        getTicketsByUserId,
        getTicketsByBusinessIdClient,
        getTicketsByUserIdClient,
        addTicketBusinessClient,
        addTicketClient,
        selectedTicketsClients,
      }}
    >
      {children}
    </ParcelContext.Provider>
  );
};
