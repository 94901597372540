import styled from "@emotion/styled";
import CardWithImages from "../../../components/CardWithImages";
import CardWithPng from "../../../components/CardWithPng";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { CardRow } from "../../Services";

const Container = styled.div`
  padding-bottom: 150px;
`;

const AboutBmc = () => {
  const services = [
    {
      title: "MANTENIMIENTO",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fmantenimiento.svg?alt=media&token=f2092c32-91c1-4f1a-aed8-3677265e985e",
    },
    {
      title: "GIMNASIO",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fgimnasio.svg?alt=media&token=c8d13015-f018-4bdd-9f2e-c31cba077f2a",
    },
    {
      title: "RESERVAS",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Freservas.svg?alt=media&token=de375894-6ff0-4538-b70a-3155d3f177aa",
    },
    {
      title: "PAQUETERÍA",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fpaqueteria.svg?alt=media&token=ec578916-dce8-4a63-a58c-d5131a47d4d4",
    },
    
    {
      title: "RESTAURACIÓN",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Frestauracion.svg?alt=media&token=405cf778-b6a6-49fc-86e6-09fc7fd83ce3",
    },
    {
      title: "PARKING",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fparking.svg?alt=media&token=3d34bbb2-7eef-4402-9984-3f4e1100f839",
    },
    {
      title: "WIFI",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fwifi.svg?alt=media&token=07c039e4-9f7a-42c5-8449-f89f2fdcf6fa",
    },
    {
      title: "FAX",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Ffax.svg?alt=media&token=1e6fefe7-30ea-4aba-a171-b84f0270cec7",
    },
    {
      title: "VIGILANCIA 24H",

      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fvigilancia.svg?alt=media&token=ab9eb2c5-e39b-4001-b445-a5da9c60a72e",
    },
    {
      title: "24H ABIERTO",
      
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2F24h.svg?alt=media&token=b7b0d842-5d8c-4163-a3a0-6c6bd1157922",
    },
  ];
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Faboutbmc%2Fcabecera_sobre_bmc.jpg?alt=media&token=363e51a3-4bbc-4ab8-b467-8339d3197916"
          title="SOBRE BMC"
          subtitle="En BMC puedes encontrar las empresas del futuro. Descubre todo lo que el edificio puede ofrecerte."
          showBackButton
        />
        <CardRow>
        {services.map((service, i) => {
            return (
              <CardWithPng color='#272728' key={`id-${service.title}-${i}`} item={service} />
            );
          })}
        </CardRow>
      </Container>
    </Layout>
  );
};

export default AboutBmc;
