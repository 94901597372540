import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import FloatButton from "../../components/FloatButton";
import { useContext } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import TicketsAdmin from "./ticketsAdmin";
import TicketsWorker from "./ticketsWorker";

const MaintenanceContainer = styled.div`
  height: 100vh;
  position: relative;
  padding-bottom:150px;
`;
export const CardsColumn = styled.div`
  width: 100%;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 150px; */
`;
export const CardTicket = styled.div`
  width: 90%;
  min-height: 146px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 0px #0000006b;
  margin: 20px auto;
  position: relative;
`;
export const HeaderTicket = styled.div<{ bg: string }>`
  height: 31px;
  background-color: ${(props) => props.bg && props.bg};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const TextTicket = styled.div`
  height: 100%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
`;

const Maintenance = () => {
  const navigate = useNavigate();
  const { user } = useContext(FirebaseContext);

  const addTicket = () => {
    navigate("/new-ticket");
  };

  return (
    <Layout>
      <MaintenanceContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/cabecera_mantenimiento.jpg?alt=media&token=fe9dc191-d2ac-43b6-a3ef-ff639fe75b33"
          title="MANTENIMIENTO"
          subtitle="Haz la solicitud a nuestros técnico explicando el problema y nos pondremos en contacto contigo lo antes posible."
          showBackButton
        />
       <FloatButton onClick={addTicket} />
        <CardsColumn>
         <TicketsWorker />
        </CardsColumn>
      </MaintenanceContainer>
    </Layout>
  );
};

export default Maintenance;
