import { collection, onSnapshot, query, addDoc, where, getDocs } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { doc, deleteDoc } from "firebase/firestore";
import { BusinessContext } from "./BusinessContext";
import { IBusiness } from "../interfaces/IBusiness";
import { IUser } from "../interfaces/IUser";
import { salasConfig } from "../interfaces/constants/salas";
import moment from "moment";

export interface ISalasProps {
  books: IMyBook[];
  allBusinessBooks: IMyBook[];
  allBooksById: IMyBook[];
  allBooks: IMyBook[];
  salas: ISala[];
  booksToday: ICompleteBook[];
  addBook: (data: IBook) => Promise<void>;
  getAllBooksById: (id: any) => void;
  deleteBook: (id: string) => Promise<void>;
  checkOpenDoor: any;
  loading: boolean;
  loadingById: boolean;
  getBooksById: (id: any, daySelected: any) => void;
  checkBooks: (daySelected: any, timeSelected: any, salaId?: string) => Promise<boolean>;
}
export type IBook = {
  id?: string;
  salaId: string;
  bookedBy: string;
  businessId: string;
  daySelected: number;
  timeSelected: number;
  type?: "web";
};
export type ISala = {
  id?: string;
  name: string;
  type: "boardroom" | "polivalent" | "bmc";
  capacity: string;
  src?: string;
};
export type IMyBook = {
  salaData: ISala;
  businessData: IBusiness;
  userData: IUser;
  id?: string;
  salaId: string;
  bookedBy: string;
  businessId: string;
  daySelected: number;
  timeSelected: number;
  type?: "web";
};

export interface ICompleteBook extends IMyBook {
  salaData: ISala;
  businessData: IBusiness;
  userData: IUser;
}

export const SalasContext = createContext<ISalasProps>(null);

export const SalasProvider: any = ({ children }: any) => {
  const [books, setBooks] = useState<ICompleteBook[]>([]);
  const [allBooksById, setAllBooksById] = useState<ICompleteBook[]>([]);
  const [allBusinessBooks, setAllBusinessBooks] = useState<ICompleteBook[]>([]);
  const [allBooks, setAllBooks] = useState<ICompleteBook[]>([]);
  const [booksToday, setBooksToday] = useState<ICompleteBook[]>([]);
  const [salas, setSalas] = useState<ISala[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingById, setLoadingById] = useState(true);
  const { business, getBusinessById } = useContext(BusinessContext);

  const { db, user, getUserData } = useContext(FirebaseContext);

  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  const addBook = async (data: IBook) => {
    try {
      await addDoc(collection(db, "sala-books"), {
        salaId: data.salaId,
        daySelected: data.daySelected,
        bookedBy: data.bookedBy,
        businessId: data.businessId,
        timeSelected: data.timeSelected,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const checkBooks = async (daySelected, timeSelected, salaId) => {
    const q = query(collection(db, "sala-books"), where("salaId", "==", salaId), where("daySelected", "==", daySelected), where("timeSelected", "==", timeSelected));
    const docSnap = await getDocs(q);
    return docSnap?.empty;
  };

  const deleteBook = async (id: string) => {
    await deleteDoc(doc(db, "sala-books", id));
  };

  const getBooksById = (id, daySelected) => {
    if (user) {
      try {
        const q = query(collection(db, "sala-books"), where("salaId", "==", id), where("daySelected", "==", daySelected));
        onSnapshot(q, async (querySnapshot) => {
          const books = await Promise.all(
            querySnapshot.docs.map(async (doc: any) => {
              try {
                const data: IMyBook = { id: doc.id, ...doc.data() };
                const salaBook = salas.find((sala) => sala.id === data.salaId);
                const businessBook = await getBusinessById(data.businessId);
                let userBook;
                if (!data.type) {
                  userBook = await getUserData(data.bookedBy);
                }
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                  userData: userBook,
                };
              } catch (error) {}
            })
          );
          setBooks(books);
        });
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };
  const getAllBooksById = (id) => {
    if (user) {
      const q = query(collection(db, "sala-books"), where("salaId", "==", id));
      onSnapshot(q, async (querySnapshot) => {
        const books = await Promise.all(
          querySnapshot.docs.map(async (doc: any) => {
            try {
              const data: IMyBook = { id: doc.id, ...doc.data() };
              const salaBook = salas.find((sala) => sala.id === data.salaId);
              const businessBook = await getBusinessById(data.businessId);
              let userBook;
              if (!data.type) {
                userBook = await getUserData(data.bookedBy);
              }
              if (userBook) {
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                  userData: userBook,
                };
              } else
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                };
            } catch (error) {}
          })
        );
        setAllBooksById(books);
      });
      setTimeout(() => {
        setLoadingById(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setLoadingById(false);
      }, 2000);
    }
  };
  const getAllBooksByBusiness = () => {
    if (user) {
      const q = query(collection(db, "sala-books"), where("daySelected", ">=", new Date().setHours(0, 0, 0, 0)), where("businessId", "==", user.businessId));
      onSnapshot(q, async (querySnapshot) => {
        const books = await Promise.all(
          querySnapshot.docs.map(async (doc: any) => {
            try {
              const data: IMyBook = { id: doc.id, ...doc.data() };
              const salaBook = salas.find((sala) => sala.id === data.salaId);
              const businessBook = await getBusinessById(data.businessId);
              let userBook;
              if (!data.type) {
                userBook = await getUserData(data.bookedBy);
              }
              if (userBook) {
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                  userData: userBook,
                };
              } else
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                };
            } catch (error) {
              console.log(error);
            }
          })
        );

        setAllBusinessBooks(books);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getAllBooks = () => {
    if (user) {
      const q = query(collection(db, "sala-books"), where("daySelected", ">=", new Date().setHours(0, 0, 0, 0)));
      onSnapshot(q, async (querySnapshot) => {
        const books = await Promise.all(
          querySnapshot.docs.map(async (doc: any) => {
            try {
              const data: IMyBook = { id: doc.id, ...doc.data() };
              const salaBook = salas.find((sala) => sala.id === data.salaId);
              const businessBook = await getBusinessById(data.businessId);
              let userBook;
              if (!data.type) {
                userBook = await getUserData(data.bookedBy);
              }
              if (userBook) {
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                  userData: userBook,
                };
              } else
                return {
                  ...data,
                  salaData: salaBook,
                  businessData: businessBook,
                };
            } catch (error) {}
          })
        );
        filterBooksToday(books);
        setAllBooks(books);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const filterBooksToday = (books: ICompleteBook[]) => {
    let today = new Date().setHours(0, 0, 0, 0);
    let todayBooks: ICompleteBook[] = [];
    books.forEach((book: ICompleteBook) => {
      if (book.daySelected === today) {
        todayBooks.push(book);
      }
    });
    setBooksToday(todayBooks);
  };

  const getSalas = () => {
    const q = query(collection(db, "salas"));
    onSnapshot(q, (querySnapshot) => {
      const arr: ISala[] = [];
      querySnapshot.forEach((doc: any) => {
        const data = { id: doc.id, ...doc.data() };
        arr.push(data);
      });
      setSalas(arr);
    });
    setLoading(false);
  };

  const checkOpenDoor = async (sala: string) => {
    const idSalaToCheck = salasConfig[sala.toLowerCase()];
    let dateTodayToCompare = new Date().setMinutes(0, 0);
    if (idSalaToCheck && user?.name) {
      const resultBook = await Promise.all(
        booksToday.filter((bookToday) => {
          let date1 = new Date(bookToday.timeSelected);
          let date2 = new Date(dateTodayToCompare);

          let hourBook = date1.getHours();
          let hourToCompare = date2.getHours();

          if (bookToday.salaId === idSalaToCheck && hourBook === hourToCompare && bookToday.businessId === user.businessId) {
            return bookToday;
          }
        })
      );

      return resultBook[0];
    }
  };

  const checkHoursAvailable = async () => {};

  useEffect(() => {
    getSalas();
  }, [user, db]);

  useEffect(() => {
    business?.length > 0 && salas?.length > 0 && getAllBooksByBusiness();
    business?.length > 0 && salas?.length > 0 && getAllBooks();
  }, [user, db, business, salas]);

  return (
    <SalasContext.Provider
      value={{
        books,
        allBooks,
        allBooksById,
        allBusinessBooks,
        booksToday,
        checkOpenDoor,
        getBooksById,
        getAllBooksById,
        salas,
        addBook,
        loading,
        loadingById,
        deleteBook,
        checkBooks,
      }}
    >
      {children}
    </SalasContext.Provider>
  );
};
