import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { QrReader } from "react-qr-reader";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "../../fb/config";
import { Title } from "../../genericComponents";
import SectionHeader from "../../components/SectionHeader";
import styled from "@emotion/styled";
import ModalCusstom from "../../components/ModalCustom";
import { useNavigate } from "react-router";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../context/FirebaseContext";
import Loading from "../../components/Loading";
import { GYM, SALAS, TORNOS } from "../../utils/constants";
import { checkTypeQr } from "../../utils/functions";
import { SalasContext } from "../../context/SalasContext";

const ContainerQR = styled.div`
  padding: 20px;
  margin-bottom: auto;
 
`;

const QrScanner = () => {
  const [data, setData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { db, user } = useContext(FirebaseContext);
  const {checkOpenDoor } = useContext(SalasContext);

  const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);
  const navigation = useNavigate();
  const openTorn = httpsCallable(functions, "openTorn");

  const checkQr = async (result: any) => {
    try {
      if (result.text) {
        let typeQr = checkTypeQr(result.text);
        if (typeQr === TORNOS || typeQr === GYM || user.roles.super || user.roles.cleaner) {
          await openTorn({ nameTorno: result.text });
          setOpenModal(true);
          setData("Puerta abierta correctamente");
          newEntry();
          setTimeout(() => {
            navigation("/");
          }, 2000);
        } else if (typeQr === SALAS) {
          
          let resultToCheck = await checkOpenDoor(result.text);
          
          if (resultToCheck) {
           
            await openTorn({ nameTorno: result.text });
            setOpenModal(true);
            setData(`Bienvenido ${resultToCheck.userData.name}`);
            newEntry();
            setTimeout(() => {
              navigation("/");
            }, 2000);
          } else {
            setOpenModal(true);
            setData("No tienes permiso para acceder.");
            newEntry();
            setTimeout(() => {
              navigation("/");
            }, 3000);
          }
        }
      }
    } catch (error) {
      setOpenModal(true);
      setData("Código erróneo. Inténtelo de nuevo");

      setTimeout(() => {
        setOpenModal(false);
      }, 2000);

    } finally {
      setLoading(false);
    }
  };

  const newEntry = async () => {
    let date = new Date();
    let idToday = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

    // Primero obtenemos la colección y el elemento con la fecha de hoy
    const docRef = doc(db, "statistics-access", idToday);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Si existe, miramos si ese usuario ha entrado ya
      let result = docSnap.data();
      if (result) {
        if (!result.totalAccess.find((res) => res.userId === user.id)) {
          //Si no ha entrado, creamos un registro
          await updateDoc(doc(db, "statistics-access", idToday), {
            totalAccess: arrayUnion({
              userId: user.id,
              timestamp: new Date().getTime(),
            }),
          });
        }
      }
    } else {
      // Si no existe la fecha de hoy, lo creamos con el usuario ya añadido
      const docRef = doc(db, "statistics-access", idToday);
      setDoc(docRef, {
        creationDate: new Date().setHours(0, 0, 0, 0),
        totalAccess: [
          {
            userId: user.id,
            timestamp: new Date().getTime(),
          },
        ],
      });
    }
  };

  return (
    <Layout>
      <SectionHeader title="ACCESO" subtitle="Escanea el código QR para acceder." showBackButton />
      <ContainerQR>
        {!openModal && !loading && (
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setLoading(true);
                checkQr(result);
              } else {
                console.log(error);
              }
            }}
            constraints={{ facingMode: "environment" }}
          />
        )}
      </ContainerQR>
      <ModalCusstom message={data} open={openModal} />
      {loading && <Loading />}
    </Layout>
  );
};

export default QrScanner;
