import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Title } from "../../genericComponents";

const Card = styled.div<{ img: string }>`
  width: 45%;
  margin-bottom: 15px;
  height: 152px;
  box-shadow: 0px 2px 5px 2px #00000012;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${(props) => props.img && `url(${props.img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const BlurContainer = styled.div`
  margin-top: auto;

  padding: 10px;
  height: 100%;
`;
const SkeletonCustom = styled(Skeleton)`
  margin-bottom: 15px;
  box-shadow: 0px 2px 5px 2px #00000012;
`;
const LazyImage = ({ item, color }) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = item?.img;
    img.onload = () => setSource(item?.img);
  }, [item?.img]);

  return source ? (
    <Card img={item?.img}>
      <BlurContainer>
        <Title
          align="center"
          color={color ? color : "#fff"}
          weight="200"
          size="14px"
        >
          {item?.title}
        </Title>
      </BlurContainer>
    </Card>
  ) : (
    <SkeletonCustom
      animation="wave"
      variant="rectangular"
      width="45%"
      height="152px"
    />
  );
};

const CardWithPng = ({ item, color }: { item: any; color?: string }) => {
  return (
    <LazyImage color={color} item={item} />
  );
};

export default CardWithPng;
