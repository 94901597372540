import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router";
import CallButtonWithProps from "../../components/CallButton";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { BorderBottomCustom, Text } from "../../genericComponents";
import GymWorker from "./GymWorker";

const GymContainer = styled.div`
  padding-bottom: 150px;
`;

const ContainerContact = styled.div`
  padding: 20px 0;
  background-color: #f6f6f6;
`;

const Gym = () => {
  const navigation = useNavigate();
  const [showMore, setShowMore] = useState(false);
  return (
    <Layout>
      <GymContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fcabecera_gimnasio.jpg?alt=media&token=0ae03f8c-bfd4-418d-91a7-bfe3241085de"
          title="GIMNASIO"
          subtitle="Descubre todas las actividades que tenemos preparadas para que cuides tu cuerpo sin tener que salir del edificio."
          showBackButton
        />
        <GymWorker />
        <Text
          top="20px"
          align="center"
          width="80%"
          left="auto"
          right="auto"
          weight="200"
          size="14px"
          italic={true}
        >
          *Recuerda que las clases se inician con un mínimo de 5 inscripciones. El pago de las clases se gestiona directamente con el
          técnico.
        </Text>
        {!showMore && (
          <Text
            onClick={() => setShowMore(true)}
            top="10px"
            align="center"
            decoration="underline"
          >
            Ver precios
          </Text>
        )}

        {showMore && (
          <Text
            size="14px"
            onClick={() => setShowMore(false)}
            weight="200"
            top="20px"
            align="center"
          >
            Clase puntual: <strong>7€</strong>
          </Text>
        )}
        {showMore && (
          <Text
            size="14px"
            width="80%"
            left="auto"
            right="auto"
            onClick={() => setShowMore(false)}
            weight="200"
            top="20px"
            align="center"
          >
            1 entrenamiento a la semana (día y horario fijo):
            <strong> 26€/mes.</strong>Incluye acceso a la app de Prohabits para
            deberes.
          </Text>
        )}
        {showMore && (
          <Text
            size="14px"
            width="80%"
            left="auto"
            right="auto"
            onClick={() => setShowMore(false)}
            weight="200"
            top="20px"
            bottom="40px"
            align="center"
          >
            2 entrenamientos a la semana (día y horario fijo):
            <strong> 35€/mes.</strong>Incluye acceso a la app de Prohabits para
            deberes.
          </Text>
        )}
        {showMore && (
          <Text
            onClick={() => setShowMore(false)}
            top="10px"
            align="center"
            decoration="underline"
          >
            Ocultar precios
          </Text>
        )}

        <BorderBottomCustom color="#000" />
        <Text
          top="20px"
          align="center"
          width="60%"
          left="auto"
          right="auto"
          weight="700"
          size="14px"
        >
          ¿Quieres formar un grupo para tu empresa?
        </Text>
        <Text
          top="20px"
          align="center"
          width="80%"
          left="auto"
          right="auto"
          weight="200"
          size="14px"
        >
          Ponte en contacto con los organizadores y descubre todas las opciones
          disponibles.
        </Text>
        <CallButtonWithProps
          type="submit"
          text="Llamar"
          top="20px"
          left="5px"
          border="none"
          href="tel:648273795"
        />
        <BorderBottomCustom color="#000" />
        <ContainerContact>
          <Text
            top="0px"
            align="center"
            width="60%"
            left="auto"
            right="auto"
            weight="700"
            size="14px"
          >
            ¿Tienes en mente alguna propuesta de actividad?
          </Text>
          <Text
            top="20px"
            align="center"
            width="80%"
            left="auto"
            right="auto"
            weight="200"
            size="14px"
          >
            Ponte en contacto con BMC y cuéntanos qué te gustaría hacer
            ¡Nosotros lo gestionamos!
          </Text>
          <CallButtonWithProps
            type="button"
            onClick={() => navigation("/contact")}
            text="Contactar"
            top="20px"
            left="5px"
            border="none"
            href="tel:648273795"
          />
        </ContainerContact>
      </GymContainer>
    </Layout>
  );
};

export default Gym;
