import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import InputButtonWithProps from "../../../components/InputButton";
import InputWithLegend from "../../../components/InputWithLegend";
import Layout from "../../../components/Layout";
import ModalCusstom from "../../../components/ModalCustom";
import { BusinessContext } from "../../../context/BusinessContext";
import { FirebaseContext } from "../../../context/FirebaseContext";

import { AuthCardSignUp, Text, Title } from "../../../genericComponents";
import { IBusiness } from "../../../interfaces/IBusiness";

import TextAreaWithLegend from "../../../components/TextAreaWithLegend";
import { normalizeString } from "../../../utils/functions";
import SelectCategory from "../../../components/SelectCategory";
import { Checkbox } from "@mui/material";
import InputCheckbox from "../../../components/InputCheckbox";

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const CardContainer = styled.div`
  width: 100%;
  background: #f6f6f6;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 250px;
`;
const TitleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 82px;
  width: 82px;
  background: ${(props) => (props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const Row = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;
const EditBusiness = () => {
  const location: any = useLocation();
  const { loadingUser, uploadImage } = useContext(FirebaseContext);

  const { id } = location?.state;

  const { getBusinessById, updateBusiness, categories } = useContext(BusinessContext);
  const navigation = useNavigate();
  const [businessData, setBusinessData] = useState<IBusiness>({
    name: "",
    nameToFilter: "",
    nif: "",
    locals: [],
    description: "",
    logo: "",
    id: "",
    phone:'',
    category:'',
    web:'',
    showInBMC:true
  });

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [anyChange, setAnyChange] = useState(false);
  const [localNew, setLocalNew] = useState("");

const handleChangeShowInBMC=(e:any)=>{
  setAnyChange(true);
  setBusinessData({ ...businessData, [e.target.name]: e.target.checked });
  //e.preventDefault();
}

  const handleChange = (e: any) => {
    setAnyChange(true);
    setBusinessData({ ...businessData, [e.target.name]: e.target.value });
    e.preventDefault();
    switch (e.target.name) {
      case "image":
        setImage(e.target.files[0]);

        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      case "name":
        const copyStr = e.target.value;
        setBusinessData({ ...businessData, [e.target.name]: e.target.value, nameToFilter: normalizeString(copyStr) });
        break;
      case "category":
        setBusinessData({ ...businessData, [e.target.name]: e.target.value});
        break;

      default:
        setBusinessData({ ...businessData, [e.target.name]: e.target.value });
        break;
    }
  };
  

  const sendRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage("Actualizando datos...");
    setModalStatus(true);
    if (image) {
      const url = await uploadImage(image);

      await updateBusiness({ ...businessData, logo: url as string, nameToFilter: normalizeString(businessData.name) });
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        setAnyChange(false);
      }, 3000);
    } else {
      await updateBusiness({ ...businessData, nameToFilter: normalizeString(businessData.name) });
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        setAnyChange(false);
      }, 3000);
    }
  };

  const getInitialData = async () => {
    const businessData = await getBusinessById(id);
    setBusinessData(businessData);
  };
  useEffect(() => {
    !loadingUser && id && getInitialData();
  }, [id]);

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Text top="0" weight="100" size="24px" onClick={() => navigation(-1)}>
            ←
          </Text>
          <Title size="25px" weight="200" top="10px">
            Modificar empresa
          </Title>
        </TitleContainer>
        <CardContainer>
          <AuthCardSignUp autoComplete="off" onSubmit={(e: React.FormEvent<HTMLFormElement>) => sendRequest(e)}>
            <Text width="100%" color="#5F5F5F" size="14px" top="10px" bottom="5px">
              Foto de perfil
            </Text>
            <ImageCircle imagePreview={preview ? preview : businessData?.logo && businessData.logo} onClick={() => document.getElementById("image").click()}>
              {!image || (!businessData?.logo && <Image src="/upload.png" />)}
            </ImageCircle>
            <Invisible>
              <InputInvisible name="image" type="file" id="image" onChange={handleChange} title="Imagen de perfil" />
            </Invisible>
            <InputWithLegend legendColor="#5F5F5F" type="text" name="name" title="Nombre" width="100%" value={businessData?.name} onChange={handleChange} />
            <InputWithLegend legendColor="#5F5F5F" type="text" name="nif" title="NIF" width="100%" value={businessData?.nif} onChange={handleChange} />
            <TextAreaWithLegend legendColor="#5F5F5F" name="description" title="Descripción" width="100%" value={businessData?.description} onChange={handleChange} />

            <InputWithLegend width="100%" legendColor="#5F5F5F" type="email" name="email" title="E-mail" value={businessData?.email} onChange={handleChange} />
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="text" name="phone" title="Teléfono" value={businessData?.phone} onChange={handleChange} />
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="text" name="web" title="Página web" value={businessData?.web} onChange={handleChange} />
            <SelectCategory
            legend="Categoría"
              options={categories}
              value={categories?.find((category) => (businessData?.category === category.id)) ? businessData?.category : "unselected"}
              handleChange={handleChange}
            />
            <InputWithLegend legendColor="#5F5F5F" type="text" name="local" title="Local*" width="100%" value={localNew} onChange={(e) => setLocalNew(e.target.value)} />
            <InputButtonWithProps
              disabled={!localNew}
              type="button"
              onClick={() => {
                const copy: IBusiness = JSON.parse(JSON.stringify(businessData));
                copy.locals.push(localNew);
                setBusinessData(copy);
                setLocalNew("");
              }}
              top="20px"
              text="Añadir local"
            />
            <Text width="100%" color="#5F5F5F" size="14px" top="10px" bottom="5px">
              Locales
            </Text>
            {businessData?.locals.length > 0 &&
              businessData.locals.map((local, idx) => {
                return (
                  <Row key={`${local}-${idx}`}>
                    <Text
                      pointer
                      onClick={() => {
                        const copy: IBusiness = JSON.parse(JSON.stringify(businessData));
                        setAnyChange(true);
                        const deletedCopy = copy.locals.filter((doc) => doc !== local);
                        setBusinessData({
                          ...businessData,
                          locals: deletedCopy,
                        });
                        setLocalNew("");
                      }}
                      size="14px"
                      color="#822d2d"
                      top="10px"
                      weight="bolder"
                      align="right"
                      width="10%"
                    >
                      X
                    </Text>
                    <Text size="14px" color="#5F5F5F" top="10px" align="left" left="5%" width="85%">
                      {local}
                    </Text>
                  </Row>
                );
              })}
{/* <InputWithLegend legendColor="#5F5F5F" type="checkbox" title="Mostrar en la web de BMC" name="showInBMC" checked={businessData?.showInBMC} onChange={handleChangeShowInBMC}/> */}
<InputCheckbox id="showBMC" padding="15px" width="100%" text="Mostrar en la web de BMC" name="showInBMC" checked={businessData?.showInBMC} onChange={handleChangeShowInBMC}/>
            <InputButtonWithProps type="submit" disabled={!anyChange || !businessData.name || !businessData.locals.length} top="20px" text="Guardar datos" />
          </AuthCardSignUp>
        </CardContainer>
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default EditBusiness;
