import styled from "@emotion/styled";
import LazyImage from "../LazyImage";

interface ISectionFooter {
  src: string;
}

const ImageHeader = styled.div<{ src: string }>`
  background: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  height: 91px;
  width: 100%;
`;

const SectionFooter = ({ src }: ISectionFooter) => {
  return <LazyImage src={src} animation="wave" variant="rectangular" width="100%" height="91px" />;
};

export default SectionFooter;
