import { collection, onSnapshot, query, addDoc, orderBy, updateDoc, arrayUnion, arrayRemove, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { doc, deleteDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../fb/config";

export interface IGymProps {
  events: IEvent[];
  addEvent: (data: IEvent) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
  updateGymStatus: (eventId: string, userId: any) => Promise<void>;
  deleteUserToEvent: (eventId: string, userId: any) => Promise<void>;
  loading: boolean;
}
export type IEvent = {
  id?: string;
  weekDays: {
    Lunes: {
      position: number;
      checked: boolean;
    };
    Martes: {
      position: number;
      checked: boolean;
    };
    Miércoles: {
      position: number;
      checked: boolean;
    };
    Jueves: {
      position: number;
      checked: boolean;
    };
    Viernes: {
      position: number;
      checked: boolean;
    };
    Sábado: {
      position: number;
      checked: boolean;
    };
    Domingo: {
      position: number;
      checked: boolean;
    };
  };
  timestamp: number;
  capacity: number;
  place: string;
  image: string;
  title: string;
  hourInit: string;
  hourFinal: string;
  participants: string[];
};
// export type IEvent = {
//   id?: string;
//   title: string;
//   participants: string[];
//   capacity: number;
//   timestamp: number;
//   place: string;
//   image: string;
// };

export const GymContext = createContext<IGymProps>(null);

export const GymProvider: any = ({ children }: any) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);
  const sendMail = httpsCallable(functions, "sendMailDeletedGymEvent");

  const { db, user, getUserData } = useContext(FirebaseContext);

  const addEvent = async (data: IEvent) => {
    try {
      await addDoc(collection(db, "gym-events"), {
        participants: [],
        weekDays: data.weekDays,
        capacity: data.capacity,
        timestamp: data.timestamp,
        place: data.place,
        image: data.image,
        hourInit: data.hourInit,
        hourFinal: data.hourFinal,
        title: data.title,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteEvent = async (id: string) => {
    const eventToDelete: IEvent = events.find((doc) => doc.id === id);
    const usersToNotice = await Promise.all(eventToDelete?.participants?.map(async (id) => await getUserData(id)));
    await Promise.all(usersToNotice?.map(async (user) => await sendMail({ email: user?.email, userName: user?.name, event: eventToDelete })));
    await deleteDoc(doc(db, "gym-events", id));
  };

  useEffect(() => {
    if (user) {
      const q = query(collection(db, "gym-events"));
      onSnapshot(q, (querySnapshot) => {
        const arr: IEvent[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        setEvents(arr);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [user, db]);

  const updateGymStatus = async (eventId: string, userId) => {
    try {
      await updateDoc(doc(db, "gym-events", eventId), {
        participants: arrayUnion(userId),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteUserToEvent = async (eventId: string, userId) => {
    try {
      await updateDoc(doc(db, "gym-events", eventId), {
        participants: arrayRemove(userId),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <GymContext.Provider
      value={{
        events,
        addEvent,
        loading,
        deleteEvent,
        updateGymStatus,
        deleteUserToEvent,
      }}
    >
      {children}
    </GymContext.Provider>
  );
};
