import {
  collection,
  onSnapshot,
  query,
  addDoc,
  where,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { doc, deleteDoc } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import axios from "axios";

export interface ICanteenProps {
  offers: IOffer[];
  addOffer: (data: IOffer) => Promise<void>;
  deleteOffer: (id: string) => Promise<void>;
  loading: boolean;
}
export type IOffer = {
  id?: string;
  title: string;
  initTimestamp: number;
  finishTimestamp: number;
  price: string;
  image: any;
  sliderVisible?: boolean;
};
export const CanteenContext = createContext<ICanteenProps>(null);

export const CanteenProvider: any = ({ children }: any) => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const now = new Date().setHours(0, 0, 0, 0);

  const { db, user } = useContext(FirebaseContext);

  const topic = "canteen";

  const addOffer = async (data: IOffer) => {
    try {
      await addDoc(collection(db, "canteen-offers"), {
        initTimestamp: data.initTimestamp,
        finishTimestamp: data.finishTimestamp,
        price: data.price,
        image: data.image,
        title: data.title,
      });
      const message = {
        data: {
          title: "Nueva oferta en la cafetería",
          body: data.title,
        },
        topic: topic,
      };
      
      const result = await axios.post(
        "https://us-central1-bmc-app-87a78.cloudfunctions.net/sendNotification",
        {
          message: message,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOffer = async (id: string) => {
    await deleteDoc(doc(db, "canteen-offers", id));
  };

  useEffect(() => {
    if (user) {
      const q = query(
        collection(db, "canteen-offers"),
        // orderBy("initTimestamp", "desc"),
        // where("initTimestamp", "<=", now),
        // where("finishTimestamp", ">=", now)
      );
      onSnapshot(q, (querySnapshot) => {
        const arr: IOffer[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        setOffers(arr);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [user, db]);

  return (
    <CanteenContext.Provider
      value={{
        offers,
        addOffer,
        loading,
        deleteOffer,
      }}
    >
      {children}
    </CanteenContext.Provider>
  );
};
