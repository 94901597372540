import { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import FloatButton from "../../components/FloatButton";
import { BusinessContext } from "../../context/BusinessContext";
import { Box } from "@mui/system";
import { ListItem } from "@mui/material";
import CircleIcon from "../../components/CircleIcon";
import { Text } from "../../genericComponents";
import ModalAccept from "../../components/ModalAccept";
import ModalCusstom from "../../components/ModalCustom";
import axios from "axios";
import { FirebaseContext } from "../../context/FirebaseContext";
import { MaintenanceContext } from "../../context/MaintenanceContext";
import { IBusiness } from "../../interfaces/IBusiness";
import { MoreBtn } from "../Users";

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
`;

const Container = styled.div`
  padding-bottom: 180px;
`;

export const BoxCustom = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ListItemCustom = styled(ListItem)`
  padding: 30px;
  justify-content: start;
  text-align: left;
  background-color: #f6f6f6;
  border-bottom: 0.5px solid #000;
  font-weight: 300;
  width: 100%;
  cursor: pointer;
`;
export const List = styled.div`
  width: 100%;
`;

const Searchbar = styled.input`
  padding: 5px 0;
  width: 90%;
`;

const ContainerSearchbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: Center;
  width: 90%;
  margin: 10px auto;
`;
export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
  justify-content: space-between;
`;

const BusinessPanel = () => {
  const navigation = useNavigate();
  const { business, deleteBusiness, deleteBusinessTickets, getAllBusinessByName, getFirebaseNextBusiness, getFirebaseAllBusiness } = useContext(BusinessContext);
  const { allTicketsList } = useContext(MaintenanceContext);

  const { getUsersByBusinessId } = useContext(FirebaseContext);

  const [value, setValue] = useState("");

  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [completeData, setCompleteData] = useState(false);


  const [selectedDoc, setSelectedDoc] = useState(null);

  const [filteredData, setFilteredData] = useState([]);



  useEffect(() => {
  business && setFilteredData(business)
  }, [business]);
  useEffect(() => {
  getFirebaseAllBusiness()
  }, []);





  const filterBusiness = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setValue(e.target.value);
    const copyStr = e.target.value;
    if (e.target.value) {
      setCompleteData(true);
     const data = await getAllBusinessByName(copyStr.toUpperCase());
    
    } else {
      setCompleteData(false);
      await getFirebaseAllBusiness()
    }
  };

  const nextData = async () => {
    const allBusinessCopy = filteredData.slice();
    const lastVisible = allBusinessCopy[allBusinessCopy.length - 1];

    const more = await getFirebaseNextBusiness(lastVisible.name);
   
    if (!business.length) {
      setCompleteData(true);
    }
  };

  const checkUsersToDelete = async (doc) => {
    const users = await getUsersByBusinessId(doc.id);

    return users;
  };

  const checkTicketsToDelete = async (doc) => {
    const tickets = await Promise.all(allTicketsList.filter((ticket) => ticket.businessId === doc.id));
    return tickets;
  };
  const deleteDoc = async (doc) => {
    setModalConfirm(false);
    setModalStatus(true);

    const usersToDelete = await checkUsersToDelete(doc);

    const ticketsToDelete = await checkTicketsToDelete(doc);

    if (ticketsToDelete?.length > 0) {
      setMessage("Borrando tickets...");
      await deleteBusinessTickets(ticketsToDelete);
    }

    if (usersToDelete && usersToDelete?.length > 0) {
      setMessage("Borrando trabajadores...");

      await Promise.all(
        usersToDelete.map(async (user) => {
          return await axios.post("https://us-central1-bmc-app-87a78.cloudfunctions.net/deleteUser", {
            uid: user.uid,
            id: user.id,
          });
        })
      );
    }

    setMessage("Borrando empresa...");

    await deleteBusiness(doc);

    setMessage("Empresa borrada con éxito");
    setTimeout(() => {
      setMessage("");
      setModalStatus(false);
      setSelectedDoc(null);

    }, 2000);
  };

  const closeModalConfirm = () => {
    setModalConfirm(false);
  };

  return (
    <Layout>
      <Container>
        <SectionHeader src="/profile.jpeg" title="EMPRESAS" subtitle="Gestiona empresas" showBackButton />
        <ContainerSearchbar>
          <Searchbar placeholder="Buscar..." type="text" name="client" value={value} onChange={(e) => filterBusiness(e)} />
        </ContainerSearchbar>
        <ItemsContainer>
          <BoxCustom>
            <List>
              {filteredData.map((doc: IBusiness, idx) => {
                return (
                  <ListItemCustom key={doc.id + idx}>
                    <Text width="80%">{doc.name}</Text>
                    {/* {doc?.web && <Text width="80%">{doc?.web}</Text>} */}
                    <ContainerIcons>
                      <CircleIcon
                        funcClick={() => {
                          navigation("/edit-business", {
                            state: { id: doc.id },
                          });
                        }}
                        color="#000000"
                        src="/pencil.png"
                      />
                      <CircleIcon
                        color="#BF2A2A"
                        src="/bin.png"
                        funcClick={() => {
                          setModalConfirm(true);
                          setSelectedDoc(doc);
                        }}
                      />
                    </ContainerIcons>
                  </ListItemCustom>
                );
              })}
              {filteredData.length === 0 && <Text align="center" weight="200" left="auto" right="auto" width="90%">Ninguna empresa encontrada...</Text>}
            </List>
            {/* {!completeData && (
              <MoreBtn
                onClick={() => {
                  nextData();
                }}
              >
                Cargar más...
              </MoreBtn>
            )} */}
          </BoxCustom>
          <FloatButton onClick={() => navigation("/new-business")} />
        </ItemsContainer>
        <ModalAccept
          text={`¿Seguro que quieres eliminar ${selectedDoc?.name}?`}
          close={closeModalConfirm}
          clearFunct={() => setSelectedDoc(selectedDoc)}
          open={modalConfirm}
          resetFunct={() => {
            deleteDoc(selectedDoc);
          }}
        />
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default BusinessPanel;
