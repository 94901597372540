import styled from "@emotion/styled";
import { Text } from "../../genericComponents";

const ContainerButton = styled.div`
  width: 59px;
  height: 59px;
  background: #5B656E;
  border: 1px solid #f6f6f6;
  box-shadow: 1px 2px 12px #00000039;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 25px;
  bottom: 100px;
  float: 0;
  z-index:15;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const FloatButton = ({ onClick }) => {
  return (
    <ContainerButton
      onClick={() => {
        onClick();
      }}
    >
      <Text top="0" size="85px" weight="100">
        +
      </Text>
    </ContainerButton>
  );
};

export default FloatButton;
