import styled from "@emotion/styled";
import { Text, TextArea } from "../../genericComponents";

type ITextArea = {
  title: string;
  name: string;
  subtitle?: string;
  value?: string;
  pattern?: string;
  width?: string;
  legendColor?: string;
  legendSubtitleColor?: string;
  titlePattern?: string;
  icon?: any;
  onChange?: (value: any) => void;
  onClickSymbol?: () => void;
};
type IContainerTextArea = {
  width?: string;
};
const ContainerDiv = styled.div<IContainerTextArea>`
  width: ${(props) => props.width};
  padding: 15px;
`;
export const SpanRelative = styled.div`
  position: relative;
`;

const TextAreaWithLegend = ({
  title,
  name,
  subtitle,
  onChange,
  titlePattern,
  legendColor,
  legendSubtitleColor,
  width,
  value,
  icon,
}: ITextArea) => {
  return (
    <ContainerDiv width={width}>
      <Text color={legendColor} size="14px" weight="500" top="10px" bottom="5px">
        {title}
      </Text>

      {subtitle && (
        <Text color={legendSubtitleColor} weight="300" size="14px" top="0px" bottom="5px">
          {subtitle}
        </Text>
      )}
      <SpanRelative>
        <TextArea
          title={titlePattern}
          onChange={onChange}
          name={name}
          value={value}
        />
        {icon}
      </SpanRelative>
    </ContainerDiv>
  );
};

export default TextAreaWithLegend;
