const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.918"
      height="25.918"
      viewBox="0 0 25.918 25.918"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectángulo_15"
            data-name="Rectángulo 15"
            width="8.934"
            height="11.772"
            fill="#f6f6f6"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_553"
        data-name="Grupo 553"
        transform="translate(-298.832 -439.082)"
      >
        <ellipse
          id="Elipse_109"
          data-name="Elipse 109"
          cx="12.959"
          cy="12.959"
          rx="12.959"
          ry="12.959"
          transform="translate(298.832 439.082)"
          fill="#bf2a2a"
        />
        <g
          id="Grupo_549"
          data-name="Grupo 549"
          transform="translate(307.324 446.154)"
        >
          <g
            id="Grupo_5"
            data-name="Grupo 5"
            transform="translate(0 0)"
            clipPath="url(#clipPath)"
          >
            <path
              id="Trazado_3"
              data-name="Trazado 3"
              d="M18.95,74.276a1.477,1.477,0,0,1-.6-.278,1.188,1.188,0,0,1-.412-.83c0-.046,0-.092,0-.138q0-3.964,0-7.928V64.97h7.583v.12q0,3.976,0,7.951a1.192,1.192,0,0,1-.982,1.219.1.1,0,0,0-.029.017Zm2.4-4.68q0,1.528,0,3.055a.928.928,0,0,0,.016.194.386.386,0,0,0,.474.287.4.4,0,0,0,.3-.445q0-3.1,0-6.191a.731.731,0,0,0-.013-.137.39.39,0,0,0-.768.009,1.038,1.038,0,0,0-.011.171q0,1.528,0,3.055m2.987-.009q0-1.522,0-3.044c0-.05,0-.1,0-.149a.386.386,0,0,0-.364-.35.392.392,0,0,0-.418.426q0,3.124,0,6.248a.644.644,0,0,0,0,.08.4.4,0,0,0,.36.346.39.39,0,0,0,.406-.286.777.777,0,0,0,.016-.193q0-1.539,0-3.078m-5.18,0q0,1.533,0,3.067a1.416,1.416,0,0,0,0,.149.393.393,0,0,0,.782-.068q0-3.141,0-6.282a.507.507,0,0,0,0-.057.393.393,0,0,0-.783.08q0,1.556,0,3.112"
              transform="translate(-17.259 -62.504)"
              fill="#f6f6f6"
            />
            <path
              id="Trazado_4"
              data-name="Trazado 4"
              d="M8.934,2.036H0a3.877,3.877,0,0,1,.07-.392A1.41,1.41,0,0,1,1.4.626c.5-.007,1,0,1.494,0h.13C3.041.562,3.054.5,3.077.447A.643.643,0,0,1,3.671.01C4.207,0,4.744,0,5.28.01a.66.66,0,0,1,.613.519c.017.078.047.1.121.1q.73,0,1.46,0a1.417,1.417,0,0,1,1.45,1.238,1.7,1.7,0,0,1,.01.175"
              transform="translate(0 0)"
              fill="#f6f6f6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default TrashIcon;
