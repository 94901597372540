import styled from "@emotion/styled";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { useContext } from "react";
import { FirebaseContext } from "../../../context/FirebaseContext";
import ParcelAdmin from "../ParcelAdmin";
import { ParcelContext } from "../../../context/ParcelContext";
import { CSVLink } from "react-csv";
import { filterColumns } from "../../../utils/functions";
import { Text } from "../../../genericComponents";
import moment from "moment";

const ParcelContainer = styled.div`
  padding-bottom: 150px;
  background-color: #f6f6f6;
  height: 100%;
`;
const ExportsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  input {
    width: 60%;
  }
  margin-top: auto;
`;
const CSVLinkCustom = styled(CSVLink)`
  text-decoration: none;
  color: black;
  border: 1px solid black;
  width: 65%;
  padding:10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover{
    opacity:0.5;
  }
  p {
    text-align: center;
  }
`;

const AdminParcel = () => {
  const { user } = useContext(FirebaseContext);
  const { packagesToDownload } = useContext(ParcelContext);

  const today = new Date().getTime();
  const todayName = moment(today).format("DD/MM/YYYY");

  return (
    <Layout>
      <ParcelContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fcabecera_paqueteria.jpg?alt=media&token=54512f69-52cb-4459-bf6c-e0821e21c74e"
          title="PAQUETERÍA"
          subtitle="Tus pedidos están seguros en nuestras manos. Aquí tienes los paquetes pendientes de recoger en recepción."
          showBackButton
        />
        {user?.roles.parcel && <ParcelAdmin />}
        <ExportsContainer>
          {packagesToDownload.length > 0 && (
            <CSVLinkCustom
              data={packagesToDownload}
              headers={filterColumns(packagesToDownload)}
              filename={`listado-paquetes-${todayName}.csv`}
            >
              <Text>Descargar listado diario</Text>
            </CSVLinkCustom>
          )}
        </ExportsContainer>
      </ParcelContainer>
    </Layout>
  );
};

export default AdminParcel;
