import { useContext } from "react";

import TicketsDisplayer from "../../../components/ticketsDisplayer";

import { MaintenanceContext } from "../../../context/MaintenanceContext";
import styled from "@emotion/styled";
import { Text } from "../../../genericComponents";
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 150px;
`;

const TicketsWorker = () => {
  const { tickets } = useContext(MaintenanceContext);
  return (
    <Column>
      <TicketsDisplayer tickets={tickets?.pending} />

      <TicketsDisplayer tickets={tickets?.progress} />

      <TicketsDisplayer tickets={tickets?.closed} />
      {!tickets?.closed.length && !tickets?.progress.length && !tickets?.pending.length && (
        <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
          No hay solicitudes pendientes...
        </Text>
      )}
    </Column>
  );
};

export default TicketsWorker;
