const HomeIcon = ({fill} :{fill?:string}) => {
  return (
    <svg
      id="home_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="28.588"
      height="28.588"
      viewBox="0 0 28.588 28.588"
      fill={fill && fill}
    >
      <path
        id="Trazado_1"
        data-name="Trazado 1"
        d="M0,0H28.588V28.588H0Z"
        fill="none"
      />
      <path
        id="Trazado_2"
        data-name="Trazado 2"
        d="M13.912,3,2,13.72H5.573V23.25H12.72V16.1H15.1V23.25H22.25V13.72h3.573Z"
        transform="translate(0.382 0.573)"
        fill={fill && fill}
      />
    </svg>
  );
};

export default HomeIcon;
