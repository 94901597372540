import styled from "@emotion/styled";
import { Text } from "../../genericComponents";
type IContainerInput = {
  width?: string;
  disabled?: boolean;
};
const Select = styled.select`
  /* margin-top: 20px; */
  min-width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ContainerDiv = styled.div<IContainerInput>`
  width: 100%;
  padding: 15px;
  opacity: ${(props) => props.disabled && 0.5};
`;
export const SpanRelative = styled.div`
  position: relative;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 10px;
  }
`;

const SelectCategory = ({ options, handleChange, value, legend }: { options: { id: string; nameToShow: string }[]; legend: string; handleChange: any; value: string }) => {
  return (
    <ContainerDiv>
      <ContainerTitle>
        <Text color="#5F5F5F" size="14px" top="10px" bottom="10px">
          {legend}
        </Text>
      </ContainerTitle>
      <Select  id="select" name="category" value={value} onChange={(e) => handleChange(e)}>
      <option  disabled id="select"  value='unselected'>
              Selecciona una categoría...
            </option>
        {options.map((option, idx) => {
          return (
            <option  id="select" key={`${option.id}-${idx}`} value={option.id}>
              {option.nameToShow}
            </option>
          );
        })}
      </Select>
    </ContainerDiv>
  );
};

export default SelectCategory;
