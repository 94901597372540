import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Layout from "../../../../components/Layout";
import SectionHeader from "../../../../components/SectionHeader";
import { BusinessContext } from "../../../../context/BusinessContext";
import { Text } from "../../../../genericComponents";
import { IBusiness } from "../../../../interfaces/IBusiness";
import { ImageCircle } from "../../../Users/EditUser";

const Container = styled.div`
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f6f6f6;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #000;
`;
const LocalsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
`;
const LocalsRow = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  p {
    margin: 0 5px;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
`;
export const Card = styled.form`
  width: 250px;
  min-height: 320px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 20px;
  z-index: 10;
`;
export const Image = styled.div<{ imagePreview: string }>`
  height: 169px;
  width: 169px;
  background: ${(props) => (props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;

const BusinessCard = () => {
  const location: any = useLocation();
  const { getBusinessById, categories } = useContext(BusinessContext);

  const [filteredBusiness, setFilteredBusiness] = useState<IBusiness>(null);

  const { id } = location?.state;

  const getInitialData = async () => {
    const filtered = await getBusinessById(id);
    setFilteredBusiness(filtered);
  };

  useEffect(() => {
    id && getInitialData();
  }, [id]);
  const getCategory = (category: string) => {
    return categories.find((doc) => doc.id === category);
 
 };
  return (
    <Layout>
      <Container>
        <SectionHeader title={filteredBusiness?.name} showBackButton />
        <Card>
          <InfoContainer>
            <Image
              imagePreview={
                filteredBusiness?.logo
                  ? filteredBusiness.logo
                  : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/building.jpg?alt=media&token=0ba753d6-2990-4b56-87f2-a5498d8aaa93"
              }
              onClick={() => document.getElementById("image").click()}
            />
            <Text top="10px">{filteredBusiness?.name}</Text>

            {filteredBusiness?.email ? <Text top="10px">{filteredBusiness?.email}</Text> : <Text top="10px">No hay email todavía...</Text>}
            {filteredBusiness?.phone ? <Text top="10px">{filteredBusiness?.phone}</Text> : <Text top="10px">No hay teléfono todavía...</Text>}
            {filteredBusiness?.web ? <Text top="10px">{filteredBusiness?.web}</Text> : <Text top="10px">No hay web todavía...</Text>}
          </InfoContainer>
          <DescriptionContainer>
            {filteredBusiness?.category && <Text top="10px">{getCategory(filteredBusiness?.category)?.nameToShow}</Text>}
            {filteredBusiness?.description ? <Text top="10px">{filteredBusiness?.description}</Text> : <Text top="10px">No hay descripción todavía...</Text>}
          </DescriptionContainer>
          <LocalsContainer>
            <Text top="10px">LOCALES</Text>
            <LocalsRow>
              {filteredBusiness?.locals?.length > 0 &&
                filteredBusiness?.locals?.map((local) => {
                  return <Text>{local}</Text>;
                })}
            </LocalsRow>
          </LocalsContainer>
        </Card>
      </Container>
    </Layout>
  );
};

export default BusinessCard;
