import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InputButtonWithProps from "../../../../components/InputButton";
import InputWithLegend from "../../../../components/InputWithLegend";
import Layout from "../../../../components/Layout";
import ModalCusstom from "../../../../components/ModalCustom";
import SelectBusiness from "../../../../components/SelectBusiness";
import { BusinessContext } from "../../../../context/BusinessContext";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { ContainerSelect, Text, Title } from "../../../../genericComponents";
import { IBusiness } from "../../../../interfaces/IBusiness";
import { IUser } from "../../../../interfaces/IUser";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import InputCheckbox from "../../../../components/InputCheckbox";
import { Link } from "react-router-dom";
import SectionFooter from "../../../../components/SectionFooter";
import { normalizeString } from "../../../../utils/functions";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

const Container = styled.div`
  width: 100%;
  /* height: 100%; */
`;
const CardContainer = styled.div`
  width: 100%;
  background: #f6f6f6;
  /* height: 100%; */
  padding-top: 20px;
  padding-bottom: 70px;
`;
const TitleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 82px;
  width: 82px;
  background: ${(props) => (props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const AuthCardSignUp = styled.form`
  width: 250px;
  min-height: 320px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  z-index: 10;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const ContainerSpinner = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const EditProfile = () => {
  const { user, loadingUser, updateUser, uploadImage } = useContext(FirebaseContext);
  const { getBusinessById } = useContext(BusinessContext);
  const navigation = useNavigate();
  const [userData, setUserData] = useState<IUser>({
    name: "",
    nameToFilter:'',
    email: "",
    image: "",
    id: "",
    businessId: "",
    local: "",
    acceptLegal: false,
    roles: {
      super: false,
      canteen: false,
      maintenance: false,
      gym: false,
      parcel: false,
    },
  });
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [anyChange, setAnyChange] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [userBusiness, setUserBusiness] = useState<IBusiness>(null);
  const [locals, setLocals] = useState([]);

  const handleChange = (e: any) => {
    setAnyChange(true);
    setUserData({ ...userData, [e.target.name]: e.target.value });
    e.preventDefault();
    switch (e.target.name) {
      case "image":
        setImage(e.target.files[0]);

        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
        case "name":

          setUserData({ ...userData, [e.target.name]: e.target.value, nameToFilter: normalizeString(e.target.value) });
        
          break;

      default:
        setUserData({ ...userData, [e.target.name]: e.target.value });
        break;
    }
  };

  const sendRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage("Actualizando datos...");
    setModalStatus(true);
    if (image) {
      const url = await uploadImage(image);

      await updateUser({ ...userData, image: url as string, nameToFilter: normalizeString(userData.name) });
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        setAnyChange(false);
      }, 3000);
    } else {
      await updateUser({ ...userData, nameToFilter: normalizeString(userData.name) });
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        setAnyChange(false);
      }, 3000);
    }
  };
  const handleChangeLocal = async (e: any) => {
    setUserData({
      ...userData,

      local: e.value,
    });
    setAnyChange(true);
  };

  
  const getInitialData = async () => {
    const copyData = JSON.parse(JSON.stringify(user));

    const businessData = await getBusinessById(user.businessId);
    const localsData = businessData?.locals?.map((local) => {
      return { value: local, label: local };
    });
    setUserData(copyData);
    setUserBusiness(businessData);
    setLocals(localsData);
    setLoadingData(false);
  };

  useEffect(() => {
    !loadingUser && user && getInitialData();
  }, [user]);

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Text top="0" weight="100" size="24px" onClick={() => navigation(-1)}>
            ←
          </Text>
          <Title size="25px" weight="200" top="10px">
            Modificar mi perfil
          </Title>
        </TitleContainer>
        <CardContainer>
          <AuthCardSignUp autoComplete="off" onSubmit={(e: React.FormEvent<HTMLFormElement>) => sendRequest(e)}>
            <Title align="left" size="14px" weight="600" width="100%">
              Datos personales
            </Title>
            <Text width="100%" color="#5F5F5F" size="14px" top="10px" bottom="5px">
              Foto de perfil
            </Text>
            <ImageCircle imagePreview={preview ? preview : user?.image && user.image} onClick={() => document.getElementById("image").click()}>
              {!image || (!user?.image && <Image src="/upload.png" />)}
            </ImageCircle>
            <Invisible>
              <InputInvisible name="image" type="file" id="image" onChange={handleChange} title="Imagen de perfil" />
            </Invisible>
            <InputWithLegend legendColor="#5F5F5F" type="text" name="name" title="Nombre" width="100%" value={userData?.name} onChange={handleChange} />

            <Title top="20px" align="left" size="14px" weight="600" width="100%">
              Datos laborales
            </Title>
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="email" disabled name="email" title="E-mail laboral" value={userData?.email} onChange={handleChange} />
            <InputCheckbox
              text="Responsable de la empresa"
              id="responsable"
              name="responsable"
              width="100%"
              disabled
              onChange={() => {
                const copy: IUser = JSON.parse(JSON.stringify(userData));
                setAnyChange(true);
                setUserData({ ...userData, responsable: !copy.responsable });
              }}
              checked={userData.responsable}
            />
            {!loadingData ? (
              <>
                <InputWithLegend width="100%" legendColor="#5F5F5F" type="email" name="email" title="Empresa" value={userBusiness?.name} disabled />
                <ContainerSelect>
                  <Text color="#5F5F5F" size="14px" top="10px" bottom="5px">
                    Local*
                  </Text>
                  <SelectBusiness disabled={!user.responsable} options={locals} handleChange={handleChangeLocal} name="local" value={userData?.local} />
                </ContainerSelect>
              </>
            ) : (
              <ContainerSpinner>
                <ClipLoader color="#000" loading={true} css={override} size={30} />
              </ContainerSpinner>
            )}
            <InputCheckbox
              text="Acepto términos y condiciones"
              id="terms-conditions"
              name="terms-conditions"
              width="100%"
              onChange={() => {
                setAcceptTerms(!acceptTerms);
              }}
              checked={acceptTerms}
            />
            <Text width="100%" color="#5F5F5F" size="14px" top="10px" bottom="5px">
              Puedes obtener información adicional en nuestra <Link to="/info-legal">información legal</Link>
            </Text>
            <InputButtonWithProps type="submit" disabled={!anyChange || !acceptTerms} top="20px" text="Modificar datos" />
          </AuthCardSignUp>
          <SectionFooter src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/my-profile%2Fcabecera_miPerfil.jpg?alt=media&token=fc869a49-055f-44f3-9f01-be8a1f11a509" />
        </CardContainer>
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default EditProfile;
