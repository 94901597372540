import { useContext, useEffect, useState } from "react";
import Router from "./components/Router";

import { useCookies } from "react-cookie";

import CookiesModal from "./components/ModalCookies";


function App() {
  const [cookies] = useCookies(["POPUP_COOKIES"]);
  const [showPopup, setShowPopup] = useState(false);
  const [editCookies, setEditCookies] = useState(false);




  useEffect(() => {
    if (!cookies["HIDE_POPUP_COOKIES"]) {
      setShowPopup(true);
    }
  }, []);

  return (
    <div>
      <Router />
      {(editCookies || showPopup) && <CookiesModal setEditCookies={setEditCookies} setShowPopup={setShowPopup} />}
    </div>
  );
}
export default App;
