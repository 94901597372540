import styled from "@emotion/styled";
import { useState } from "react";
import { Text } from "../../genericComponents";
import InputButtonWithProps from "../InputButton";
import TextAreaWithLegend from "../TextAreaWithLegend";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
`;

const SendParcelSectionClients = ({ clearFunct, data, sendFunct }) => {
  const [comments, setComments] = useState('')

  return (
    <Container>
      <Row>
        <Text
          pointer
          onClick={() => clearFunct()}
          color="#894141"
          weight="bolder"
          align="center"
          width="10%"
        >
          X
        </Text>
        <Text weight="500" align="center" width="90%">
          {data.name}
        </Text>
      </Row>
      <Text top="20px" weight="200">
        Un cliente ha dejado un paquete.
      </Text>

      <TextAreaWithLegend
        title="Empresa de transporte*"
        legendColor="#5F5F5F"
        legendSubtitleColor="#5F5F5F"
        name="comments"
        value={comments}
        width='100%'
        onChange={(e) => setComments(e.target.value)}
      />

      <InputButtonWithProps
        type="button"
        onClick={() => {
          sendFunct(comments)
          setComments('');
        }}
        top="20px"
        disabled={!comments}
        text="Enviar mensaje"
      />
    </Container>
  );
};

export default SendParcelSectionClients;
