const Networking = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.164"
      height="35.688"
      viewBox="0 0 55.164 35.688"
    >
      <path
        id="networking"
        d="M27.237,25.9a13.317,13.317,0,0,0,2.212-3.793,13.129,13.129,0,0,0,.718-4.54,13.129,13.129,0,0,0-.718-4.54,13.317,13.317,0,0,0-2.212-3.793,9.307,9.307,0,0,1,7.671,1.322q3.3,2.3,3.3,7.01t-3.3,7.01A9.307,9.307,0,0,1,27.237,25.9ZM39.649,44.635v-5.4a10.545,10.545,0,0,0-1.494-5.459,11.33,11.33,0,0,0-5.172-4.252q9.941,1.264,13.59,3.678t3.649,6.034v5.4ZM45.97,28.028V22.282H40.224V18.834H45.97V13.088h3.448v5.746h5.746v3.448H49.418v5.746ZM18.1,26.189A8.267,8.267,0,0,1,9.481,17.57,8.267,8.267,0,0,1,18.1,8.951,8.267,8.267,0,0,1,26.72,17.57,8.267,8.267,0,0,1,18.1,26.189ZM0,44.635v-5.4a6.624,6.624,0,0,1,3.907-6.091A45.479,45.479,0,0,1,11.291,30.5a28.216,28.216,0,0,1,6.809-.8,27.865,27.865,0,0,1,6.781.8,45.912,45.912,0,0,1,7.355,2.643,6.709,6.709,0,0,1,2.873,2.442A6.434,6.434,0,0,1,36.2,39.233v5.4ZM18.1,22.742a5.012,5.012,0,0,0,5.172-5.172A5.012,5.012,0,0,0,18.1,12.4a5.012,5.012,0,0,0-5.172,5.172A5.012,5.012,0,0,0,18.1,22.742ZM3.448,41.187H32.754V39.233a3.418,3.418,0,0,0-.46-1.724,3.119,3.119,0,0,0-1.437-1.264,35.483,35.483,0,0,0-6.723-2.471,27.01,27.01,0,0,0-6.034-.632,26.626,26.626,0,0,0-6,.632,38.5,38.5,0,0,0-6.809,2.471,3.009,3.009,0,0,0-1.35,1.235,3.414,3.414,0,0,0-.488,1.753ZM18.1,17.57Zm0,23.617Z"
        transform="translate(0 -8.947)"
        fill="#f6f6f6"
      />
    </svg>
  );
};

export default Networking;
