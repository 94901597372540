import { createContext, useContext, useEffect, useState } from "react";
import {
  getFirestore,
  Firestore,
  doc,
  collection,
  addDoc,
  getDoc,
  DocumentData,
  query,
  onSnapshot,
  updateDoc,
  deleteDoc,
  orderBy,
  where,
  getDocs,
  limit,
  startAfter,
  endAt,
  startAt,
  QuerySnapshot,
} from "firebase/firestore";
import { onBackgroundMessage } from "firebase/messaging/sw";

import { firebaseConfig } from "../fb/config";
import { initializeApp } from "firebase/app";
import { getToken, Messaging, onMessage, getMessaging } from "firebase/messaging";
import { useCookies } from "react-cookie";

import { EmailAuthProvider, onAuthStateChanged, reauthenticateWithCredential, updateEmail, updatePassword, UserCredential } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, list } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import { IUser } from "../interfaces/IUser";
import axios from "axios";
import { IUserNotis } from "../interfaces/IUserNotis";
import { stringify } from "querystring";
import { normalizeString, requestNotisPermission, sliceIntoChunks } from "../utils/functions";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";

export interface IFirebaseContextProps {
  user: IUser;
  permissions: boolean;
  messaging: Messaging;
  allUsers: IUser[];
  deleteNotifications: (notisToUpdate: any) => void;
  getUserData: (id: string) => Promise<IUser>;
  loadingUser: boolean;
  updateUser: (data: IUser) => Promise<void>;
  uploadImage: (file: any) => Promise<string>;
  signup: (name: string, email: string, password: string, businessId: string, local: string) => Promise<UserCredential>;
  signout: () => Promise<void>;
  updateEmailAuth: (email: string) => Promise<void>;
  login: (email: string, password: string) => Promise<UserCredential>;
  db: Firestore;
  progresspercent: number;
  sendingPasswordResetMail: (value: string) => Promise<string>;
  updateActualPassword: (password: string, actualPassword: string, email: string) => Promise<void>;
  setNotification: React.Dispatch<any>;
  notification: {
    title: string;
    body: string;
  };
  tokenMessaging: string;
  userNotis: IUserNotis;
  updateNotifications: (notisToUpdate: any) => void;
  getUsersByBusinessId: (id: string) => Promise<IUser[]>;
  getFirebaseAllUsers: () => Promise<void>;
  getFirebaseNextUsers: (id: any) => Promise<void>;
  getAllUsersByName: (nameInit: string) => Promise<void>;
  addNotiEventBmc: (event: any, type: any, developer?: boolean) => Promise<void>;
  updateAllUsers: any;
}

export const FirebaseContext = createContext<IFirebaseContextProps>(null);

export const FirebaseProvider: any = ({ children }: any) => {
  // const { user, loadingUser } = useAuth();
  const [cookies, setCookie] = useCookies<any>(["USERS"]);
  const [user, setUser] = useState<IUser>();
  const [loadingUser, setLoadingUser] = useState(true);
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [permissions, setPermissions] = useState(false);
  const [tokenMessaging, setTokenMessaging] = useState(null);
  const [userNotis, setUserNotis] = useState<IUserNotis>(null);
  const [notification, setNotification] = useState<{
    title: string;
    body: string;
  }>(null);

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging();

  const db = getFirestore(app);
  const storage = getStorage(app);

  const auth = getAuth();
  const functions = getFunctions(app);
  const sendMail = httpsCallable(functions, "sendMailEventBMC");

  const [progresspercent, setProgresspercent] = useState(0);

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, (userAuth: any) => {
      let userDB = null;
      try {
        if (userAuth) {
          localStorage.setItem("token", userAuth?.accessToken);
          const q = query(collection(db, "users"), where("uid", "==", userAuth.uid));
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let userData = doc.data();
              userDB = {
                id: doc.id,
                uid: auth.currentUser.uid,
                businessId: userData.businessId,
                local: userData.local,
                name: userData.name,
                email: userData.email,
                image: userData.image,
                firstTime: userData.firstTime,
                responsable: userData.responsable,
                acceptLegal: userData.acceptLegal,
                roles: {
                  canteen: userData.roles.canteen,
                  maintenance: userData.roles.maintenance,
                  parcel: userData.roles.parcel,
                  gym: userData.roles.gym,
                  super: userData.roles.super,
                  cleaner: userData.roles?.cleaner ? userData.roles?.cleaner : false,
                },
              };

              setUser(userDB);
              setLoadingUser(false);
            });
          });
        } else {
          setUser(null);
          setLoadingUser(false);
        }
      } catch (error) {
        console.log(error);
      }

      // setLoadingUser(false);
    });
    return () => unsuscribe();
  }, []);

  useEffect(() => {
    if (user?.roles?.super || user?.roles?.parcel) {
      getFirebaseAllUsers();
    }
  }, [user]);
  // const getAllUsers = async () => {
  //   try {
  //     let listUsers = localStorage.getItem("USERS");
  //     let date = new Date();
  //     date.setDate(new Date().getDate() + 1);

  //     if (!listUsers) {
  //       await updateListAllUsers();
  //     } else {
  //       let now = new Date().getTime();
  //       let decryptListUsers = JSON.parse(listUsers);
  //       if (decryptListUsers.expiresDate < now) {
  //         await updateListAllUsers();
  //       } else {
  //         setAllUsers(decryptListUsers.listUsers);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateListAllUsers = async () => {
  //   const responseUsers: IUser[] = await getFirebaseAllUsers();
  //   let date = new Date();
  //   date.setDate(new Date().getDate() + 1);

  //   let listUsersLocalStorage = JSON.stringify({
  //     expiresDate: date.getTime(),
  //     listUsers: responseUsers,
  //   });
  //   localStorage.setItem("USERS", listUsersLocalStorage);
  //   setAllUsers(responseUsers);
  // };

  const getFirebaseAllUsers = async () => {
    const first = query(collection(db, "users"), orderBy("nameToFilter", "asc"), limit(25));
    onSnapshot(first, async (docSnap) => {
      if (docSnap.docs.length > 0) {
        const users = await Promise.all(
          docSnap.docs.map((doc: any) => {
            return { id: doc.id, ...doc.data() };
          })
        );

        setAllUsers(users);
      } else {
        setAllUsers([]);
      }
    });
  };
  const getFirebaseAllUsers2 = async () => {
    const q = query(collection(db, "users"));
    const usersDocs = await getDocs(q);

    const users: IUser[] = await Promise.all(
      usersDocs.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      })
    );

    return users;
  };

  const deleteBMCNotis = async () => {
    const users = await getFirebaseAllUsers2();
    const sliced50 = sliceIntoChunks(users, 250);
    sliced50.map(async (usersToUpdate) => {
      await Promise.all(
        usersToUpdate.map(async (userToUpdateNoti: IUser) => {
          const q = query(collection(doc(collection(db, "users"), userToUpdateNoti.id), "notifications"), where("type", "==", "events-business"));
          const notisToDelete = await getDocs(q);
          const notisToDeleteFinal = await Promise.all(
            notisToDelete.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          );
          await deleteNotificationsToUser(notisToDeleteFinal, userToUpdateNoti);
        })
      );
    });
  };
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const addNotiEventBmc = async (event, type, developer) => {
    if (!developer) {
      const users = await getFirebaseAllUsers2();
      const sliced50 = sliceIntoChunks(users, 50);
      for (const usersToUpdate of sliced50) {
        await Promise.all(
          usersToUpdate.map(async (userToUpdateNoti) => {
            const q = collection(doc(collection(db, "users"), userToUpdateNoti.id), "notifications");
            await addDoc(q, {
              ...event,
              type: type,
              viewed: false,
              info: `Nuevo evento de BMC: ${event.title}`,
              createdAtt: new Date().getTime(),
            });
            await sendMail({
              email: userToUpdateNoti?.email,
              userName: userToUpdateNoti?.name,
              event,
              date: `${moment(event?.initTimestamp).format("DD/MM/YYYY")}–${moment(event?.finishTimestamp).format("DD/MM/YYYY")}`,
            });
          })
        );
        // Esperar 1 segundo después de enviar cada lote de 50 correos
        await delay(1000);
      }
    }
  };
  

  // useEffect(() => {
  //   deleteBMCNotis();
  // }, [db]);

  const getAllUsersByName = async (nameInit: string) => {
    const next = query(collection(db, "users"), orderBy("nameToFilter", "asc"), startAfter(nameInit), endAt(nameInit + "\uf8ff"), limit(25));

    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const filtered = await Promise.all(
        docSnap.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        })
      );

      setAllUsers(filtered);
    } else {
      setAllUsers([]);
    }
  };

  const getFirebaseNextUsers = async (id) => {
    const next = query(collection(db, "users"), orderBy("nameToFilter", "asc"), startAfter(id), limit(25));
    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const copy = allUsers.slice();
      await docSnap.forEach((doc: any) => {
        copy.push({ id: doc.id, ...doc.data() });
      });

      setAllUsers(copy);
    } else {
      setAllUsers([]);
    }
  };
  const updateAllUsers = async () => {
    const next = query(collection(db, "users"));
    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      return await Promise.all(
        docSnap.docs.map(async (doc, idx) => {
          const nameFormatted = normalizeString(doc.data().name);
          const data = { ...doc.data(), id: doc.id, nameToFilter: nameFormatted };
          await updateUser(data);

          return data;
        })
      );
    } else {
      console.log("Users not found");
    }
  };

  // const requestServiceWorkerPermissions = () => {
  //   navigator.serviceWorker
  //     .register("../firebase-messaging-sw.js")
  //     .then(function (registration) {
  //       console.log(registration);
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === "granted") {
  //           getInitTokens();
  //         } else {
  //           requestNotisPermission();
  //         }
  //       });
  //     })
  //     .catch(function (err) {
  //       console.log("Service worker registration failed, error:", err);
  //     });
  // };

  // const getInitTokens = async () => {
  //   getToken(messaging, { vapidKey: "BHLSGWWoKikPtNjSZeZSPul_R5iBczOoVTx9Bu3YHOHrLU_iM5A6gKV_QPpvxk93eRaxgHUGjefpBQZ2ZcoLmSQ" })
  //     .then(async (currentToken) => {
  //       if (currentToken) {
  //         setTokenMessaging(currentToken);
  //         // const tokens = [];
  //         // tokens.push(currentToken);
  //         // await axios.post("https://us-central1-bmc-app-87a78.cloudfunctions.net/subscribeToTopics", { tokens: tokens });
  //       } else {
  //         // Show permission request UI
  //         console.log("No registration token available. Request permission to generate one.");
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("An error occurred while retrieving token. ", err);
  //       // ...
  //     });
  // };
  // onMessage(messaging, (payload) => {
  //   console.log("Message received. ", payload);
  //   return payload;
  // });

  const uploadImage = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `gym-events/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // setProgresspercent(progress); // Asegúrate de que esta función esté definida y acepte un número
          console.log(`Upload is ${snapshot.state}, ${progress}% done`);
        },
        (error) => {
          console.error(error);
          reject(error); // Podrías considerar pasar el error a reject para manejarlo mejor
        },
        async () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          }).catch((error) => reject(error));
        }
      );
    });
  };
  const checkRoles = () => {
    if (user?.roles?.super || user?.roles?.canteen || user?.roles?.gym || user?.roles?.parcel || user?.roles?.maintenance) {
      setPermissions(true);
    } else setPermissions(false);
  };

  const updateUser = async (data: IUser) => {
    try {
      await updateDoc(doc(db, "users", data.id), { ...data });
    } catch (error) {
      console.log(error);
    }
  };

  const signup = async (name: string, email: string, password: string, businessId: string, local: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const userData = {
      uid: user.uid,
      name: name,
      nameToFilter: normalizeString(name),
      email: user.email,
      businessId: businessId,
      local: local,
      roles: { canteen: false, maintenance: false, gym: false, parcel: false },
    };

    const usersRef = collection(db, "users");
    await addDoc(usersRef, userData);

    return userCredential;
  };
  const getUserData = async (id: string) => {
    const docRef = doc(db, "users", id);
    const docSnap: any = await getDoc(docRef);

    const user: IUser = { id: docSnap.id, ...docSnap.data() };
    return user;
  };

  const getUsersByBusinessId = async (id: string) => {
    try {
      const q = query(collection(db, "users"), where("businessId", "==", id));
      const docSnap: any = await getDocs(q);
      if (docSnap.docs.length > 0) {
        const businessUsers: IUser[] = await Promise.all(
          docSnap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
        return businessUsers;
      } else {
        console.log("No such document!");
      }
    } catch (error) {}
  };

  const login = async (email: string, password: string) => {
    try {
      const loging = await signInWithEmailAndPassword(auth, email, password);

      return loging;
    } catch (error) {
      return null;
    }
  };

  const sendingPasswordResetMail = async (email: string) => {
    return await sendPasswordResetEmail(auth, email)
      .then(() => {
        return "Solicitud enviada correctamente";
      })
      .catch((error) => {
        return "Error enviando la solicitud";
      });
  };

  const updateEmailAuth = async (email: string) => {
    const authUser = getAuth();
    const currentUserAuth = authUser.currentUser;
    return await updateEmail(currentUserAuth, email);
  };

  const updateActualPassword = async (password: string, actualPassword: string, email: string) => {
    const authUser = getAuth();
    const currentUser = authUser.currentUser;
    const authCred = EmailAuthProvider.credential(email, actualPassword);
    await reauthenticateWithCredential(currentUser, authCred);
    await updatePassword(currentUser, password);
    user.firstTime &&
      (await updateDoc(doc(db, "users", user.id), {
        firstTime: false,
      }));
  };
  const getUserNotifications = () => {
    if (user?.id) {
      const q = query(collection(doc(collection(db, "users"), user.id), "notifications"), orderBy("createdAtt", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: any = [];
        querySnapshot.forEach(async (doc: any) => {
          const data = { ...doc.data(), id: doc.id };
          arr.push(data);
        });
        const notisCanteen = arr.filter((doc) => doc.type === "canteen");
        const notisEvents = arr.filter((doc) => doc.type === "events-business");
        const notisEventsBmc = arr.filter((doc) => doc.type === "events-bmc");
        const notisPackages = arr.filter((doc) => doc.type === "packages");
        const notisMaintenance = arr.filter((doc) => doc.type === "tickets");
        const notisCarsharing = arr.filter((doc) => doc.type === "carsharing");
        const notisSala = arr.filter((doc) => doc.type === "sala");
        const notisActivitySharing = arr.filter((doc) => doc.type === "activitysharing");

        const notisCarsharingUnviewed = notisCarsharing.filter((doc) => doc.viewed === false);
        const notisCarsharingViewed = notisCarsharing.filter((doc) => doc.viewed === true);
        const notisSalaUnviewed = notisSala.filter((doc) => doc.viewed === false);
        const notisSalaViewed = notisSala.filter((doc) => doc.viewed === true);
        const notisActivitySharingUnviewed = notisActivitySharing.filter((doc) => doc.viewed === false);
        const notisActivitySharingViewed = notisActivitySharing.filter((doc) => doc.viewed === true);
        const notisMaintenanceUnviewed = notisMaintenance.filter((doc) => doc.viewed === false);
        const notisMaintenanceViewed = notisMaintenance.filter((doc) => doc.viewed === true);
        const notisPackagesUnviewed = notisPackages.filter((doc) => doc.viewed === false);
        const notisPackagesViewed = notisPackages.filter((doc) => doc.viewed === true);

        const notisCanteenUnviewed = notisCanteen.filter((doc) => doc.viewed === false);
        const notisCanteenViewed = notisCanteen.filter((doc) => doc.viewed === true);

        const notisEventsUnviewed = notisEvents.filter((doc) => doc.viewed === false);
        const notisEventsViewed = notisEvents.filter((doc) => doc.viewed === true);

        const notisEventsBmcUnviewed = notisEventsBmc.filter((doc) => doc.viewed === false);
        const notisEventsBmcViewed = notisEventsBmc.filter((doc) => doc.viewed === true);
        const totalUnviewed = arr.filter((doc) => doc.viewed === false);
        setUserNotis({
          canteen: {
            viewed: notisCanteenViewed,
            unviewed: notisCanteenUnviewed,
          },
          eventsBmc: {
            viewed: notisEventsBmcViewed,
            unviewed: notisEventsBmcUnviewed,
          },
          tickets: {
            viewed: notisMaintenanceViewed,
            unviewed: notisMaintenanceUnviewed,
          },
          events: { viewed: notisEventsViewed, unviewed: notisEventsUnviewed },
          packages: {
            viewed: notisPackagesViewed,
            unviewed: notisPackagesUnviewed,
          },
          carsharing: {
            viewed: notisCarsharingViewed,
            unviewed: notisCarsharingUnviewed,
          },
          activitysharing: {
            viewed: notisActivitySharingViewed,
            unviewed: notisActivitySharingUnviewed,
          },
          sala: {
            viewed: notisSalaViewed,
            unviewed: notisSalaUnviewed,
          },
          totalUnviewed: totalUnviewed.length,
        });
      });
    }
  };
  useEffect(() => {
    // const q = query(collection(db, "users"));
    // onSnapshot(q, (querySnapshot) => {
    //   const arr: IUser[] = [];
    //   querySnapshot.forEach(async (doc: any) => {
    //     const data = { id: doc.id, ...doc.data() };
    //     arr.push(data);
    //   });
    //   setAllUsers(arr);
    // });
    checkRoles();
    getUserNotifications();
  }, [user, db]);

  // useEffect(() => {
  //   navigator.serviceWorker && !tokenMessaging && requestServiceWorkerPermissions();
  // }, [navigator]);

  const signout = async () => {
    try {
      await signOut(auth);
      localStorage.setItem("token", "");
    } catch (error) {
      console.log(error);
    }
  };

  const updateNotifications = (notisToUpdate) => {
    notisToUpdate &&
      notisToUpdate?.map(async (noti) => {
        return await updateDoc(doc(db, "users", user.id, "notifications", noti.id), {
          viewed: true,
        });
      });
  };
  const deleteNotifications = (notisToUpdate) => {
    notisToUpdate &&
      notisToUpdate?.map(async (noti) => {
        return await deleteDoc(doc(db, "users", user.id, "notifications", noti.id));
      });
  };
  const deleteNotificationsToUser = async (notisToUpdate, userToNotis) => {
    notisToUpdate &&
      notisToUpdate?.map(async (noti) => {
        return await deleteDoc(doc(db, "users", userToNotis.id, "notifications", noti.id));
      });
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        userNotis,
        updateNotifications,
        deleteNotifications,
        messaging,
        tokenMessaging,
        permissions,
        loadingUser,
        signup,
        signout,
        login,
        db,
        sendingPasswordResetMail,
        getUserData,
        uploadImage,
        progresspercent,
        allUsers,
        updateUser,
        updateEmailAuth,
        updateActualPassword,
        setNotification,
        notification,
        getUsersByBusinessId,
        getFirebaseNextUsers,
        getAllUsersByName,
        getFirebaseAllUsers,
        addNotiEventBmc,
        updateAllUsers,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useAuthState = () => {
  const auth = useContext(FirebaseContext);
  return {
    ...auth,
    loadingUser: auth.loadingUser,
    isAuthenticated: auth.user != null,
  };
};
