import React from "react";
type IIcon = {
  opacity?: string;
};

const EyeIcon = ({ opacity }: IIcon) => {
  return (
    <svg
      style={{ opacity: opacity }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14.63"
      height="9.75"
      viewBox="0 0 29.252 19.509"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_109"
            data-name="Rectángulo 109"
            width="29.252"
            height="19.509"
            fill="#5F5F5F"
          />
        </clipPath>
      </defs>
      <g id="ojo" transform="translate(0 0)">
        <g
          id="Grupo_48"
          data-name="Grupo 48"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Trazado_172"
            data-name="Trazado 172"
            d="M0,9.7c.288-.491.567-.987.865-1.471A17.793,17.793,0,0,1,6.9,2.165,14.687,14.687,0,0,1,13.047.084a15.11,15.11,0,0,1,11.7,3.778A18.551,18.551,0,0,1,29.22,9.6a.385.385,0,0,1,0,.306,18.175,18.175,0,0,1-6.877,7.477,14.622,14.622,0,0,1-6.072,2.04A15.112,15.112,0,0,1,4.564,15.653,18.5,18.5,0,0,1,.236,10.2C.165,10.063.079,9.94,0,9.812V9.7m20.763.053a6.108,6.108,0,1,0-6.117,6.112A6.125,6.125,0,0,0,20.763,9.75"
            transform="translate(0 0)"
            fill="#5F5F5F"
          />
          <path
            id="Trazado_173"
            data-name="Trazado 173"
            d="M38.944,17.508m-2.445.025a3.663,3.663,0,1,0-3.676,3.643A3.67,3.67,0,0,0,36.5,17.533"
            transform="translate(-18.182 -7.758)"
            fill="#5F5F5F"
          />
          <path
            id="Trazado_174"
            data-name="Trazado 174"
            d="M41.7,22.734a3.663,3.663,0,1,1-3.635-3.683A3.67,3.67,0,0,1,41.7,22.734"
            transform="translate(-23.383 -12.959)"
            fill="#5F5F5F"
          />
        </g>
      </g>
    </svg>
  );
};

export default EyeIcon;
