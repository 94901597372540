import styled from "@emotion/styled";
import { useContext } from "react";
import { FirebaseContext } from "../../../context/FirebaseContext";
import FloatButton from "../../../components/FloatButton";
import { useNavigate } from "react-router";
import { GymContext } from "../../../context/GymContext";

import Event from "./Event";

const EventsColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  input {
    margin-bottom: 20px;
  }
`;

const EventsDisplayer = ({ admin }: { admin: boolean }) => {
  const { events } = useContext(GymContext);
  const { user } = useContext(FirebaseContext);

  const navigation = useNavigate();
  return (
    <EventsColumn>
      {events?.length > 0 &&
        events.map((event, idx) => {
          const userInTheEvent = event?.participants.find((id) => id === user.id) ? true : false;

          return <Event event={event} key={`${event.id}-${idx}`} admin={admin} userInTheEvent={userInTheEvent} />;
        })}
      {admin && <FloatButton onClick={() => navigation("/new-gym-event")} />}
    </EventsColumn>
  );
};

export default EventsDisplayer;
