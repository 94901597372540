import { useContext } from "react";
import styled from "@emotion/styled";

import { ParcelContext } from "../../../context/ParcelContext";
import PackagesDisplayer from "../../../components/packagesDisplayer";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { Text } from "../../../genericComponents";

const Form = styled.div`
  padding-bottom: 150px;
  background-color: #f6f6f6;
`;

const ParcelWorker = () => {
  const { tickets, responsableTickets } = useContext(ParcelContext);
  const { user } = useContext(FirebaseContext);

  return (
    <Form>
      <Text
        weight="300"
        top="20px"
        bottom="0"
        width="80%"
        left="auto"
        right="auto"
      >
        PERSONAL
      </Text>
      {tickets?.pending && <PackagesDisplayer tickets={tickets?.pending} />}
      {tickets?.closed && <PackagesDisplayer tickets={tickets?.closed} />}
      {(!tickets?.pending.length && !tickets?.closed.length)  && (
        <Text size="14px" weight="200" top="20px" align="center">No hay ningún paquete pendiente.</Text>
      )}
      {user?.responsable && (
        <Text
          weight="300"
          top="20px"
          bottom="0"
          width="80%"
          left="auto"
          right="auto"
        >
          EMPRESA
        </Text>
      )}
      {user?.responsable && responsableTickets?.pending && (
        <PackagesDisplayer tickets={responsableTickets?.pending} />
      )}
      {user?.responsable && responsableTickets?.closed && (
        <PackagesDisplayer tickets={responsableTickets?.closed} />
      )}
      {user?.responsable && (!responsableTickets?.pending.length && !responsableTickets?.closed.length)   && (
        <Text size="14px" weight="200" top="20px" align="center">No hay ningún paquete pendiente.</Text>
      )}
    </Form>
  );
};

export default ParcelWorker;
