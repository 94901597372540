import styled from "@emotion/styled";
import { useContext } from "react";
// import { useContext } from "react";
// import { FirebaseContext } from "../../../context/FirebaseContext";
import { useNavigate } from "react-router";
import { SalasContext } from "../../../context/SalasContext";
import { Title, Text } from "../../../genericComponents";

const Button = styled.button<{ reversed?: boolean }>`
  color: ${(props) => (props.reversed ? "#000" : "#fff")};
  background-color: ${(props) => (props.reversed ? "#fff" : "#000")};
  padding: 0 20px;

  opacity: ${(props) => props.disabled && 0.6};
  width: auto;
  margin: 0 auto;
  margin-top: 20px;
  border: none;
  padding: 10px 15px;
`;
const SalasColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  input {
    margin-bottom: 20px;
  }
`;
const Card = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 200px;
  margin-top: 20px;
  box-shadow: 1px 1px 5px 0px #0000006b;
  span {
    padding: 0;
  }
`;
const Image = styled.div<{ src: string }>`
  background: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-position: center;

  width: 40%;
  height: 100%;
`;
const CardText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding: 0 20px;
`;
const SalasDisplayer = ({ admin }: { admin: boolean }) => {
  const { salas } = useContext(SalasContext);

  const navigation = useNavigate();
  return (
    <SalasColumn>
      {salas?.length > 0 &&
        salas.map((sala, idx) => {
          return (
            <Card key={`${sala?.id}-${idx}`}>
              <Image src={sala?.src} />
              <CardText>
                <Title top="20px" size="14px">
                  {sala?.name}
                </Title>
                <Text weight="200" top="10px" size="14px" color="#000000">
                  {sala?.capacity} personas
                </Text>

                <Button
                  onClick={() => {
                    admin
                      ? navigation("/books-admin", {
                          state: {
                            id: sala?.id,
                          },
                        })
                      : navigation("/new-book", {
                          state: {
                            id: sala?.id,
                          },
                        });
                  }}
                >
                  {admin ? "Gestionar" : "Reservar"}
                </Button>
              </CardText>
            </Card>
          );
        })}
    </SalasColumn>
  );
};

export default SalasDisplayer;
