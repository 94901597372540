const QRIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <path
        id="qr_code_scanner_FILL0_wght400_GRAD0_opsz48"
        d="M4,11.136V4h7.136V6.475H6.475v4.661ZM4,37V29.864H6.475v4.661h4.661V37Zm25.864,0V34.525h4.661V29.864H37V37Zm4.661-25.864V6.475H29.864V4H37v7.136Zm-4.62,18.81h2.6v2.6h-2.6Zm0-5.2h2.6v2.6h-2.6Zm-2.6,2.6h2.6v2.6h-2.6Zm-2.6,2.6h2.6v2.6h-2.6Zm-2.6-2.6h2.6v2.6h-2.6Zm5.2-5.2h2.6v2.6h-2.6Zm-2.6,2.6h2.6v2.6h-2.6Zm-2.6-2.6h2.6v2.6h-2.6ZM32.5,8.455V18.85H22.109V8.455ZM18.85,22.15V32.545H8.455V22.15Zm0-13.695V18.85H8.455V8.455ZM16.788,30.482v-6.27h-6.27v6.27Zm0-13.695v-6.27h-6.27v6.27Zm13.654,0v-6.27h-6.27v6.27Z"
        transform="translate(-4 -4)"
        fill="#fff"
      />
    </svg>
  );
};

export default QRIcon;
