import { useLocation, Routes, Route } from "react-router-dom";
import "../../index.css";
import Login from "../../pages/Login";
import Services from "../../pages/Services";
import Network from "../../pages/Network";
import Maintenance from "../../pages/Maintenance";
import Parcel from "../../pages/Parcel";
import Canteen from "../../pages/Canteen";
import Gym from "../../pages/Gym";
import Restaurant from "../../pages/Restaurant";
import Home from "../../pages";
import { useEffect } from "react";
import Signup from "../../pages/Signup";
import NewTicket from "../../pages/Maintenance/newTicket";
import BusinessTickets from "../../pages/Maintenance/BusinessTickets";
import NewGymEvent from "../../pages/Gym/newEvent";
import NewCanteenOffer from "../../pages/Canteen/newOffer";
import Account from "../../pages/Profile";

import MyProfile from "../../pages/Profile/MyProfile";
import MyCharsharing from "../../pages/Profile/MyCarsharing";
import Business from "../../pages/Network/Business";
import Partners from "../../pages/Network/Partners";
import Charsharing from "../../pages/Network/Carsharing";
import Activitysharing from "../../pages/Network/Activitysharing";
import Bmc from "../../pages/BMC";
import QrScanner from "../../pages/QrScanner";
import Salas from "../../pages/SalaBooking";
import SalaPicker from "../../pages/SalaBooking/SalaPicker";
import EditProfile from "../../pages/Profile/MyProfile/EditProfile";
import ChangePass from "../../pages/Profile/MyProfile/ChangePass";
import CreateBusiness from "../../pages/Business/CreateBusiness";
import AdminPanel from "../../pages/Admin";
import CanteenAdmin from "../../pages/Canteen/Admin";
import AdminGym from "../../pages/Gym/Admin";
import UsersPanel from "../../pages/Users";
import BusinessPanel from "../../pages/BusinessPanel";
import AdminMaintenance from "../../pages/Maintenance/Admin";
import AdminParcel from "../../pages/Parcel/Admin";
import AdminSalas from "../../pages/SalaBooking/Admin";
import EditUser from "../../pages/Users/EditUser";
import NewCarSharing from "../../pages/Network/Carsharing/New";
import EditBusiness from "../../pages/Business/editBusiness";
import Contact from "../../pages/BMC/Contact";
import AboutBmc from "../../pages/BMC/AboutBmc";
import BmcEvents from "../../pages/BMC/BmcEvents";
import BusinessCard from "../../pages/Network/Business/BusinessCard";
import EditMyBusiness from "../../pages/Profile/MyProfile/editMyBusiness";
import EventsBMC from "../../pages/Admin/EventsBMC";
import NewEventBmc from "../../pages/Admin/EventsBMC/New";
import Events from "../../pages/Network/Events";
import MyEvents from "../../pages/Profile/MyEvents";
import NewEvent from "../../pages/Network/Events/New";
import Statistics from "../../pages/Statistics";
import SliderAdmin from "../../pages/Admin/Slider";
import Notis from "../../pages/Profile/Notis";
import NotisDetails from "../../pages/Profile/Notis/NotisDetails";
import NewActivitySharing from "../../pages/Network/Activitysharing/New";
import Invitation from "../../pages/Invitation";
import TemporalAccess from "../../pages/TemporalAccess";
import { ProtectedRoute } from "../UtilRoutes/PrivateRoute";
import { AnonRoute } from "../UtilRoutes/AnonRoute";
import AdminPicker from "../../pages/SalaBooking/AdminPicker";
import Legal from "../../pages/Legal";
import InfoLegal from "../../pages/InfoLegal";
import LegalLSSI from "../../pages/Legal/Info";
import PrivacityPolicy from "../../pages/Privacity";
import CookiesPolicy from "../../pages/Cookies";
import Categories from "../../pages/Admin/Categories";
import NewEventWebBmc from "../../pages/Admin/EventsWebBMC/New";
import EventsWebBMC from "../../pages/Admin/EventsWebBMC";
import EditEventBmc from "../../pages/Admin/EventsBMC/Edit";
import EditEventBusiness from "../../pages/Network/Events/Edit";
import Offers from "../../pages/Network/Offers";
import AdminParcelClients from "../../pages/ParcelClients/Admin";



const Router = () => {
  // const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/invitation/:id" element={<TemporalAccess />} />
      <Route
        path="/login"
        element={
          <AnonRoute>
            <Login />
          </AnonRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute redirectPath="/login">
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/qrscanner"
        element={
          <ProtectedRoute redirectPath="/login">
            <QrScanner />
          </ProtectedRoute>
        }
      />
      <Route
        path="/account"
        element={
          <ProtectedRoute redirectPath="/login">
            <Account />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-profile"
        element={
          <ProtectedRoute redirectPath="/login">
            <MyProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categories"
        element={
          <ProtectedRoute redirectPath="/login">
            <Categories/>
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-my-business"
        element={
          <ProtectedRoute redirectPath="/login">
            <EditMyBusiness />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-profile"
        element={
          <ProtectedRoute redirectPath="/login">
            <EditProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-user"
        element={
          <ProtectedRoute redirectPath="/login">
            <EditUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-business"
        element={
          <ProtectedRoute redirectPath="/login">
            <EditBusiness />
          </ProtectedRoute>
        }
      />
      <Route
        path="/change-password"
        element={
          <ProtectedRoute redirectPath="/login">
            <ChangePass />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notis"
        element={
          <ProtectedRoute redirectPath="/login">
            <Notis />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notis-details"
        element={
          <ProtectedRoute redirectPath="/login">
            <NotisDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-carsharing"
        element={
          <ProtectedRoute redirectPath="/login">
            <MyCharsharing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/services"
        element={
          <ProtectedRoute redirectPath="/login">
            <Services />
          </ProtectedRoute>
        }
      />
      <Route
        path="/gym"
        element={
          <ProtectedRoute redirectPath="/login">
            <Gym />
          </ProtectedRoute>
        }
      />
      <Route
        path="/gym-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminGym />
          </ProtectedRoute>
        }
      />
      <Route
        path="/salas"
        element={
          <ProtectedRoute redirectPath="/login">
            <Salas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/salas-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminSalas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-book"
        element={
          <ProtectedRoute redirectPath="/login">
            <SalaPicker />
          </ProtectedRoute>
        }
      />
      <Route
        path="/parcel"
        element={
          <ProtectedRoute redirectPath="/login">
            <Parcel />
          </ProtectedRoute>
        }
      />

      <Route
        path="/parcel-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminParcel />
          </ProtectedRoute>
        }
      />
      <Route
        path="/parcel-admin-clients"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminParcelClients />
          </ProtectedRoute>
        }
      />

      <Route
        path="/users-panel"
        element={
          <ProtectedRoute redirectPath="/login">
            <UsersPanel />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business-panel"
        element={
          <ProtectedRoute redirectPath="/login">
            <BusinessPanel />
          </ProtectedRoute>
        }
      />
      <Route
        path="/canteen"
        element={
          <ProtectedRoute redirectPath="/login">
            <Canteen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/canteen-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <CanteenAdmin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/slider-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <SliderAdmin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-invitation"
        element={
          <ProtectedRoute redirectPath="/login">
            <Invitation />
          </ProtectedRoute>
        }
      />

      <Route
        path="/maintenance"
        element={
          <ProtectedRoute redirectPath="/login">
            <Maintenance />
          </ProtectedRoute>
        }
      />

      <Route
        path="/maintenance-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminMaintenance />
          </ProtectedRoute>
        }
      />

      <Route
        path="/maintenance-details"
        element={
          <ProtectedRoute redirectPath="/login">
            <BusinessTickets />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-gym-event"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewGymEvent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-canteen-offer"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewCanteenOffer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-ticket"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewTicket />
          </ProtectedRoute>
        }
      />
      <Route
        path="/restaurant"
        element={
          <ProtectedRoute redirectPath="/login">
            <Restaurant />
          </ProtectedRoute>
        }
      />
      <Route
        path="/network"
        element={
          <ProtectedRoute redirectPath="/login">
            <Network />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business"
        element={
          <ProtectedRoute redirectPath="/login">
            <Business />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business-card"
        element={
          <ProtectedRoute redirectPath="/login">
            <BusinessCard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/partners"
        element={
          <ProtectedRoute redirectPath="/login">
            <Partners />
          </ProtectedRoute>
        }
      />
      <Route
        path="/carsharing/new"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewCarSharing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/carsharing"
        element={
          <ProtectedRoute redirectPath="/login">
            <Charsharing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/activitysharing"
        element={
          <ProtectedRoute redirectPath="/login">
            <Activitysharing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/activitysharing/new"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewActivitySharing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bmc"
        element={
          <ProtectedRoute redirectPath="/login">
            <Bmc />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bmc-events"
        element={
          <ProtectedRoute redirectPath="/login">
            <BmcEvents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-event-bmc"
        element={
          <ProtectedRoute redirectPath="/login">
            <EditEventBmc />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-event-business"
        element={
          <ProtectedRoute redirectPath="/login">
            <EditEventBusiness />
          </ProtectedRoute>
        }
      />

      <Route
        path="/about-bmc"
        element={
          <ProtectedRoute redirectPath="/login">
            <AboutBmc />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contact"
        element={
          <ProtectedRoute redirectPath="/login">
            <Contact />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminPanel />
          </ProtectedRoute>
        }
      />
      <Route
        path="/statistics"
        element={
          <ProtectedRoute redirectPath="/login">
            <Statistics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <ProtectedRoute redirectPath="/login">
            <Signup />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-business"
        element={
          <ProtectedRoute redirectPath="/login">
            <CreateBusiness />
          </ProtectedRoute>
        }
      />
      <Route
        path="/events-bmc-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <EventsBMC />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-event-bmc"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewEventBmc />
          </ProtectedRoute>
        }
      />
      <Route
        path="/events-bmc-web-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <EventsWebBMC />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-event-bmc-web"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewEventWebBmc />
          </ProtectedRoute>
        }
      />
      <Route
        path="/events-business"
        element={
          <ProtectedRoute redirectPath="/login">
            <Events />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offers-business"
        element={
          <ProtectedRoute redirectPath="/login">
            <Offers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-events"
        element={
          <ProtectedRoute redirectPath="/login">
            <MyEvents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-event"
        element={
          <ProtectedRoute redirectPath="/login">
            <NewEvent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/books-admin"
        element={
          <ProtectedRoute redirectPath="/login">
            <AdminPicker />
          </ProtectedRoute>
        }
      />
      <Route
        path="/legal"
        element={
          <ProtectedRoute redirectPath="/login">
            <Legal />
          </ProtectedRoute>
        }
      />
      <Route
        path="/legal/info"
        element={
          <ProtectedRoute redirectPath="/login">
            <LegalLSSI />
          </ProtectedRoute>
        }
      />
      <Route
        path="/info-legal"
        element={
          <ProtectedRoute redirectPath="/login">
            <InfoLegal />
          </ProtectedRoute>
        }
      />
      <Route path="/cookies-policy" element={<CookiesPolicy />} />
      <Route path="/privacity" element={<PrivacityPolicy />} />
    </Routes>
  );
};

export default Router;
