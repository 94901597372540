import styled from "@emotion/styled";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Text } from "../../genericComponents";
import BmcIcon from "../../icons/bmcIcon";
import Submenu from "./Submenu";

const NavbarContainer = styled.nav`
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 100;

  box-shadow: 0px -1px 6px 0 #00000029;
`;
const IconsContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: black;
  }
`;

const ImageProfile = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const IconTest = styled.span`
  cursor: pointer;
`;
const CircleNotis = styled.span`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #215f8d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -14px;
  color: #fff;
  font-size: 12px;
  border: 1px solid #fff;
`;
const ContainerImage = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
`;
const NavTop = () => {
  const { user, userNotis } = useContext(FirebaseContext);
  const [showSubmenu, setShowSubmenu] = useState(false);
  return (
    <NavbarContainer>
      <IconsContainer>
        <Link to="/">
          <IconTest>
            <BmcIcon />
          </IconTest>
        </Link>
        {user?.name ? (
          <ContainerImage>
            <ImageProfile
              onClick={() => {
                setShowSubmenu(!showSubmenu);
              }}
              src={user?.image ? user?.image : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/default.jpg?alt=media&token=cd97b76e-b855-4e11-8258-513b5b708555"}
            />
            {userNotis?.totalUnviewed ? <CircleNotis>{userNotis?.totalUnviewed}</CircleNotis> : null}
            {showSubmenu && <Submenu onClickOutside={() => setShowSubmenu(false)} showSubmenu={showSubmenu} />}
          </ContainerImage>
        ) : (
          <Link to="/login">
            <Text weight="200" size="15px" decoration="underline">
              Iniciar sesión
            </Text>
          </Link>
        )}
      </IconsContainer>
    </NavbarContainer>
  );
};
export default NavTop;
