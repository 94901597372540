import styled from "@emotion/styled";
import { Footer } from "../../pages";
import DevelopedByPevitech from "../DevelopedByPevitech";
import Navbar from "../Navbar";
import NavTop from "../NavTop";

const Main = styled.main<{ overflowHidden: boolean }>`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: ${(props) => props.overflowHidden && "hidden"};
`;
const ContainerChildren = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  height: 100%;
`;
const Layout = ({ anonUser, children }: any) => {
  return (
    <Main overflowHidden={location.pathname === "/restaurant"}>
      <NavTop />
      <ContainerChildren>
        {children}
        {(location.pathname === "/about-bmc" || location.pathname === "/account") && <DevelopedByPevitech />}
        {location.pathname === "/" && <Footer />}
      </ContainerChildren>

      {!anonUser && <Navbar />}
    </Main>
  );
};

export default Layout;
