import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Text, Title } from "../../genericComponents";

const LinkCustom = styled(Link)<{ img: string }>`
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.img && `url(${props.img})`};
  background-position: center;
  background-size: cover;
  box-shadow: 0px 2px 5px 2px #00000012;
  flex-basis: 45%;
  margin-bottom: 15px;
  height: 195px;
`;
const SkeletonCustom = styled(Skeleton)`
  margin-bottom: 15px;
  box-shadow: 0px 2px 5px 2px #00000012;
`;

const BlurContainer = styled.div`
  margin-top: auto;
  backdrop-filter: blur(12px);
  padding: 10px;
`;

const LazyImage = ({ item }) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = item?.img;
    img.onload = () => setSource(item?.img);
  }, [item?.img]);

  return item ? (
    source ? (
      <LinkCustom img={item.img}  to={item?.route}>
        <BlurContainer>
          <Title align="center" color="#fff" weight="normal" size="14px">
            {item?.title}
          </Title>

          {item?.subtitle && (
            <Text
              top="5px"
              align="center"
              color="#fff"
              weight="normal"
              size="12px"
              decoration="underline"
            >
              {item?.subtitle}
            </Text>
          )}
        </BlurContainer>
      </LinkCustom>
    ) : (
      <SkeletonCustom
        animation="wave"
        variant="rectangular"
        width="45%"
        height="195px"
      />
    )
  ) : null;
};
const CardWithImages = ({ item }) => {
  return <LazyImage item={item} />;
};

export default CardWithImages;
