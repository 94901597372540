import styled from "@emotion/styled";
import { query, collection, where, getDocs } from "firebase/firestore";
import React, { useContext, useState } from "react";
import DatePickerCustom from "../../components/DatePicker";
import InputButtonWithProps from "../../components/InputButton";
import InputWithLegend from "../../components/InputWithLegend";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Text } from "../../genericComponents";

const Container = styled.div`
  background-color: #f6f6f6;
  position: relative;
  padding-bottom: 150px;
`;

const InnerContainer = styled.form`
  padding: 20px 30px;
  background-color: #fff;
  margin: 20px;
`;

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;
type IAccess = {
  creationDate: number;
  totalAccess: { userId: string; timestamp: number }[];
};
const Statistics = () => {
  const { db } = useContext(FirebaseContext);
  const [valuesToObtain, setValuesToObtain] = useState({
    initDay: "",
    finishDay: "",
    initHour: "",
    finishHour: "",
  });
  const [result, setResult] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [count, setCount] = useState(0);

  const handleChange = (e) => {
    setValuesToObtain({
      ...valuesToObtain,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingResult(true)
    let counter = 0;
    try {
      const initDate = new Date(valuesToObtain.initDay);
      initDate.setHours(
        Number(valuesToObtain.initHour.split(":")[0]),
        Number(valuesToObtain.initHour.split(":")[1])
      );
      const finishDate = new Date(valuesToObtain.finishDay);
      finishDate.setHours(
        Number(valuesToObtain.finishHour.split(":")[0]),
        Number(valuesToObtain.finishHour.split(":")[1])
      );

      const q = query(
        collection(db, "statistics-access"),
        where(
          "creationDate",
          ">=",
          new Date(valuesToObtain.initDay).setHours(0, 0, 0, 0)
        ),
        where(
          "creationDate",
          "<=",
          new Date(valuesToObtain.finishDay).setHours(0, 0, 0, 0)
        )
      );

      const querySnapshot = await getDocs(q);

      const filtered: any = await Promise.all(
        querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      );

      filtered?.forEach((result: IAccess) => {
        result.totalAccess.forEach((access) => {
          if (
            access.timestamp > initDate.getTime() &&
            access.timestamp < finishDate.getTime()
          )
            return (counter = counter + 1);
        });
      });

      setCount(counter);
      setLoadingResult(false)

      setResult(true);
    } catch (error) {}
  };

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/statistics%2Fcabecera_estadisticas.png?alt=media&token=794cc90f-c01b-4c0a-8c10-24dbdebc6900"
          title="ESTADÍSTICAS"
          subtitle="Selecciona el período de tiempo para obtener estadísticas del tráfico de personas en el edificio."
          showBackButton
        />
        <InnerContainer onSubmit={handleSubmit}>
          <DatePickerCustom
            title="Seleccionar día inicio"
            label="initDay"
            setFunct={setValuesToObtain}
            data={valuesToObtain}
            disableMin
          />
          <DatePickerCustom
            title="Seleccionar día final"
            label="finishDay"
            setFunct={setValuesToObtain}
            data={valuesToObtain}
            initSelected={new Date(valuesToObtain?.initDay).getTime()}
          />
          <InputWithLegend
            title="Seleccionar hora inicio"
            name="initHour"
            type="time"
            value={valuesToObtain.initHour}
            onChange={handleChange}
            disableMin
          />
          <InputWithLegend
            title="Seleccionar hora final"
            name="finishHour"
            type="time"
            value={valuesToObtain.finishHour}
            onChange={handleChange}
            disableMin
          />
          <ContainerButton>
            <InputButtonWithProps
              disabled={
                !valuesToObtain.finishDay ||
                !valuesToObtain.initDay ||
                !valuesToObtain.initHour ||
                !valuesToObtain.finishHour
              }
              type="submit"
              border="none"
              text="Mostrar resultado"
            />
            {result && (
              <Text top="20px" color="black">
               {loadingResult ? 'Calculando...' : ` Resultado: ${count} personas`}
              </Text>
            )}
          </ContainerButton>
        </InnerContainer>
      </Container>
    </Layout>
  );
};

export default Statistics;
