import styled from "@emotion/styled";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";

const RestaurantContainer = styled.div`
  padding-bottom: 150px;
  height:100vh;
  overflow: hidden;
  
`;
const Iframe = styled.iframe`
 padding-bottom: 150px;
 width:100vw;
 height:100vh;
`;

const Restaurant = () => {
 
  return (
    <Layout>
      <RestaurantContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fcabecera_restaurante.jpg?alt=media&token=dcf10619-e32c-410b-9c81-c2cbf38ec0be"
          title="RESTAURANTE"
          subtitle="Consulta aquí el menú de nuestro restaurante. Toda nuestra oferta en la palma de tu mano."
          showBackButton
        />
          <Iframe
            id="inlineFrameExample"
            title="Menu diario Lobby"
            width="100vw"
            height="100vh"
            src="https://lobbyrestaurant.es/menu-diari/"
          ></Iframe>
      </RestaurantContainer>
    </Layout>
  );
};

export default Restaurant;
