import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import InputRadioButton from "../../../../components/InputRadioButton";
import InputWithLegend from "../../../../components/InputWithLegend";
import Layout from "../../../../components/Layout";
import SectionHeader from "../../../../components/SectionHeader";
import BackIcon from "../../../../icons/services/backIcon";
import CalendarIcon from "../../../../icons/services/calendarIcon";
import { Text } from "../../../../genericComponents";
import InputCheckbox from "../../../../components/InputCheckbox";
import UserIcon from "../../../../icons/services/userIcon";
import CarIcon from "../../../../icons/services/carIcon";
import ContactIcon from "../../../../icons/services/contactIcon";
import TextAreaWithLegend from "../../../../components/TextAreaWithLegend";
import InputButtonWithProps from "../../../../components/InputButton";
import SectionFooter from "../../../../components/SectionFooter";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { addDoc, collection } from "firebase/firestore";
import ModalCusstom from "../../../../components/ModalCustom";
import { useNavigate } from "react-router";
import DatePickerCustom from "../../../../components/DatePicker";
import LocationIcon from "../../../../icons/locationIcon";
import { ImageCircle } from "../../Events/New";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const ContainerDiv = styled.div`
  padding: 15px;
`;
const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  p {
    margin-left: 5px;
  }
`;

const Invisible = styled.div`
  display: none;
`;
const InputInvisible = styled.input`
  display: flex;
`;

const InnerContainer = styled.form`
  margin: 20px 25px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;

const NewActivitySharing = () => {
  const { db, user, uploadImage } = useContext(FirebaseContext);
  const navigation = useNavigate();

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [newActivitySharing, setnewActivitySharing] = useState({
    title: "",
    date: new Date().getTime(),
    hour: "",
    location: "",
    contact: "",
    description: "",
  });

  const [disableButton, setDisableButton] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    switch (e.target.name) {
      case "date":
        const init = new Date(e.target.value).getTime();
        setnewActivitySharing({ ...newActivitySharing, [e.target.name]: init });
        break;
      case "image":
        setImage(e.target.files[0]);
        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;

      default:
        setnewActivitySharing({
          ...newActivitySharing,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setOpenModal(true);
      setModalMessage("Creando actividad...");

      if (image) {
        const url = await uploadImage(image);
        await addDoc(collection(db, "activitysharing"), {
          userId: user.id,
          details: newActivitySharing,
          participants: [],
          image: url,
        });
      } else {
        await addDoc(collection(db, "activitysharing"), {
          userId: user.id,
          details: newActivitySharing,
          participants: [],
        });
      }
      setOpenModal(true);
      setModalMessage("Actividad creada correctamente.");
    } catch (error) {
      console.log(error)
      setOpenModal(true);
      setModalMessage("Ha habido un error, inténtelo de nuevo más tarde.");
    } finally {
      setTimeout(() => {
        setOpenModal(false);
        setModalMessage("");
        navigation("/activitysharing");
      }, 3000);
    }
  };

  useEffect(() => {
    if (
      newActivitySharing.title &&
      newActivitySharing.date !== 0 &&
      newActivitySharing.hour !== "" &&
      newActivitySharing.location &&
      newActivitySharing.contact &&
      newActivitySharing.description
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [newActivitySharing]);

  return (
    <Layout>
      <Container>
        <SectionHeader title="PUBLICAR ACTIVIDAD" showBackButton={true} />
        <InnerContainer onSubmit={handleSubmit}>
          <InputWithLegend
            preIcon={<BackIcon />}
            title="Título*"
            name="title"
            type="text"
            value={newActivitySharing.title}
            onChange={handleChange}
          />
          <DatePickerCustom
            preIcon={<CalendarIcon />}
            label="date"
            data={newActivitySharing}
            legendColor="#5F5F5F"
            setFunct={setnewActivitySharing}
            title="Fecha"
            width="100%"
          />
          <InputWithLegend
            preIcon={<CalendarIcon />}
            title="Hora*"
            name="hour"
            type="time"
            value={newActivitySharing.hour}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<LocationIcon />}
            title="Ubicación*"
            name="location"
            type="text"
            value={newActivitySharing.location}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<ContactIcon />}
            title="Contacto*"
            name="contact"
            type="text"
            value={newActivitySharing.contact}
            onChange={handleChange}
          />
          <TextAreaWithLegend
            title="Añadir descripción."
            subtitle="Aquí puedes explicar el plan, el punto de encuentro, etc.."
            legendColor="#5B656E"
            legendSubtitleColor="#5F5F5F"
            name="description"
            value={newActivitySharing.description}
            onChange={handleChange}
          />
          <ContainerDiv>
            <Text
              left="10px"
              color="#5F5F5F"
              size="14px"
              top="10px"
              bottom="10px"
            >
              Imagen del evento
            </Text>
            <ImageCircle
              imagePreview={
                preview
                  ? preview
                  : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/default_img.jpeg?alt=media&token=860e4b3e-fe68-4359-9e90-1e7a79fc7a1d"
              }
              onClick={() => document.getElementById("image").click()}
            >
              {!image && !preview && <Image src="/upload.png" />}
            </ImageCircle>
          </ContainerDiv>
          <Invisible>
            <InputInvisible
              name="image"
              type="file"
              id="image"
              onChange={handleChange}
              title="Imagen del evento"
            />
          </Invisible>
          <ContainerButton>
            <InputButtonWithProps
              disabled={disableButton}
              type="submit"
              border="none"
              text="Publicar actividad"
              top="20px"
              bottom="20px"
            />
          </ContainerButton>
        </InnerContainer>
        <SectionFooter src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fnetwork%2Fpie_network.jpg?alt=media&token=536a2fff-50a1-4ddf-8dc5-fdeca59294f5" />
      </Container>
      <ModalCusstom open={openModal} message={modalMessage} />
    </Layout>
  );
};

export default NewActivitySharing;
