import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

interface ILazyImage {
  src: string;
  animation: false | "pulse" | "wave";
  variant: "text" | "rectangular" | "circular";
  width: string | number;
  height: string | number;
}

const SkeletonCustom = styled(Skeleton)`
  margin-bottom: 15px;
`;

const ImageHeader = styled.div<{ src: string; height: string | number }>`
  background: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  height: ${(props) => (props.height ? `${props.height}` : "91px")};
  width: 100%;
`;

const LazyImage = (props: ILazyImage) => {
  const { src, animation, variant, width, height } = props;

  const [source, setSource] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSource(src);
  }, [src]);

  return source ? (
    <ImageHeader height={height} src={src}></ImageHeader>
  ) : (
    <SkeletonCustom
      animation={animation}
      variant={variant}
      width={width}
      height={height}
    />
  );
};

export default LazyImage;
