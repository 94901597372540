import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import TicketsDisplayer from "../../../components/ticketsDisplayer";
import { MaintenanceContext } from "../../../context/MaintenanceContext";

import { Text, Title } from "../../../genericComponents";
import styled from "@emotion/styled";
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom:150px;
`;

const BusinessTickets = () => {
  const location: any = useLocation();
  const navigation = useNavigate();
  const { id, name } = location?.state;

  const { allTickets } = useContext(MaintenanceContext);

  const business = allTickets.length && allTickets?.find((business) => business.id === id);
  

  return (
    <Layout>
      <Text onClick={() => navigation(-1)} left="20px" size="24px">
        ←
      </Text>
      <Title top="25px" align="center" size="25px">
        {name}
      </Title>
      <Column>
        <TicketsDisplayer admin tickets={business?.tickets?.pending} />

        <TicketsDisplayer admin tickets={business?.tickets?.progress} />

        <TicketsDisplayer admin tickets={business?.tickets?.closed} />
      </Column>
    </Layout>
  );
};

export default BusinessTickets;
