const ContactIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <path
        id="mail_FILL0_wght400_GRAD0_opsz48"
        d="M5.5,24a1.439,1.439,0,0,1-1.05-.45A1.439,1.439,0,0,1,4,22.5V9.5a1.439,1.439,0,0,1,.45-1.05A1.439,1.439,0,0,1,5.5,8h17A1.538,1.538,0,0,1,24,9.5v13A1.538,1.538,0,0,1,22.5,24ZM14,16.45,5.5,10.875V22.5h17V10.875Zm0-1.5L22.4,9.5H5.625ZM5.5,10.875V22.5h0Z"
        transform="translate(-4 -8)"
        fill="#5B656E"
      />
    </svg>
  );
};
export default ContactIcon;
