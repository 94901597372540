import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { SliderContext } from "../../../context/SliderContext";
import { Text } from "../../../genericComponents";
import InputButtonWithProps from "../../../components/InputButton";
import { EventsContext, IEventsBmc } from "../../../context/EventsContext";
import { CanteenContext } from "../../../context/CanteenContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import SelectorElementActive from "../../../components/SelectorElementActive";
import ActiveEvents from "./ActiveEvents";
const Container = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 150px;
`;
const ContainerEvents = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 15px;
  background: #fff;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 0.3px solid #000;
`;

const SliderAdmin = () => {
  const { updateSliderStatus, deleteItemToSlider, slidesEvents, loading } = useContext(SliderContext);
  const { offers } = useContext(CanteenContext);
  const { eventsBmc } = useContext(EventsContext);
  const { events } = useContext(EventsContext);
  const { user } = useContext(FirebaseContext);
  const [elementActive, setElementActive] = useState(1);
  const businessEvents = events.filter((doc) => doc.businessId !== "zqo2igcdtwmIuDHIJsgV");

  const firstElement = {
    text: "Activos",
    ref: 1,
  };

  const secondElement = {
    text: "Disponibles",
    ref: 2,
  };

  const lobbyEvents = events.filter((doc) => doc?.businessId === "zqo2igcdtwmIuDHIJsgV");
  const bmcEvents = slidesEvents.filter((doc) => doc?.type === "eventBmc");
  const lobby = slidesEvents.filter((doc) => doc?.type === "lobby");
  const canteenEvents = slidesEvents.filter((doc) => doc.type === "offer");
  const businessEventsSlider = slidesEvents.filter((doc) => doc.type === "business");

  const addEventToSlider = async (itemId: string, type: string) => {
    await updateSliderStatus(itemId, type);
    setElementActive(1);
  };

  const deleteToSlider = async (itemId: string) => {
    await deleteItemToSlider(itemId);
  };
  return (
    <Layout>
      <Container>
        <SectionHeader title="SLIDER ADMIN" showBackButton />
        <SelectorElementActive firstElement={firstElement} secondElement={secondElement} setActiveElement={setElementActive} activeElement={elementActive} />
        {elementActive === 2 && (
          <>
            {user.roles.super && (
              <ContainerEvents>
                <Text align="left" width="50%" size="18px" weight="200">
                  EVENTOS BMC
                </Text>
                {eventsBmc.length > 0 ? (
                  eventsBmc.map((event, idx) => {
                    const itemInTheSlider = bmcEvents?.find((doc) => doc?.eventId === event.id);
                    return (
                      <Row key={`${event.id}–${idx}`}>
                        <Text align="left" width="50%">
                          {event.title}
                        </Text>
                        {itemInTheSlider ? (
                          <InputButtonWithProps
                            onClick={() => {
                              deleteToSlider(itemInTheSlider.id);
                            }}
                            color="#fff"
                            textColor="#000"
                            border="1px solid #000"
                            text="Dejar de mostrar"
                            type="button"
                          />
                        ) : (
                          <InputButtonWithProps
                            onClick={() => {
                              addEventToSlider(event.id, "eventBmc");
                            }}
                            type="button"
                            text="Mostrar en Slider"
                          />
                        )}
                      </Row>
                    );
                  })
                ) : !loading ? (
                  <Text align="left" width="50%">
                    No hay eventos actualmente...
                  </Text>
                ) : (
                  <Text align="left" width="50%">
                    Comprobando...
                  </Text>
                )}
              </ContainerEvents>
            )}
            <ContainerEvents>
              <Text align="left" width="50%" size="18px" weight="200">
                OFERTAS
              </Text>
              {offers.length > 0 ? (
                offers.map((offer, idx) => {
                  const itemInTheSlider = canteenEvents.find((doc) => doc?.eventId === offer.id);
                  return (
                    <Row key={`${offer.id}–${idx}`}>
                      <Text align="left" width="50%">
                        {offer.title}
                      </Text>
                      {itemInTheSlider ? (
                        <InputButtonWithProps
                          onClick={() => {
                            deleteToSlider(itemInTheSlider.id);
                          }}
                          color="#fff"
                          textColor="#000"
                          border="1px solid #000"
                          text="Dejar de mostrar"
                          type="button"
                        />
                      ) : (
                        <InputButtonWithProps
                          onClick={() => {
                            addEventToSlider(offer.id, "offer");
                          }}
                          disabled={lobby.length > 0 || canteenEvents.length > 0}
                          type="button"
                          text="Mostrar en Slider"
                        />
                      )}
                    </Row>
                  );
                })
              ) : !loading ? (
                <Text align="left" width="50%">
                  No hay eventos actualmente...
                </Text>
              ) : (
                <Text align="left" width="50%">
                  Comprobando...
                </Text>
              )}
            </ContainerEvents>
            <ContainerEvents>
              <Text align="left" width="50%" size="18px" weight="200">
                EVENTOS EMPRESAS
              </Text>
              {businessEvents.length > 0 && user.roles.super ? (
                businessEvents.map((offer, idx) => {
                  const itemInTheSlider = businessEventsSlider.find((doc) => doc?.eventId === offer.id);
                  return (
                    <Row key={`${offer.id}–${idx}`}>
                      <Text align="left" width="50%">
                        {offer.title}
                      </Text>
                      {itemInTheSlider ? (
                        <InputButtonWithProps
                          onClick={() => {
                            deleteToSlider(itemInTheSlider.id);
                          }}
                          color="#fff"
                          textColor="#000"
                          border="1px solid #000"
                          text="Dejar de mostrar"
                          type="button"
                        />
                      ) : (
                        <InputButtonWithProps
                          onClick={() => {
                            addEventToSlider(offer.id, "business");
                          }}
                          disabled={businessEventsSlider.length > 1}
                          type="button"
                          text="Mostrar en Slider"
                        />
                      )}
                    </Row>
                  );
                })
              ) : !loading ? (
                <Text align="left" width="50%">
                  No hay eventos actualmente...
                </Text>
              ) : (
                <Text align="left" width="50%">
                  Comprobando...
                </Text>
              )}
            </ContainerEvents>
            <ContainerEvents>
              <Text align="left" width="50%" size="18px" weight="200">
                EVENTOS LOBBY
              </Text>
              {lobbyEvents.length > 0 ? (
                lobbyEvents.map((event, idx) => {
                  const itemInTheSlider = lobby?.find((doc) => doc?.eventId === event.id);
                  return (
                    <Row key={`${event.id}–${idx}`}>
                      <Text align="left" width="50%">
                        {event.title}
                      </Text>
                      {itemInTheSlider ? (
                        <InputButtonWithProps
                          onClick={() => {
                            deleteToSlider(itemInTheSlider.id);
                          }}
                          color="#fff"
                          textColor="#000"
                          border="1px solid #000"
                          text="Dejar de mostrar"
                          type="button"
                        />
                      ) : (
                        <InputButtonWithProps
                          onClick={() => {
                            addEventToSlider(event.id, "lobby");
                          }}
                          disabled={lobby.length > 0 || canteenEvents.length > 0}
                          type="button"
                          text="Mostrar en Slider"
                        />
                      )}
                    </Row>
                  );
                })
              ) : !loading ? (
                <Text align="left" width="50%">
                  No hay eventos actualmente...
                </Text>
              ) : (
                <Text align="left" width="50%">
                  Comprobando...
                </Text>
              )}
            </ContainerEvents>
          </>
        )}
        {elementActive === 1 && <ActiveEvents />}
      </Container>
    </Layout>
  );
};

export default SliderAdmin;
