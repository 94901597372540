import styled from "@emotion/styled";
import React from "react";
type IButton = {
  textColor?: string;
  color?: string;
  size?: number;
  fontSize?: number;
  text?: string;
  onClick?: any;
  onSubmit?: any;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  border?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  href?: string;
};
const AButton = styled.a<IButton>`
  color: ${(props) => (props.textColor ? props.textColor : "#fff")};
  background: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "normal")};
  width: ${(props) => (props.size ? `${props.size}px` : "140px")};
  height: ${(props) => (props.size ? `${props.size}px` : "32px")};

  margin: 0 auto;
  margin-top:20px;
  text-align: center;
  text-decoration:none;
  opacity: ${(props) => props.disabled && 0.5};
  border: ${(props) => props.border && props.border};
  display: flex;
  justify-content: center;
  flex-direction: column;
  :hover{
    opacity:0.6;
  }

  cursor: pointer;
`;
const CallButtonWithProps = ({
  textColor,
  color,
  size,
  fontSize,
  onClick,
  text,
  left,
  top,
  bottom,
  right,
  disabled,
  href,
}: IButton) => {
  return (
    <AButton
      textColor={textColor}
      color={color}
      size={size}
      fontSize={fontSize}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      disabled={disabled}
      href={href}
      onClick={() => onClick && onClick()}
    >
      {text}
    </AButton>
  );
};

export default CallButtonWithProps;
