const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.483"
      height="19.874"
      viewBox="0 0 20.483 19.874"
    >
      <path
        id="person_FILL0_wght400_GRAD0_opsz48"
        d="M18.241,18.551a4.6,4.6,0,0,1-4.8-4.8,4.6,4.6,0,0,1,4.8-4.8,4.6,4.6,0,0,1,4.8,4.8,4.6,4.6,0,0,1-4.8,4.8ZM8,28.824V25.816a3.522,3.522,0,0,1,.608-2.08,3.9,3.9,0,0,1,1.568-1.312,24.056,24.056,0,0,1,4.113-1.44,16.625,16.625,0,0,1,3.952-.48,15.925,15.925,0,0,1,3.936.5,27.263,27.263,0,0,1,4.1,1.424,3.859,3.859,0,0,1,1.6,1.312,3.522,3.522,0,0,1,.608,2.08v3.008ZM9.92,26.9H26.562V25.816a1.753,1.753,0,0,0-.3-.976,1.927,1.927,0,0,0-.752-.688,16.994,16.994,0,0,0-3.744-1.36,16.558,16.558,0,0,0-3.52-.368,17.009,17.009,0,0,0-3.552.368,16.255,16.255,0,0,0-3.744,1.36A1.926,1.926,0,0,0,9.92,25.816Zm8.321-10.273a2.791,2.791,0,0,0,2.88-2.88,2.881,2.881,0,1,0-5.761,0,2.791,2.791,0,0,0,2.88,2.88Zm0-2.88Zm0,13.154Z"
        transform="translate(-8 -8.95)"
        fill="#5B656E"
      />
    </svg>
  );
};

export default UserIcon;
