import styled from "@emotion/styled";
import { IEventsBmc } from "../../context/EventsContext";
import { Text } from "../../genericComponents";
import moment from "moment";
import CalendarIcon from "../../icons/services/calendarIcon";
import CircleIcon from "../CircleIcon";
import { useNavigate } from "react-router";

const Card = styled.div`
  position: relative;
  min-height: 335px;
  width: 80%;
  margin: 0 auto;
  background-color: #ffffff;
  margin-top: 20px;
`;
const EventImage = styled.div<{ image: string }>`
  background: ${(props) => props.image && `url(${props.image})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 142px;
`;
const ColumnTime = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 85%;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 10px 15px;
`;
const RowTime = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  svg {
    width: 10%;
    margin-right: 20px;
  }
`;
export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  position: absolute;
  top: 20px;
  right: 20px;
`;
const EventCardAdmin = ({ eventData, deleteFunct, editFunct }: { eventData: IEventsBmc; deleteFunct: any; editFunct?: any }) => {
  const renderIconsDelete = (doc?: IEventsBmc) => {
    return (
      <ContainerIcons>
        <CircleIcon color="#BF2A2A" src="/bin.png" funcClick={() => deleteFunct()} />
        <span onClick={() => editFunct()} style={{ marginLeft: "10px" }}>
          <CircleIcon borderColor="#000" color="#FFF" src="/write.png" />
        </span>
      </ContainerIcons>
    );
  };

  return (
    <Card>
      {renderIconsDelete()}
      {eventData?.image && <EventImage image={eventData?.image} />}
      <InfoContainer>
        <Text top="10px" align="left" size="14px" weight="600">
          {eventData?.title}
        </Text>
        <RowTime>
          <CalendarIcon />
          <ColumnTime>
            <Text size="13px" weight="200">
              Fecha: {moment(eventData?.initTimestamp).format("DD/MM/YYYY")}–{moment(eventData?.finishTimestamp).format("DD/MM/YYYY")}
            </Text>
            <Text size="13px" weight="200">
              Hora: {eventData?.allDay ? "Todo el día" : eventData?.hour}
            </Text>
          </ColumnTime>
        </RowTime>
        <Text top="10px" size="13px" weight="200">
          {eventData.description}
        </Text>
        {eventData?.externalUrl && <a style={{fontSize:"13px", wordBreak: "break-all"}} href={eventData?.externalUrl} target="_blank" rel="noopener noreferrer">{eventData?.externalUrl}</a>}
      </InfoContainer>
    </Card>
  );
};

export default EventCardAdmin;
