const Colaboration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71.376"
      height="35.688"
      viewBox="0 0 71.376 35.688"
    >
      <path
        id="colaboracion"
        d="M0,47.688V43.748q0-2.9,3.123-4.684a16.053,16.053,0,0,1,8.03-1.784q.967,0,1.784.037a12.242,12.242,0,0,1,1.636.186,12.106,12.106,0,0,0-1.19,5.353v4.833Zm17.844,0V42.855q0-4.833,4.944-7.807t12.9-2.974q8.03,0,12.937,2.974t4.907,7.807v4.833Zm40.149,0V42.855a14.209,14.209,0,0,0-.26-2.788,11.144,11.144,0,0,0-.855-2.565,12.242,12.242,0,0,1,1.636-.186q.818-.037,1.71-.037a15.969,15.969,0,0,1,8.1,1.784q3.048,1.784,3.048,4.684v3.941ZM35.688,36.536a22.438,22.438,0,0,0-9.666,1.784q-3.718,1.784-3.718,4.535v.372H49.071v-.446q0-2.677-3.68-4.461A22.39,22.39,0,0,0,35.688,36.536ZM11.153,35.049a5.015,5.015,0,0,1-3.68-1.524,5.205,5.205,0,0,1,0-7.361,5.015,5.015,0,0,1,3.68-1.524,5.2,5.2,0,0,1,3.68,8.885A5.015,5.015,0,0,1,11.153,35.049Zm49.071,0a5.2,5.2,0,0,1-3.68-8.885A5.2,5.2,0,0,1,63.9,33.524,5.015,5.015,0,0,1,60.224,35.049Zm-24.536-5.2a8.885,8.885,0,0,1-8.922-8.922,8.57,8.57,0,0,1,2.6-6.357A8.668,8.668,0,0,1,35.688,12a8.763,8.763,0,0,1,8.922,8.922,8.668,8.668,0,0,1-2.565,6.32A8.57,8.57,0,0,1,35.688,29.844Zm0-13.383a4.367,4.367,0,0,0-3.16,1.264,4.533,4.533,0,0,0,0,6.357,4.533,4.533,0,0,0,6.357,0,4.367,4.367,0,0,0,1.264-3.16,4.323,4.323,0,0,0-4.461-4.461Zm0,26.766Zm0-22.305Z"
        transform="translate(0 -12)"
        fill="#5B656E"
      />
    </svg>
  );
};

export default Colaboration;
