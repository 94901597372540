import styled from "@emotion/styled";
type ITitle = {
  color?: string;
  size?: string;
  pointer?: boolean;
  lineHeight?: string;
  decoration?: string;
  selected?: boolean;
  align?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  width?: string;
  flex?: boolean;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  hover?: boolean;
  weight?: string;
  italic?: boolean;
};
type ILink = { selected?: boolean };
export const Input = styled.input`
  min-width: 100%;
  height: 36px;
  background: #f6f6f6;
  border: none;
  opacity: ${(props) => props.disabled && 0.6};
  padding-left: 4px;
`;
export const TextArea = styled.textarea`
  width: 100%;
  height: 123px;
  background: #f6f6f6;
  border: none;
`;

export const Text = styled.p<ITitle>`
  font-size: ${(props) => (props.size ? props.size : "normal")};
  font-style: ${(props) => (props.italic ? "italic" : "normal")};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.pointer && "pointer"};
  text-decoration: ${(props) => props.decoration && props.decoration};
  font-weight: ${(props) => (props.weight ? props.weight : "normal")};
  text-align: ${(props) => props.align && props.align};
  margin-top: ${(props) => props.top && props.top};
  margin-left: ${(props) => (props.left ? props.left : 0)};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : 0)};
  margin-right: ${(props) => (props.right ? props.right : 0)};
  width: ${(props) => props.width && props.width};
  flex-direction: ${(props) => props.flexDirection && props.flexDirection};
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  display: ${(props) => props.flex && "flex"};
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  :hover {
    font-weight: ${(props) => props.hover && "bolder"};
  }
`;
export const Link = styled.a<ITitle>`
  font-size: ${(props) => (props.size ? props.size : "normal")};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.pointer && "pointer"};
  text-decoration: ${(props) => props.decoration && props.decoration};
  font-weight: ${(props) => (props.weight ? props.weight : "normal")};
  text-align: ${(props) => props.align && props.align};
  margin-top: ${(props) => props.top && props.top};
  margin-left: ${(props) => (props.left ? props.left : 0)};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : 0)};
  margin-right: ${(props) => (props.right ? props.right : 0)};
  width: ${(props) => props.width && props.width};
  flex-direction: ${(props) => props.flexDirection && props.flexDirection};
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  display: ${(props) => props.flex && "flex"};
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  :hover {
    font-weight: ${(props) => props.hover && "bolder"};
  }
`;
export const Title = styled.h1<ITitle>`
  font-size: ${(props) => (props.size ? props.size : "normal")};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.pointer && "pointer"};
  text-decoration: ${(props) => props.decoration && props.decoration};
  font-weight: ${(props) => props.weight && props.weight};
  text-align: ${(props) => props.align && props.align};
  margin-top: ${(props) => props.top && props.top};
  margin-left: ${(props) => (props.left ? props.left : 0)};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : 0)};
  margin-right: ${(props) => (props.right ? props.right : 0)};
  width: ${(props) => props.width && props.width};
  flex-direction: ${(props) => props.flexDirection && props.flexDirection};
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  display: ${(props) => props.flex && "flex"};
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  :hover {
    font-weight: ${(props) => props.hover && "bolder"};
  }
`;
export const LinkToNavbar = styled.a<ILink>`
  margin: 0 10px;
  cursor: pointer;
  color: ${(props) => props.selected && "#3573E0"};
  :hover {
    color: #3573e0;
  }
  border-bottom: ${(props) => props.selected && "1px solid #3573E0"};
`;
type IAbsolute = {
  size?: string;
  pointer?: boolean;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
};

export const IconAbsolute = styled.span<IAbsolute>`
  position: absolute;
  cursor: ${(props) => props.pointer && "pointer"};
  font-size: ${(props) => (props.size ? props.size : "normal")};
  top: ${(props) => props.top && props.top};
  bottom: ${(props) => props.bottom && props.bottom};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
`;

export const AuthCard = styled.form`
  width: 250px;
  height: 320px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  z-index: 10;
`;
export const AuthCardSignUp = styled.form`
  width: 250px;
  min-height: 320px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  z-index: 10;
`;
export const Card = styled.form`
  width: 250px;
  height: auto;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  z-index: 10;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url("/login.png");
  height: 100vh;
  background-size: cover;
  position: relative;
  p {
    z-index: 10;
  }
`;
export const BlackFilter = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  opacity: 0.4;
`;
export const BorderBottom = styled.span<{ color: string }>`
  padding-bottom: 10px;
  border-bottom: ${(props) => props.color && `1px solid ${props.color}`};
`;
export const BorderBottomCustom = styled.span<{ color: string }>`
  padding-bottom: 10px;
  border-bottom: ${(props) => props.color && `0.6px solid ${props.color}`};
  height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
`;

export const ContainerSelect = styled.div`
  width: 100%;
`;
