import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { GymContext } from "../../../../context/GymContext";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { BorderBottom, Text, Title } from "../../../../genericComponents";
import ModalInscriptors from "../../../../components/ModalInscriptors";

const Button = styled.button<{ reversed?: boolean }>`
  color: ${(props) => (props.reversed ? "#000" : "#fff")};
  background-color: ${(props) => (props.reversed ? "#fff" : "#000")};
  padding: 0 20px;

  opacity: ${(props) => props.disabled && 0.6};
  width: auto;
  margin: 0 auto;
  margin-top: 20px;
`;
const EventsColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  input {
    margin-bottom: 20px;
  }
`;
const Card = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  min-height: 200px;
  padding: 0 0 0 0;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 1px 1px 5px 0px #0000006b;
  span {
    padding: 0;
  }
`;
const Image = styled.div<{ src: string }>`
  background: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-position: center;

  width: 40%;
  height: auto;
`;
const CardText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding: 0 20px;
`;

const Event = ({event, admin, userInTheEvent}) => {
  const { events, deleteEvent, updateGymStatus, deleteUserToEvent } = useContext(GymContext);
  const { user } = useContext(FirebaseContext);

  const [showUsers, setShowUsers] = useState(false);
  const addToEvent = async (id: string) => {
    await updateGymStatus(id, user.id);
  };

  const deleteToEvent = async (id: string) => {
    await deleteUserToEvent(id, user.id);
  };
  const renderDates = (event) => {
    const datesAvaiable = [];
    Object.keys(event.weekDays).forEach((dataWeekDays) => {
      if (event.weekDays[dataWeekDays].checked) {
        datesAvaiable.push({
          label: dataWeekDays,
          position: event.weekDays[dataWeekDays].position,
        });
      }
    });
    const orderedArray = datesAvaiable.sort((a, b) => a.position - b.position);
    let textToRender = "";
    if (orderedArray.length > 1) {
      orderedArray.forEach((weekDay: any, i: number) => {
        if (i === orderedArray.length - 1) {
          textToRender = `${textToRender} y ${weekDay.label}`;
        } else if (i === 0) {
          textToRender = `${weekDay.label}`;
        } else {
          textToRender = `${textToRender}, ${weekDay.label}`;
        }
      });
    } else {
      textToRender = orderedArray[0].label;
    }
    return textToRender;
  };

  return (
    <Card>
      <Image src={event?.image}></Image>
      <CardText>
        <Title top="20px" size="14px">
          {event?.title}
        </Title>
        <Text weight="200" top="10px" size="14px" color="#000000">
          Días: {renderDates(event)}
        </Text>
        <Text bottom="10px" weight="200" top="10px" size="14px" color="#000000">
          Hora: {event?.hourInit}–{event?.hourFinal}
        </Text>
        <BorderBottom color="#A3A2A7"></BorderBottom>

        <>
          <Text top="10px" weight="200" size="14px" color="#5B656E">
            Inscritos: {event?.participants.length} de {event?.capacity}
          </Text>
         {admin &&  <Button onClick={() => setShowUsers(true)}>Ver inscritos</Button>}
        </>
        {admin && <Button onClick={() => deleteEvent(event?.id)}>Borrar</Button>}

        {!admin && !userInTheEvent && (
          <Button disabled={userInTheEvent ? true : false || event?.capacity === event?.participants?.length} onClick={() => addToEvent(event?.id)}>
            Apuntarse
          </Button>
        )}
        {!admin && userInTheEvent && (
          <Button reversed onClick={() => deleteToEvent(event?.id)}>
            Desapuntarse
          </Button>
        )}
      </CardText>
      <ModalInscriptors open={showUsers} close={() => setShowUsers(false)} usersId={event?.participants} />
    </Card>
  );
};

export default Event;
