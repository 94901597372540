import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Text } from "../../../genericComponents";
import { useLocation, useNavigate } from "react-router";
import { es } from "date-fns/locale";
import Layout from "../../../components/Layout";
import { IBook, SalasContext } from "../../../context/SalasContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IUser } from "../../../interfaces/IUser";
import { IBusiness } from "../../../interfaces/IBusiness";
import AllBooks from "../SalaAdmin/allBooks";
const DatePickerCustom = styled(DatePicker)``;

export type IBookForBusiness = {
  userBooker: IUser;
  businessBooker: IBusiness;
  id?: string;
  salaId: string;
  bookedBy: string;
  businessId: string;
  daySelected: number;
  timeSelected: number;
};
registerLocale("es", es);

const SalaBookingContainer = styled.div`
  .day-with-event {
    color: green;
  }
`;


const DatePickerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  :first-child div {
    margin: 0 auto;
    
  }
  .react-datepicker{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  .react-datepicker__month-container{
    width: 100%;
    
  }
  .react-datepicker__day--highlighted {
    border-radius: 50%;
    background-color: #333333;
  }
  .react-datepicker__day--today {
    background-color: #2b72d5;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      input {
        width: 85%;
        margin: 0 auto;

        height: 36px;
        background: #f6f6f6;
        border: none;
        margin-bottom: 20px;

        padding-left: 4px;
      }
    }
  }
`;

const AdminPicker = () => {
  const location: any = useLocation();
  const today = new Date();
  today.setHours(today.getHours() + 1);
  today.setMinutes(0);

  const { getBooksById, books, allBooksById, getAllBooksById } =
    useContext(SalasContext);
  const { user } =
    useContext(FirebaseContext);

  const [daySelected, setDaySelected] = useState(new Date(today));

  const id = location?.state?.id;

  const navigate = useNavigate();

  const updateBooks = async () => {
    const time = new Date(daySelected);
    const hours = time.setHours(0, 0, 0, 0);
    getBooksById(id, hours);
  };


  const daysWithEvents =
    allBooksById.length > 0 &&
    allBooksById.map((book: IBook) => new Date(book.daySelected));

  useEffect(() => {
    if (id) {
      updateBooks();
    }
  }, [daySelected, id, user]);

  useEffect(() => {
    if (id && user) {
      getAllBooksById(id);
    }
  }, [id, user]);


  return (
    <Layout>
      <SalaBookingContainer>
        <Text left="25px" size="25px" onClick={() => navigate(-1)}>
          ←
        </Text>
        <Text weight="300" left="25px" align="left" size="25px">
          RESERVA
        </Text>
        <DatePickerContainer>
          <Text size="18px" align="center" weight="600" bottom="20px">
            {moment(daySelected).format("DD/MM/YYYY HH:mm")}
          </Text>
          <DatePickerCustom
            wrapperClassName="date-picker"
            selected={daySelected}
            onChange={(newValue: Date) => {
              setDaySelected(newValue);
            }}
            inline
            locale="es"
            highlightDates={daysWithEvents}
            dateFormat="MM/dd/yyyy"
          />
        </DatePickerContainer>
        <AllBooks  />
      </SalaBookingContainer>
    </Layout>
  );
};

export default AdminPicker;
