import styled from "@emotion/styled";

const Img = styled.img``;
const ContainerImg = styled.div<{ color: string; borderColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 50%;
  width: 27px;
  height: 27px;
  border: ${(props) => props.borderColor && `0.5px solid ${props.borderColor}`};
  background-color: ${(props) => props.color && props.color};
  cursor: pointer;
`;

const CircleIcon = ({ src, color, funcClick, borderColor }: { src: string; color: string; funcClick?: any; borderColor?: string }) => {
  return (
    <ContainerImg borderColor={borderColor} color={color} onClick={() => funcClick && funcClick()}>
      <Img width="auto" height={13} src={src} />
    </ContainerImg>
  );
};

export default CircleIcon;
