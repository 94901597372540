import { IDoc } from "../../context/MaintenanceContext";
import styled from "@emotion/styled";
const SelectCustom = styled.select`
  /* margin-top: 20px; */
  min-width: 100%;
  padding:10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Select = ({
  options,
  handleChange,
  ticket,
}: {
  options: { value: string; name: string }[];
  handleChange: any;
  ticket: IDoc;
}) => {
  return (
    <SelectCustom
    id="select"
      name={ticket.id}
      defaultValue={ticket.status}
      onChange={(e) => handleChange(e, ticket.id)}
    >
      {/* <option value={ticket.status}>{ticket.status === 'pending' ? 'Pendiente' : ticket.status === 'progress' ? 'En curso' : 'Cerrado'}</option> */}
      {options.map((option, idx) => {
        return (
          <option id="select" key={`${option.value}-${idx}`} value={option.value}>
            {option.name}
          </option>
        );
      })}
    </SelectCustom>
  );
};

export default Select;
