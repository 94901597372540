import styled from "@emotion/styled";
import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import CardActivitySharing from "../../../components/CardActivitySharing";
import FloatButton from "../../../components/FloatButton";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import SelectorElementActive from "../../../components/SelectorElementActive";
import { NetworkContext } from "../../../context/NetworkContext";
import { Text } from "../../../genericComponents";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const InnerContainer = styled.form`
  /* width: 100%; */
  padding: 10px;
  background-color: #f6f6f6;
`;

const Activisharing = () => {
  const navigation = useNavigate();
  const [elementActive, setElementActive] = useState(1);
  const { activitySharing, myActivitySharing } = useContext(NetworkContext);
  const firstElement = {
    text: "Todos",
    ref: 1,
  };

  const secondElement = {
    text: "Mis actividades",
    ref: 2,
  };
  const renderCardsActivitySharing = () => {
    if (activitySharing.length)
      return activitySharing.map((infoCarSharing: any) => {
        return <CardActivitySharing key={`${infoCarSharing.id}`} data={infoCarSharing} />;
      });
    else
      return (
        <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
          Todavía no hay actividades publicadas...
        </Text>
      );
  };
  const renderMyCardsActivitySharing = () => {
    if (myActivitySharing.length)
      return myActivitySharing.map((infoCarSharing: any) => {
        return <CardActivitySharing deleteButton={true} key={`${infoCarSharing.id}`} data={infoCarSharing} />;
      });
    else
      return (
        <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
          Todavía no hay actividades publicadas...
        </Text>
      );
  };

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="/profile.jpeg"
          title="ACTIVITY SHARING"
          subtitle="Encuentra actividades para tus ratos libres, consulta las que te has apuntado o modifica las publicadas por ti."
          showBackButton
        />
        <FloatButton onClick={() => navigation("/activitysharing/new")} />
        <SelectorElementActive firstElement={firstElement} secondElement={secondElement} setActiveElement={setElementActive} activeElement={elementActive} />
        {elementActive === 1 && <InnerContainer>{renderCardsActivitySharing()}</InnerContainer>}
        {elementActive === 2 && <InnerContainer>{renderMyCardsActivitySharing()}</InnerContainer>}
      </Container>
    </Layout>
  );
};

export default Activisharing;
