import styled from "@emotion/styled";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

const Container = styled.div``;

const MyCharsharing = () => {
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="/car.png"
          title="MI CARSHARING"
          subtitle="Consulta tus notificaciones y todos tus servicios."
          showBackButton
        />
      </Container>
    </Layout>
  );
};

export default MyCharsharing;
