import { useContext, useState } from "react";
import styled from "@emotion/styled";

import { SalasContext } from "../../../../context/SalasContext";
import { Text, Title } from "../../../../genericComponents";
import moment from "moment";
import { CardTicket, HeaderTicket } from "../../../Maintenance";
import ModalAccept from "../../../../components/ModalAccept";
import ModalCusstom from "../../../../components/ModalCustom";
import InputButtonWithProps from "../../../../components/InputButton";

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  input{
      margin: 10px auto;
  }
`;

const MyBooks = () => {
  const { allBusinessBooks, deleteBook } = useContext(SalasContext);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [bookToDelete, setBookToDelete] = useState(null);
  const closeModalConfirm = () => {
    setModalConfirm(false);
  };

  const handleDelete = async () => {
    setModalConfirm(false);
    setModalStatus(true);
    try {
      setMessage("Cancelando reserva...");
      await deleteBook(bookToDelete);
      setMessage("Reserva cancelada con éxito");
    } catch (error) {
      setMessage("Error cancelando la reserva, prueba de nuevo más tarde");
    } finally {
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
      }, 2000);
    }
  };
  return (
    <Container>
      {allBusinessBooks.length > 0 &&
        allBusinessBooks.sort((a,b) => a.timeSelected - b.timeSelected).map((book, idx) => {
          const timeFinal = new Date(book.timeSelected).getHours() + 1;
          const timeString = timeFinal + ":00";
          return (
            <CardTicket key={`${book.id}-idx${idx}`}>
              <HeaderTicket bg="#333333">
                <Text left="10px" size="14px" color="white" weight="200">
                  {moment(book.daySelected).format("DD/MM/YYYY")}
                </Text>
              </HeaderTicket>
              <Text
                left="10px"
                weight="200"
                top="10px"
                size="14px"
                color="#000000"
              >
                Sala: <strong>{book.salaData.name}</strong>
              </Text>
              <Text
                left="10px"
                weight="200"
                top="10px"
                size="14px"
                color="#000000"
              >
                Hora:{" "}
                <strong>
                  {moment(book.timeSelected).format("HH:mm")}–{timeString}
                </strong>
              </Text>
              <Text
                left="10px"
                weight="200"
                top="10px"
                size="14px"
                color="#000000"
              >
                Empresa: <strong>{book.businessData.name}</strong>
              </Text>
              <Text
                left="10px"
                weight="200"
                top="10px"
                size="14px"
                color="#000000"
              >
                Reservado por:{" "}
                <strong>
                  {book.userData.name}
                </strong>
              </Text>
              <ContainerButton>
                <InputButtonWithProps
                  onClick={() => {
                    setModalConfirm(true);
                    setBookToDelete(book.id);
                  }}
                  right="5px"
                  type="button"
                  text="Cancelar"
                  top="20px"
                />
              </ContainerButton>
            </CardTicket>
          );
        })}
      <ModalAccept
        text={`¿Seguro que quieres cancelar tu reserva?`}
        close={closeModalConfirm}
        clearFunct={() => setModalConfirm(false)}
        open={modalConfirm}
        resetFunct={handleDelete}
      />
      <ModalCusstom message={message} open={modalStatus} />
    </Container>
  );
};

export default MyBooks;
