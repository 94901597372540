export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#fff",
    boxShadow: 24,
    borderRadius: "0px",
    p: 4,
  };