import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Text } from "../../../genericComponents";
import { useLocation, useNavigate } from "react-router";
import { es } from "date-fns/locale";
import Layout from "../../../components/Layout";
import { IBook, SalasContext } from "../../../context/SalasContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { BusinessContext } from "../../../context/BusinessContext";
import { IUser } from "../../../interfaces/IUser";
import { IBusiness } from "../../../interfaces/IBusiness";
import ModalCusstom from "../../../components/ModalCustom";
import SelectorElementActive from "../../../components/SelectorElementActive";

export type IBookForBusiness = {
  userBooker: IUser;
  businessBooker: IBusiness;
  id?: string;
  salaId: string;
  bookedBy: string;
  businessId: string;
  daySelected: number;
  timeSelected: number;
};
registerLocale("es", es);

const SalaBookingContainer = styled.div``;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  button {
    width: auto;
    padding: 5px 20px;
  }
`;

const Button = styled.button`
  width: 80%;
  margin: 0 auto;
  padding: 0 20px;
  color: #fff;
  background-color: #000;

  :disabled {
    opacity: 0.5;
  }
`;

const DatePickerContainer = styled.div<{ hoursSel: any }>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  :first-child div {
    margin: 0 auto;
  }
  .react-datepicker {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__day--highlighted {
    border-radius: 50%;
    background-color: #333333;
  }
  .react-datepicker__day--today {
    background-color: #2b72d5;
    border-radius: 50%;
    color: #fff;
  }
  .react-datepicker__time-list-item--selected{
    background-color: #ffff !important;
    color: #000 !important;
    font-weight:normal !important;
  }
  .react-datepicker__time-list-item{
    background-color: none ;
  }

  
  .react-datepicker__time-list-item--disabled {
    display: none !important;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
  }

  .selected-time{
    background-color: #2e73ab!important;
  }
  .react-datepicker-wrapper 
    .react-datepicker__input-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      input {
        width: 85%;
        margin: 0 auto;

        height: 36px;
        background: #f6f6f6;
        border: none;
        margin-bottom: 20px;

        padding-left: 4px;
      }
    }
  }
`;

const SalaPicker = () => {
  const location: any = useLocation();
  const navigation = useNavigate();
  const today = new Date();
  const todayMinDate = new Date();
  today.setHours(today.getHours() + 1);
  today.setMinutes(0);
  const todayTime = new Date();
  todayTime.setHours(23, 59, 0, 0);
  const dateToCheckToday = new Date();
  dateToCheckToday.setHours(0, 0, 0, 0);
  const todayMin = new Date(todayMinDate.setHours(todayMinDate.getHours() - 1));

  const { addBook, getBooksById, books, checkBooks } = useContext(SalasContext);
  const { user } = useContext(FirebaseContext);

  const [daySelected, setDaySelected] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [hoursSelected, setHoursSelected] = useState([]);
  const [daySelectedToCompare, setDaySelectedToCompare] = useState(new Date(today));
  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const id = location?.state?.id;

  const navigate = useNavigate();

  const updateBooks = async () => {
    const time = new Date(daySelected);
    const hours = time.setHours(0, 0, 0, 0);
    getBooksById(id, hours);
  };

 
  const booksForDay = books.length > 0 && books.map((book: IBook) => new Date(book.timeSelected));

  useEffect(() => {
    if (id) {
      updateBooks();
    }
  }, [daySelected, id]);

  const changeSelectedDate = (newDate: Date) => {
    const date = new Date(newDate);
    const dateToCompare = new Date(newDate);
    if (moment(dateToCompare).startOf("day").isSame(moment(daySelectedToCompare).startOf("day"))) {
    } else {
      date.setHours(0, 0, 0, 0);
      dateToCompare.setHours(0, 0, 0, 0);
    }

    setDaySelected(date);
    if (moment(dateToCompare).startOf("day").isSame(moment(daySelectedToCompare).startOf("day"))) {
      if (hoursSelected.find((dateIn) => moment(dateIn).startOf("hour").isSame(moment(date).startOf("hour")))) {
        const newHours = hoursSelected.filter((dateIn) => !moment(dateIn).startOf("hour").isSame(moment(date).startOf("hour")));
        setHoursSelected(newHours);
      } else {
        hoursSelected.push(date);
        setHoursSelected(hoursSelected);
      }
    }

    setDaySelectedToCompare(dateToCompare);
    setFirstLoad(false);
  };

  const checkDisabledButton = () => {
    if (!daySelected || firstLoad || moment(daySelected).get("hour") === 0 || hoursSelected?.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Layout>
      <SalaBookingContainer>
        <Text left="25px" size="25px" onClick={() => navigate(-1)}>
          ←
        </Text>
        <Text weight="300" left="25px" align="left" size="25px">
          RESERVA
        </Text>
        <DatePickerContainer hoursSel={hoursSelected}>
          <Text size="18px" align="center" weight="600" bottom="20px">
            {daySelected ? moment(daySelected).format("DD/MM/YYYY") : "Selecciona una fecha"}
          </Text>
          <DatePicker
            wrapperClassName="date-picker"
            onChange={(newValue: Date) => {
              changeSelectedDate(newValue);
            }}
            minDate={new Date()}
            inline
            minTime={dateToCheckToday.getTime() === daySelectedToCompare.setHours(0, 0, 0, 0) ? todayMin : new Date(daySelectedToCompare.setHours(0, 0, 0, 0))}
            maxTime={todayTime}
            showTimeSelect
            shouldCloseOnSelect={false}
            locale="es"
            timeClassName={(date) => {
              const dateToClass = new Date(date);
              const isSelected = hoursSelected.find((dateIn) => moment(dateIn).startOf("hour").isSame(moment(dateToClass).startOf("hour")));
              if (isSelected) {
                return "selected-time";
              }
            }}
            selected={daySelected}
            timeInputLabel="Hora:"
            timeIntervals={60}
            excludeTimes={booksForDay}
            dateFormat="MM/dd/yyyy"
            timeCaption="Hora"
            timeFormat="HH:mm"
          />
        </DatePickerContainer>
        {hoursSelected?.length > 0 ? <p style={{ width: "90%", margin: "0 auto" }}>Horas Seleccionadas:</p> : <p style={{ width: "90%", margin: "0 auto" }}>Ninguna hora seleccionada...</p>}
        <div style={{ display: "flex", justifyContent: "start", width: "90%", margin: "20px auto" }}>
          {hoursSelected
            .sort((a, b) => a - b)
            .map((time) => {
              return <p style={{ marginLeft: "10px" }}> {moment(time).format("HH:mm")}</p>;
            })}
        </div>
        <ButtonContainer>
          <Button
            disabled={checkDisabledButton()}
            onClick={async () => {
              try {
                setMessage("Reservando sala...");
                hoursSelected.map(async (date) => {
                  const date1 = new Date(date);
                  const date2 = new Date(date);
                  date2.setSeconds(0, 0);
                  setModalStatus(true);

                  const daySelected = date1.setHours(0, 0, 0, 0);
                  const timeSelected = date2.getTime();
                  // const isAvailable = await checkBooks(date1.setHours(0, 0, 0, 0), date2.getTime());
                  const isAvailable = await checkBooks(daySelected, timeSelected, id);
                  if (isAvailable) {
                    await addBook({
                      salaId: id,
                      bookedBy: user.id,
                      businessId: user.businessId,
                      daySelected: date1.setHours(0, 0, 0, 0),
                      timeSelected: date2.getTime(),
                    });
                    id !== "VCrxKEI55awpqy4OcFMR" ? setMessage("Reservado con éxito") : setMessage("Reservado con éxito, para acceder solicita la llave en recepción");
                  } else {
                    setMessage("Alguna de las horas seleccionadas ya ha sido reservada, por favor recarga la página y vuelve a intentarlo.");
                  }
                });
              } catch (error) {
                setMessage("Error reservando sala, prueba de nuevo más tarde");
              } finally {
                setTimeout(() => {
                  setMessage("");
                  setModalStatus(false);
                  navigation("/salas");
                }, 4000);
              }
            }}
          >
            Reservar
          </Button>
        </ButtonContainer>
        <ModalCusstom message={message} open={modalStatus} />
      </SalaBookingContainer>
    </Layout>
  );
};

export default SalaPicker;
