import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import InputRadioButton from "../../../../components/InputRadioButton";
import InputWithLegend from "../../../../components/InputWithLegend";
import Layout from "../../../../components/Layout";
import SectionHeader from "../../../../components/SectionHeader";
import BackIcon from "../../../../icons/services/backIcon";
import CalendarIcon from "../../../../icons/services/calendarIcon";
import { Text } from "../../../../genericComponents";
import InputCheckbox from "../../../../components/InputCheckbox";
import UserIcon from "../../../../icons/services/userIcon";
import CarIcon from "../../../../icons/services/carIcon";
import ContactIcon from "../../../../icons/services/contactIcon";
import TextAreaWithLegend from "../../../../components/TextAreaWithLegend";
import InputButtonWithProps from "../../../../components/InputButton";
import SectionFooter from "../../../../components/SectionFooter";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { addDoc, collection } from "firebase/firestore";
import ModalCusstom from "../../../../components/ModalCustom";
import { useNavigate } from "react-router";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  p {
    margin-left: 5px;
  }
`;

const InnerContainer = styled.form`
  margin: 20px 25px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;

const NewCarSharing = () => {
  const { db, user } = useContext(FirebaseContext);
  const navigation = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [newCarSharing, setNewCarSharing] = useState({
    origin: "",
    destination: "",

    weekDays: {
      Lunes: {
        position: 1,
        checked: false,
      },
      Martes: {
        position: 2,
        checked: false,
      },
      Miércoles: {
        position: 3,
        checked: false,
      },
      Jueves: {
        position: 4,
        checked: false,
      },
      Viernes: {
        position: 5,
        checked: false,
      },
      Sábado: {
        position: 6,
        checked: false,
      },
      Domingo: {
        position: 7,
        checked: false,
      },
    },
    repeatWeekly: false,
    hour: "",
    places: "",
    carModel: "",
    carColor: "",
    contact: "",
    comments: "",
  });

  const [disableButton, setDisableButton] = useState(true);

  const handleChange = (e) => {
    setNewCarSharing({ ...newCarSharing, [e.target.name]: e.target.value });
  };

  const clickCheckbox = (e) => {
    setNewCarSharing({
      ...newCarSharing,
      repeatWeekly: !newCarSharing.repeatWeekly,
    });
  };

  const selectWeekDay = (e) => {
    setNewCarSharing({
      ...newCarSharing,
      weekDays: {
        ...newCarSharing.weekDays,
        [e.target.name]: {
          ...newCarSharing.weekDays[e.target.name],
          checked: !newCarSharing.weekDays[e.target.name].checked,
        },
      },
    });
  };

  const renderWeeklyCheckbox = () => {
    return Object.keys(newCarSharing.weekDays).map(
      (weekDay: string, i: number) => {
        return (
          <InputCheckbox
            key={`${i}-${weekDay}`}
            text={weekDay}
            id={weekDay}
            name={weekDay}
            onChange={selectWeekDay}
            checked={newCarSharing.weekDays[weekDay].checked}
          />
        );
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "carsharing"), {
        userId: user.id,
        details: newCarSharing,
        participants: [],
      });
      setOpenModal(true);
      setModalMessage("Anuncio creado correctamente.");
    } catch (error) {
      setOpenModal(true);
      setModalMessage("Ha habido un error, inténtelo de nuevo más tarde.");
    } finally {
      setTimeout(() => {
        setOpenModal(false);
        setModalMessage("");
        navigation("/carsharing");
      }, 3000);
    }
  };

  useEffect(() => {
    if (
      newCarSharing.origin &&
      newCarSharing.destination &&
      newCarSharing.hour &&
      newCarSharing.places &&
      newCarSharing.carModel &&
      newCarSharing.carColor &&
      newCarSharing.contact
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [newCarSharing]);

  return (
    <Layout>
      <Container>
        <SectionHeader title="PUBLICAR VIAJE" showBackButton={true} />
        <InnerContainer onSubmit={handleSubmit}>
          <InputWithLegend
            preIcon={<BackIcon />}
            title="Origen*"
            name="origin"
            type="text"
            value={newCarSharing.origin}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<BackIcon />}
            title="Destino*"
            name="destination"
            type="text"
            value={newCarSharing.destination}
            onChange={handleChange}
          />
          <ContainerText>
            <CalendarIcon />
            <Text size="14px" top="10px" bottom="5px">
              Seleccionar día:*
            </Text>
          </ContainerText>
          {renderWeeklyCheckbox()}
          <Text left="20px">¿Quieres hacer el viaje periódico?</Text>
          <InputCheckbox
            text="Repetir semanalmente"
            id="repeatWeekly"
            name="repeatWeekly"
            onChange={clickCheckbox}
            checked={newCarSharing.repeatWeekly}
          />
          <InputWithLegend
            preIcon={<CalendarIcon />}
            title="Hora*"
            name="hour"
            type="time"
            value={newCarSharing.hour}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<UserIcon />}
            title="Plazas disponibles*"
            name="places"
            type="number"
            value={newCarSharing.places}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<CarIcon />}
            title="Modelo del coche*"
            name="carModel"
            type="text"
            value={newCarSharing.carModel}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<CarIcon />}
            title="Color del coche*"
            name="carColor"
            type="text"
            value={newCarSharing.carColor}
            onChange={handleChange}
          />
          <InputWithLegend
            preIcon={<ContactIcon />}
            title="Contacto*"
            name="contact"
            type="text"
            value={newCarSharing.contact}
            onChange={handleChange}
          />
          <TextAreaWithLegend
            title="Comentarios a tener en cuenta."
            subtitle="Aquí puedes indicar el punto de recogida o el precio del viaje."
            legendColor="#5B656E"
            legendSubtitleColor="#5F5F5F"
            name="comments"
            value={newCarSharing.comments}
            onChange={handleChange}
          />
          <ContainerButton>
            <InputButtonWithProps
              disabled={disableButton}
              type="submit"
              border="none"
              text="Publicar viaje"
            />
          </ContainerButton>
        </InnerContainer>
        <SectionFooter src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fnetwork%2Fpie_network.jpg?alt=media&token=536a2fff-50a1-4ddf-8dc5-fdeca59294f5" />
      </Container>
      <ModalCusstom open={openModal} message={modalMessage} />
    </Layout>
  );
};

export default NewCarSharing;
