import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router";
import { Text } from "../../genericComponents";
import { Link } from "react-router-dom";

export const ContainerInvisible = styled.div`
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: hidden;
`;
export const ContainerGeneral = styled.div`
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 40px;
`;
export const ContainerModal = styled.div`
  flex-direction: column;
  display: flex;
  width: 90%;
  margin: 0 auto;
  @media only screen and (min-width: 800px) {
    width: 70%;
    flex-direction: row;
    align-items: center;
  }
`;
export const ContainerTexts = styled.div`
  @media only screen and (min-width: 800px) {
    margin-right: 35px;
  }

  margin: 0 auto;
`;
export const ContainerButton = styled.div`
  margin: 20px 0px;

  button {
    background-color: black;
    font-size: 14px;
    font-weight: 300;
    padding: 5px 40px;
    color: white;
    border: none;
  }

  @media only screen and (min-width: 800px) {
    margin: unset;
  }
`;

type IModalProps = { setEditCookies: React.Dispatch<React.SetStateAction<boolean>>; setShowPopup: React.Dispatch<React.SetStateAction<boolean>> };

const CookiesModal = ({ setShowPopup, setEditCookies }: IModalProps) => {
  const [cookies, setCookie] = useCookies<any>(["POPUP_COOKIES"]);
  const location = useLocation();
  const handleSubmit = (e) => {
    e.preventDefault();
    var oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    setCookie("HIDE_POPUP_COOKIES", true, {
      expires: oneYearFromNow,
    });
    setShowPopup(false);
    setEditCookies(false);
  };

  return (
    <>
      {location.pathname !== "/privacity" && location.pathname !== "/cookies-policy" && (
        <ContainerInvisible>
          <ContainerGeneral>
            <ContainerModal>
              <ContainerTexts>
                <Text size="14px" weight="700">
                  Política de cookies
                </Text>
                <Text size="14px" weight="300" top="10px">
                  Esta web sólo utiliza cookies técnicas tanto propias como de terceros para el correcto funcionamiento de la misma y proporcionar una mejor experiencia de usuario.
                </Text>

                <Text size="14px" weight="300" top="10px">
                  Puede cambiar sus preferencias de cookies en cualquier momento. Para obtener más información al respecto, consulte nuestra <Link to="/cookies-policy"> Política de Cookies</Link> y{" "}
                  <Link to="/privacity">De Privacidad. </Link>
                </Text>
              </ContainerTexts>
              <ContainerButton>
                <button onClick={handleSubmit}>Aceptar</button>
              </ContainerButton>
            </ContainerModal>
          </ContainerGeneral>
        </ContainerInvisible>
      )}
    </>
  );
};

export default CookiesModal;
