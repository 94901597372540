import styled from "@emotion/styled";
import CardWithImages from "../../components/CardWithImages";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { CardRow } from "../Services";

const Container = styled.div`
  padding-bottom: 150px;
`;

const Bmc = () => {
  const services = [
    {
      title: "SOBRE BMC",
      subtitle: "Ver",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fdistribuidor%2Fdes_sobreBMC.jpg?alt=media&token=7362b96f-76e0-4d73-aafd-5a003c70c345",
      route: "/about-bmc",
    },
    {
      title: "CONTACTO",
      subtitle: "Consultar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fdistribuidor%2Fdes_contacto.jpg?alt=media&token=26da0be3-3ebe-49ba-9009-c8fa53c455c5",
      route: "/contact",
    },
    {
      title: "EVENTOS BMC",
      subtitle: "Consultar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fdistribuidor%2Fdes_eventos.jpg?alt=media&token=30ecc821-7a40-4c32-a017-acb16455e436",
      route: "/bmc-events",
    },
  ];
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fdistribuidor%2Fcabecera_bmc.jpg?alt=media&token=d3731f24-2025-46eb-b513-92dc468d0aa2"
          title="BMC"
          subtitle="BMC es mucho más que un edificio de oficinas. Descubre todo lo que podemos hacer por ti."
          
        />
        <CardRow>
          {services.map((service, i) => {
            return (
              <CardWithImages key={`id-${service.route}-${i}`} item={service} />
            );
          })}
        </CardRow>
      </Container>
    </Layout>
  );
};

export default Bmc;
