const BackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.036"
      height="11.974"
      viewBox="0 0 18.036 11.974"
    >
      <path
        id="keyboard_backspace_FILL0_wght400_GRAD0_opsz48"
        d="M5.987,11.974,0,5.987,5.987,0,7.064,1.077,2.906,5.235h15.13v1.5H2.906L7.064,10.9Z"
        transform="translate(18.036 11.974) rotate(180)"
        fill="#5B656E"
      />
    </svg>
  );
};

export default BackIcon;
