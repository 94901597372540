import styled from "@emotion/styled";
import { List } from "@mui/material";
import React, { useContext } from "react";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { SliderContext } from "../../../../context/SliderContext";
import { Text } from "../../../../genericComponents";
import { ListItemCustom } from "../../../BusinessPanel";

const ContainerEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 15px;
`;
const Button = styled.button<{ reversed?: boolean }>`
  color: ${(props) => (props.reversed ? "#000" : "#fff")};
  background-color: ${(props) => (props.reversed ? "#fff" : "#000")};
  padding: 0 20px;

  opacity: ${(props) => props.disabled && 0.6};
  width: auto;
  margin: 0 auto;
  margin-top: 20px;
`;
const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ActiveEvents = () => {
  const { user } = useContext(FirebaseContext);
  const { slidesEvents, deleteItemToSlider } = useContext(SliderContext);
  const lobbyEvents = slidesEvents?.filter((event) => event.type === "offer" || event.type === "lobby");
  const bmcEvents = slidesEvents?.filter((event) => event.type === "eventBmc");
  const businessEvents = slidesEvents?.filter((event) => event.type === "business");

  return (
    <ContainerEvents>
      <List>
        {user?.roles?.super &&
          bmcEvents?.length > 0 &&
          bmcEvents?.map((event) => {
            return (
              <ListItemCustom>
                <ContainerItem>
                  <Text>{event?.title}</Text>
                  <Button onClick={() => deleteItemToSlider(event?.id)}>Dejar de mostrar</Button>
                </ContainerItem>
              </ListItemCustom>
            );
          })}
        {user?.roles?.canteen &&
          lobbyEvents?.length > 0 &&
          lobbyEvents?.map((event) => {
            return (
              <ListItemCustom>
                <ContainerItem>
                  <Text>{event?.title}</Text>

                  <Button onClick={() => deleteItemToSlider(event?.id)}>Dejar de mostrar</Button>
                </ContainerItem>
              </ListItemCustom>
            );
          })}{" "}
        {user?.roles?.super &&
          businessEvents?.length > 0 &&
          businessEvents?.map((event) => {
            return (
              <ListItemCustom>
                <ContainerItem>
                  <Text>{event?.title}</Text>

                  <Button onClick={() => deleteItemToSlider(event?.id)}>Dejar de mostrar</Button>
                </ContainerItem>
              </ListItemCustom>
            );
          })}{" "}
        {slidesEvents.length <= 0 && (
          <Text align="left" width="50%">
            No hay eventos activos...
          </Text>
        )}
      </List>
    </ContainerEvents>
  );
};

export default ActiveEvents;
