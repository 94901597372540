import styled from "@emotion/styled";

import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import InputButtonWithProps from "../../../components/InputButton";
import InputWithLegend from "../../../components/InputWithLegend";
import Layout from "../../../components/Layout";
import ModalCusstom from "../../../components/ModalCustom";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { GymContext } from "../../../context/GymContext";
import { Text, Title } from "../../../genericComponents";
import DatePickerCustom from "../../../components/DatePicker";
import InputCheckbox from "../../../components/InputCheckbox";
import CalendarIcon from "../../../icons/services/calendarIcon";
import { ImageCircle } from "../../Canteen/newOffer";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const ContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  p {
    margin-left: 5px;
  }
`;
const ContainerDiv = styled.div`
  padding: 15px;
`;
const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
`;
const InnerContainer = styled.form`
  margin: 20px 25px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;
const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const Invisible = styled.div`
  display: none;
`;
const InputInvisible = styled.input`
  display: flex;
`;

const NewGymEvent = () => {
  const navigation = useNavigate();
  const [event, setEvent] = useState({
    weekDays: {
      Lunes: {
        position: 1,
        checked: false,
      },
      Martes: {
        position: 2,
        checked: false,
      },
      Miércoles: {
        position: 3,
        checked: false,
      },
      Jueves: {
        position: 4,
        checked: false,
      },
      Viernes: {
        position: 5,
        checked: false,
      },
      Sábado: {
        position: 6,
        checked: false,
      },
      Domingo: {
        position: 7,
        checked: false,
      },
    },
    timestamp: null,
    capacity: null,
    image: null,
    title: "",
    hourInit: "",
    hourFinal: "",
    place: "",
    participants: [],
  });
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [message, setMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);
  const { uploadImage } = useContext(FirebaseContext);
  const { addEvent } = useContext(GymContext);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    switch (e.target.name) {
      case "timestamp":
        const date = new Date(e.target.value).getTime();
        setEvent({ ...event, timestamp: date });
        break;
      case "image":
        setImage(e.target.files[0]);
        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      default:
        setEvent({ ...event, [e.target.name]: e.target.value });
        break;
    }
  };

  const selectWeekDay = (e) => {
    setEvent({
      ...event,
      weekDays: {
        ...event.weekDays,
        [e.target.name]: {
          ...event.weekDays[e.target.name],
          checked: !event.weekDays[e.target.name].checked,
        },
      },
    });
  };

  const renderWeeklyCheckbox = () => {
    return Object.keys(event.weekDays).map((weekDay: string, i: number) => {
      return <InputCheckbox key={`${i}-${weekDay}`} text={weekDay} id={weekDay} name={weekDay} onChange={selectWeekDay} checked={event.weekDays[weekDay].checked} />;
    });
  };

  const addNewEvent = async () => {
    setModalOpen(true);
    setMessage("Creando evento...");
    try {
      const url = await uploadImage(image);

      const eventData = { ...event, image: url as string };
      await addEvent(eventData);
    } catch (error) {
      setModalOpen(true);
      setMessage("Error creando evento, prueba de nuevo más tarde.");
    } finally {
      setTimeout(() => {
        setMessage("Evento creado con éxito.");
        setModalOpen(true);
        setMessage("");
        navigation("/gym-admin");
      }, 2000);
    }
  };

  const checkDaysSelected = () => {
    return Object.keys(event.weekDays).some((day) => event.weekDays[day].checked);
  };

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Text top="0" weight="100" size="24px" onClick={() => navigation(-1)}>
            ←
          </Text>
          <Title size="25px" weight="200" top="10px">
            NUEVO EVENTO
          </Title>
        </TitleContainer>
        <InnerContainer>
          <InputWithLegend name="title" type="text" legendColor="#5F5F5F" onChange={handleChange} title="Actividad*" />

          <ContainerText>
            <CalendarIcon />
            <Text size="14px" top="10px" bottom="5px">
              Seleccionar día:*
            </Text>
          </ContainerText>
          {renderWeeklyCheckbox()}
          <InputWithLegend preIcon={<CalendarIcon />} title="Hora inicio*" name="hourInit" type="time" value={event.hourInit} onChange={handleChange} />
          <InputWithLegend preIcon={<CalendarIcon />} title="Hora fin*" name="hourFinal" type="time" value={event.hourFinal} onChange={handleChange} />

          <ContainerDiv>
            <Text left="0px" color="#5F5F5F" size="14px" top="10px" bottom="10px">
              Imagen del evento*
            </Text>
            <ImageCircle
              imagePreview={preview ? preview : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/default_img.jpeg?alt=media&token=860e4b3e-fe68-4359-9e90-1e7a79fc7a1d"}
              onClick={() => document.getElementById("image").click()}
            >
              {!image && !preview && <Image src="/upload.png" />}
            </ImageCircle>
          </ContainerDiv>
          <Invisible>
            <InputInvisible name="image" type="file" id="image" onChange={handleChange} title="Imagen del evento*" />
          </Invisible>
          <InputWithLegend name="capacity" type="number" legendColor="#5F5F5F" onChange={handleChange} title="Participantes*" />
          <BtnContainer>
            <InputButtonWithProps
              left="auto"
              right="auto"
              text="Crear evento"
              disabled={!image || !event.title || !event.capacity || !event.hourInit || !event.hourFinal || !checkDaysSelected()}
              onClick={() => addNewEvent()}
            />
          </BtnContainer>
          <Text top="20px" bottom="20px" weight="200" size="10px">
            Todos los campos marcados con * son obligatorios.
          </Text>
        </InnerContainer>
        <ModalCusstom message={message} open={modalOpen} />
      </Container>
    </Layout>
  );
};

export default NewGymEvent;
