import styled from "@emotion/styled";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import GymAdmin from "../GymAdmin";


const GymContainer = styled.div`
  padding-bottom: 150px;
`;

const AdminGym = () => {

  return (
    <Layout>
      <GymContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fcabecera_gimnasio.jpg?alt=media&token=0ae03f8c-bfd4-418d-91a7-bfe3241085de"
          title="GIMNASIO"
          subtitle="Descubre todas las actividades que tenemos preparadas para que cuides tu cuerpo sin tener que salir del edificio."
          showBackButton
        />
         <GymAdmin />
      </GymContainer>
    </Layout>
  );
};

export default AdminGym;
