const CarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.359"
      height="18.097"
      viewBox="0 0 20.359 18.097"
    >
      <path
        id="directions_car_FILL0_wght400_GRAD0_opsz48"
        d="M8.262,25.722v1.527a.822.822,0,0,1-.848.848H6.848A.822.822,0,0,1,6,27.249V18.087l2.4-7.239a1.246,1.246,0,0,1,.467-.622A1.28,1.28,0,0,1,9.619,10H22.74a1.28,1.28,0,0,1,.749.226,1.246,1.246,0,0,1,.467.622l2.4,7.239v9.162a.822.822,0,0,1-.848.848h-.594a.778.778,0,0,1-.594-.24.852.852,0,0,1-.226-.608V25.722Zm.085-9.331H24.012L22.457,11.7H9.9Zm-.65,1.7v0Zm3,4.524a1.454,1.454,0,0,0,1.089-.452,1.492,1.492,0,0,0,.438-1.075,1.574,1.574,0,0,0-.438-1.117,1.433,1.433,0,0,0-1.089-.467A1.593,1.593,0,0,0,9.11,21.085a1.433,1.433,0,0,0,.467,1.089A1.574,1.574,0,0,0,10.694,22.611Zm11,0a1.548,1.548,0,0,0,1.117-.452,1.446,1.446,0,0,0,.467-1.075A1.593,1.593,0,0,0,21.694,19.5a1.433,1.433,0,0,0-1.089.467,1.574,1.574,0,0,0-.438,1.117,1.454,1.454,0,0,0,.452,1.089A1.492,1.492,0,0,0,21.694,22.611Zm-14,1.414H24.663V18.087H7.7Z"
        transform="translate(-6 -10)"
        fill="#5B656E"
      />
    </svg>
  );
};
export default CarIcon;
