import { useContext } from "react";
import { IDoc } from "../../context/MaintenanceContext";
import { Text } from "../../genericComponents";

import "moment/locale/es";

import { CardsColumn, CardTicket, HeaderTicket, TextTicket } from "../../pages/Maintenance";
import { packageStatusPhrase, ticketColorStatus } from "../../utils/functions";
import Select from "../Select";
import moment from "moment";
import { IDocB, ParcelContext } from "../../context/ParcelContext";
moment.locale("es");

const PackagesDisplayer = ({ tickets, admin }: { tickets: IDoc[] | IDocB[]; admin?: boolean }) => {
  const { updateTicketStatus } = useContext(ParcelContext);

  const handleChange = async (e, id: string) => {
    await updateTicketStatus(e.target.value, id);
  };
  return (
    <CardsColumn>
      {tickets?.length > 0 &&
        tickets.map((ticket, idx) => {
          return (
            <CardTicket key={`${ticket.id}-idx${idx}`}>
              <HeaderTicket bg={ticketColorStatus(ticket.status)}>
                <Text left="10px" size="14px" color="white" weight="200">
                  {moment(ticket.timestamp).format("DD/MM/YYYY")}
                </Text>
              </HeaderTicket>
              <TextTicket>
                <Text top="5px" size="14px" weight="200">
                  - {ticket.reason}
                </Text>
                <Text top="5px" size="14px" weight="200">
                  - Hora llegada: <strong>{moment(ticket.timestamp).format("HH:mm")}</strong>
                </Text>
                {ticket.status === "closed" && ticket?.lastUpdate && (
                  <Text top="5px" size="14px" weight="200">
                    - Hora entrega: <strong>{moment(ticket?.lastUpdate).format("HH:mm")}</strong>
                  </Text>
                )}
                {admin ? (
                  <Text top="5px" size="14px" weight="200">
                    - Cambiar estado:
                  </Text>
                ) : (
                  <Text top="5px" size="14px" weight="200">
                    - Estado: <strong>{packageStatusPhrase(ticket.status)}</strong>
                  </Text>
                )}
                <Text top="5px" size="14px" weight="200">
                  - Comentarios: <strong>{ticket.comments}</strong>
                </Text>

                {admin && (
                  <Select
                    options={[
                      { value: "pending", name: "Pendiente de recogida" },
                      { value: "closed", name: "Paquete recogido" },
                    ]}
                    ticket={ticket}
                    handleChange={handleChange}
                  />
                )}
              </TextTicket>
            </CardTicket>
          );
        })}
    </CardsColumn>
  );
};

export default PackagesDisplayer;
