import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

import styled from "@emotion/styled";
import FloatButton from "../../../components/FloatButton";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { EventsContext, IEventsBmc } from "../../../context/EventsContext";
import ModalAccept from "../../../components/ModalAccept";
import ModalCusstom from "../../../components/ModalCustom";
import EventCardAdmin from "../../../components/EventCardAdmin";
import { Text } from "../../../genericComponents";

const Container = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
`;
const ContainerEvents = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 150px;
`;

const EventsWebBMC = () => {
  const navigation = useNavigate();
  const { eventsWebBmc, deleteEventWebBmc } = useContext(EventsContext);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [message, setMessage] = useState("");
  const [modalOpenAccept, setModalOpenAccept] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpenAccept(false);
  };
  const clearSelections = () => {
    setSelectedDoc(null);
  };
  const showModal = (doc: IEventsBmc) => {
    setSelectedDoc(doc);
    setModalOpenAccept(true);
  };

  const deleteDoc = async () => {
    setModalOpenAccept(false);
    try {
      setModalOpen(true);
      setMessage("Borrando evento...");
      await deleteEventWebBmc(selectedDoc.id);
      setMessage("Evento borrado con éxito");
    } catch (error) {
      setModalOpen(true);
      setMessage("Error borrando evento, prueba de nuevo más tarde");
    } finally {
      setTimeout(() => {
        setMessage("");
        setModalOpen(false);
        clearSelections();
      }, 2000);
    }
  };

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fcabecera_eventosBMC.jpg?alt=media&token=4a054293-28af-4ba7-8e84-46266a4c3025"
          title="EVENTOS WEB BMC"
          subtitle="Crea, edita o elimina eventos para la web generados por la administración de BMC."
          showBackButton
        />
        <FloatButton
          onClick={() => {
            navigation("/new-event-bmc-web");
          }}
        />
        <ContainerEvents>
          {eventsWebBmc?.length > 0 ? (
            eventsWebBmc.map((doc, idx) => {
              return (
                <EventCardAdmin
                  deleteFunct={() => {
                    showModal(doc);
                  }}
                  key={doc.title + idx}
                  eventData={doc}
                />
              );
            })
          ) : (
            <Text
              size="13px"
              width="80%"
              left="auto"
              right="auto"
              top="20px"
              weight="200"
              align="center"
            >
              Todavía no hay ningún evento programado. Estáte atento, ¡pronto
              tendrás noticias nuestras!
            </Text>
          )}
        </ContainerEvents>
      </Container>
      <ModalAccept
        resetFunct={() => deleteDoc()}
        clearFunct={clearSelections}
        open={modalOpenAccept}
        close={closeModal}
        text={`¿Seguro que quieres eliminar ${selectedDoc?.title}`}
      />
      <ModalCusstom message={message} open={modalOpen} />
    </Layout>
  );
};

export default EventsWebBMC;
