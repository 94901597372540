import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InputButtonWithProps from "../../../components/InputButton";
import InputWithLegend from "../../../components/InputWithLegend";
import Layout from "../../../components/Layout";
import ModalCusstom from "../../../components/ModalCustom";
import SelectBusiness from "../../../components/SelectBusiness";
import TextAreaWithLegend from "../../../components/TextAreaWithLegend";
import { BusinessContext } from "../../../context/BusinessContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { MaintenanceContext } from "../../../context/MaintenanceContext";
import {
  AuthCard,
  ContainerSelect,
  Text,
  Title,
} from "../../../genericComponents";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const CardContainer = styled.div`
  width: 100%;
  background: #f6f6f6;
  height: 100%;
  padding-top: 20px;
`;
const TitleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
`;

type ITicket = {
  localNum: string;
  reason: string;
};
const NewTicket = () => {
  const { addTicket } = useContext(MaintenanceContext);
  const { user, loadingUser } = useContext(FirebaseContext);
  const { getBusinessById } = useContext(BusinessContext);
  const navigation = useNavigate();
  const [ticket, setTicket] = useState<ITicket>({ localNum: "", reason: "" });
  const [locals, setLocals] = useState([]);
  const [message, setMessage] = useState('')
  const [modalStatus, setModalStatus] = useState(false)

  const handleChange = (e) => {
    setTicket({ ...ticket, [e.target.name]: e.target.value });
  };
  const handleChangeLocal = async (e: any) => {
    
    setTicket({
      ...ticket,

      localNum: e.value,
    });
  };
  const sendRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage('Creando ticket...')
    setModalStatus(true);

    const timestamp = new Date().getTime();
    await addTicket({
      ...ticket,
      userId: user.id,
      businessId: user.businessId,
      timestamp: timestamp,
      status: "pending",
    });
    setMessage('Ticket creado con éxito')
    setTimeout(() => {
      setMessage('')
      setModalStatus(true);
      navigation("/maintenance");
    }, 3000);
  };

  const getLocals = async () => {
    const userBusiness = await getBusinessById(user.businessId);
    const localsOptions = await Promise.all(userBusiness.locals.map((local) => {
      return { value: local, label: local };
    }));
    setLocals(localsOptions);
  };

  useEffect(() => {
    user && !loadingUser && locals.length === 0 && getLocals();
  }, [user, loadingUser]);

  

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Text top="0" weight="100" size="24px" onClick={() => navigation('/maintenance-admin')}>
            ←
          </Text>
          <Title size="25px" weight="200" top="10px">
            NUEVA CITA
          </Title>
        </TitleContainer>
        <CardContainer>
          <AuthCard
            autoComplete="off"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => sendRequest(e)}
          >
            {/* <InputWithLegend
              legendColor="#5F5F5F"
              type="text"
              name="localNum"
              title="Número de local a revisar"
              onChange={handleChange}
              width="90%"
            /> */}
            {locals.length > 0 && (
              <ContainerSelect>
                <Text color="#5F5F5F" size="14px" top="10px" bottom="5px">
                  Local*
                </Text>
                <SelectBusiness
                  options={locals}
                  handleChange={handleChangeLocal}
                  name="localNum"
                  value={ticket?.localNum}
                />
              </ContainerSelect>
            )}
            <TextAreaWithLegend
              legendColor="#5F5F5F"
              name="reason"
              title="Motivo de la solicitud"
              onChange={handleChange}
              width="100%"
            />
            <InputButtonWithProps
              type="submit"
              disabled={!ticket?.localNum || !ticket?.reason}
              top="20px"
              text="Enviar solicitud"
            />
          </AuthCard>
        </CardContainer>
        <ModalCusstom message={message} open={modalStatus} />

      </Container>
    </Layout>
  );
};

export default NewTicket;
