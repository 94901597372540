import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { FirebaseProvider } from "./context/FirebaseContext";
import { MaintenanceProvider } from "./context/MaintenanceContext";
import { GymProvider } from "./context/GymContext";
import { CanteenProvider } from "./context/CanteenContext";
import { BusinessProvider } from "./context/BusinessContext";
import { SalasProvider } from "./context/SalasContext";
import { ParcelProvider } from "./context/ParcelContext";
import { NetworkProvider } from "./context/NetworkContext";
import { EventsProvider } from "./context/EventsContext";
import { SliderProvider } from "./context/SliderContext";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <CookiesProvider>
      <FirebaseProvider>
        <BusinessProvider>
          <SalasProvider>
            <ParcelProvider>
              <MaintenanceProvider>
                <GymProvider>
                  <CanteenProvider>
                    <NetworkProvider>
                      <EventsProvider>
                        <SliderProvider>
                          <App />
                        </SliderProvider>
                      </EventsProvider>
                    </NetworkProvider>
                  </CanteenProvider>
                </GymProvider>
              </MaintenanceProvider>
            </ParcelProvider>
          </SalasProvider>
        </BusinessProvider>
      </FirebaseProvider>
    </CookiesProvider>
  </BrowserRouter>
);
