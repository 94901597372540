const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.552"
      height="23.04"
      viewBox="0 0 15.552 23.04"
    >
      <path
        id="ubicacion"
        d="M18.276,20.761A22.737,22.737,0,0,0,22.8,16.11a7.5,7.5,0,0,0,1.526-4.162,5.962,5.962,0,0,0-2.146-4.81,5.772,5.772,0,0,0-1.973-1.08,6.565,6.565,0,0,0-1.93-.331,6.565,6.565,0,0,0-1.93.331,5.772,5.772,0,0,0-1.973,1.08,5.962,5.962,0,0,0-2.146,4.81,7.5,7.5,0,0,0,1.526,4.162A22.737,22.737,0,0,0,18.276,20.761Zm0,2.189a27.019,27.019,0,0,1-5.861-5.674A9.225,9.225,0,0,1,10.5,11.949a7.927,7.927,0,0,1,.706-3.442,7.8,7.8,0,0,1,1.829-2.491A7.643,7.643,0,0,1,15.54,4.5a7.678,7.678,0,0,1,5.472,0,7.643,7.643,0,0,1,2.506,1.512,7.8,7.8,0,0,1,1.829,2.491,7.927,7.927,0,0,1,.706,3.442,9.225,9.225,0,0,1-1.915,5.328A27.019,27.019,0,0,1,18.276,22.95Zm0-9.158A2.016,2.016,0,0,0,19.7,10.35,2.016,2.016,0,0,0,16.85,13.2,1.942,1.942,0,0,0,18.276,13.792ZM10.5,27.04V25.312H26.052V27.04Zm7.776-15.091Z"
        transform="translate(-10.5 -4)"
        fill="#215f8d"
      />
    </svg>
  );
};
export default LocationIcon;
