import Layout from "../../components/Layout";
import styled from "@emotion/styled";
import SectionHeader from "../../components/SectionHeader";

import CardWithImages from "../../components/CardWithImages";

const ServicesContainer = styled.div`
  padding-bottom: 150px;
`;

export const CardRow = styled.div`
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-around;
  padding: 20px 0;
`;

const Services = () => {
  const services = [
    {
      title: "MANTENIMIENTO",
      subtitle: "Solicitar cita",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/des_mantenimiento.jpg?alt=media&token=724c90f9-d507-47b7-8019-3423cf1af87e",
      route: "/maintenance",
    },
    {
      title: "SALAS",
      subtitle: "Reservar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/admin%2Fdes_salas.jpg?alt=media&token=8145af37-09d6-48fc-ba06-db6574ee404e",
      route: "/salas",
    },
    {
      title: "PAQUETERÍA",
      subtitle: "Consultar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fdes_paqueteria.jpg?alt=media&token=568b05a1-1376-44b2-bbb9-17f03c6f34ee",
      route: "/parcel",
    },

    {
      title: "CAFETERÍA",
      subtitle: "Ver ofertas",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2FCAFETERIA.jpg?alt=media&token=f7ca548c-e0c9-498e-86d3-47894954ec48",
      route: "/canteen",
    },
    {
      title: "RESTAURANTE",
      subtitle: "Ver menú",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2FIMG_4822.jpg?alt=media&token=8275494f-f0da-4104-af7b-66d07b500379",
      route: "/restaurant",
    },
    {
      title: "GIMNASIO",
      subtitle: "Apuntarse",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fdes_gimnasio.jpg?alt=media&token=ee75cf3e-f477-4463-9f50-1abc5e0ac4aa",
      route: "/gym",
    },
  ];
  return (
    <Layout>
      <ServicesContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fcabecera_servicios.jpg?alt=media&token=749eefca-b1be-40c2-9cd9-d3fa47d346d6"
          title="SERVICIOS"
          subtitle="Todo lo que necesitas en la palma de tu mano. Aprovecha y disfruta
              de nuestros servicios exclusivos del edificio."
        />
        <CardRow>
          {services.map((service, i) => {
            return (
              <CardWithImages key={`id-${service.route}-${i}`} item={service} />
            );
          })}
        </CardRow>
      </ServicesContainer>
    </Layout>
  );
};

export default Services;
