import styled from "@emotion/styled";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

import CanteenAdmin from "../CanteenAdmin";

const CanteenContainer = styled.div`
  padding-bottom: 150px;
`;

const AdminCanteen = () => {
  return (
    <Layout>
      <CanteenContainer>
     
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/cabecera_cafeteria.jpg?alt=media&token=adaac93c-d4d1-4e6d-b659-27800b937346"
          title="CAFETERÍA"
          subtitle="¿No has desayunado? Descubre nuestras ofertas y haz que tu día empiece con buen pie."
          showBackButton
        />
        <CanteenAdmin />
      </CanteenContainer>
    </Layout>
  );
};

export default AdminCanteen;
