
const NewtworkIcon = ({fill} :{fill?:string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      
    >
      <g fill={fill && fill} id="people_alt_black_24dp" transform="translate(0 -0.341)">
        <g fill={fill && fill} id="Grupo_3" data-name="Grupo 3">
          <rect
            id="Rectángulo_85"
            data-name="Rectángulo 85"
            width="28"
            height="28"
            transform="translate(0 0.341)"
            fill="none"
          />
        </g>
        <g fill={fill && fill} id="Grupo_5" data-name="Grupo 5" transform="translate(1.181 4.723)">
          <g id="Grupo_4" data-name="Grupo 4">
            <path
              id="Trazado_3"
              data-name="Trazado 3"
              d="M16.67,13.13a5.492,5.492,0,0,1,2.751,4.57v3.543h4.723V17.7C24.145,15.126,19.929,13.6,16.67,13.13Z"
              transform="translate(1.834 -2.349)"
              fill={fill && fill}
            />
            <path
              id="Trazado_4"
              data-name="Trazado 4"
              d="M15.241,13.447a4.723,4.723,0,1,0,0-9.447,4.934,4.934,0,0,0-1.571.283,7.061,7.061,0,0,1,0,8.88A4.934,4.934,0,0,0,15.241,13.447Z"
              transform="translate(1.291 -4)"
              fill={fill && fill}
            />
            <path
              id="Trazado_5"
              data-name="Trazado 5"
              d="M9.723,13.447A4.723,4.723,0,1,0,5,8.723,4.722,4.722,0,0,0,9.723,13.447Zm0-7.085A2.362,2.362,0,1,1,7.362,8.723,2.369,2.369,0,0,1,9.723,6.362Z"
              transform="translate(-0.277 -4)"
              fill={fill && fill}
            />
            <path
              id="Trazado_6"
              data-name="Trazado 6"
              d="M10.447,13C7.294,13,1,14.582,1,17.723v3.543H19.894V17.723C19.894,14.582,13.6,13,10.447,13Zm7.085,5.9H3.362V17.735c.236-.85,3.9-2.374,7.085-2.374s6.849,1.523,7.085,2.362Z"
              transform="translate(-1 -2.372)"
              fill={fill && fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NewtworkIcon;
