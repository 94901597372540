const Segurity = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="42"
      viewBox="0 0 32 42"
    >
      <path
        id="seguridad"
        d="M24,2a9.159,9.159,0,0,1,6.725,2.775A9.159,9.159,0,0,1,33.5,11.5v4.8H37a2.988,2.988,0,0,1,3,3V41a2.988,2.988,0,0,1-3,3H11a2.988,2.988,0,0,1-3-3V19.3a2.988,2.988,0,0,1,3-3h3.5V11.5a9.159,9.159,0,0,1,2.775-6.725A9.159,9.159,0,0,1,24,2Zm0,3a6.265,6.265,0,0,0-4.6,1.9,6.265,6.265,0,0,0-1.9,4.6v4.8h13V11.5a6.265,6.265,0,0,0-1.9-4.6A6.265,6.265,0,0,0,24,5ZM11,41H37V19.3H11V41ZM24,26.3a3.565,3.565,0,0,0-2.725,1.225A3.936,3.936,0,0,0,20.15,30.25a3.572,3.572,0,0,0,1.125,2.65,3.925,3.925,0,0,0,5.45,0,3.572,3.572,0,0,0,1.125-2.65,3.936,3.936,0,0,0-1.125-2.725A3.565,3.565,0,0,0,24,26.3Zm-13-7h0V41h0V19.3Z"
        transform="translate(-8 -2)"
        fill="#f6f6f6"
      />
    </svg>
  );
};

export default Segurity;
