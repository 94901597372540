import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import { Text } from "../../genericComponents";
import BmcNavIcon from "../../icons/bmcNavIcon";
import HomeIcon from "../../icons/homeIcon";
import NewtworkIcon from "../../icons/networkIcon";
import QRIcon from "../../icons/qrIcon";
import ServicesIcon from "../../icons/servicesIcon";
import { isPlatform } from "@ionic/react";

const NavbarContainer = styled.nav<{ isIos }>`
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  box-shadow: 0px -1px 6px 0 #00000029;
  padding-bottom: ${(props) => (props.isIos ? "30px" : "10px")};
  z-index: 100;
`;
const IconsContainer = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
    color: black;
  }
`;

const IconsGroup = styled.div`
  height: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 40%;
`;
const QRIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  left: 49%;
  right: 49%;
  position: absolute;
  margin: 0 auto;
  top: -38px;
  span {
    width: 64px;
    height: 64px;
    box-shadow: 1px 1px 5px 0px #0000006b;
    border-radius: 50%;
    background: rgba(51, 51, 51, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
    border: 5px solid #fff;
  }
`;

const LinkCustom = styled(Link)`
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  cursor: pointer;
`;

const servicesSelected = () => {
  if (
    location.pathname === "/services" ||
    location.pathname === "/new-ticket" ||
    location.pathname === "/canteen" ||
    location.pathname === "/maintenance" ||
    location.pathname === "/salas" ||
    location.pathname === "/new-book" ||
    location.pathname === "/parcel" ||
    location.pathname === "/gym"
  )
    return true;
  else return false;
};
const networkSelected = () => {
  if (
    location.pathname === "/network" ||
    location.pathname === "/carsharing" ||
    location.pathname === "/carsharing/new" ||
    location.pathname === "/activitysharing" ||
    location.pathname === "/activitysharing/new" ||
    location.pathname === "/business" ||
    location.pathname === "/business-card" ||
    location.pathname === "/events-business"
  )
    return true;
  else return false;
};
const aboutBmcSelected = () => {
  if (
    location.pathname === "/bmc" ||
    location.pathname === "/bmc-events" ||
    location.pathname === "/contact" ||
    location.pathname === "/about-bmc"
  )
    return true;
  else return false;
};

const Navbar = () => {
  const location = useLocation();
  return (
    <NavbarContainer isIos={isPlatform("ios")}>
      <IconsContainer>
        <IconsGroup>
          <LinkCustom to="/">
            <HomeIcon
              fill={location.pathname === "/" ? "#333333" : "#B5B5B5"}
            />
            <Text
              top="0"
              color={location.pathname === "/" ? "#333333" : "#B5B5B5"}
              size="9px"
            >
              Inicio
            </Text>
          </LinkCustom>
          <LinkCustom to="/services">
            <ServicesIcon fill={servicesSelected() ? "#333333" : "#B5B5B5"} />
            <Text
              top="5px"
              color={servicesSelected() ? "#333333" : "#B5B5B5"}
              size="9px"
            >
              Servicios
            </Text>
          </LinkCustom>
        </IconsGroup>
        <QRIconContainer>
          <LinkCustom to="/qrscanner">
            <span>
              <QRIcon />
            </span>
          </LinkCustom>
        </QRIconContainer>
        <IconsGroup>
          <LinkCustom to="/network">
            <NewtworkIcon fill={networkSelected() ? "#333333" : "#B5B5B5"} />
            <Text
              top="0"
              color={networkSelected() ? "#333333" : "#B5B5B5"}
              size="9px"
            >
              Network
            </Text>
          </LinkCustom>

          <LinkCustom to="/bmc">
            <BmcNavIcon fill={aboutBmcSelected() ? "#333333" : "#B5B5B5"} />
            <Text
              top="5px"
              color={aboutBmcSelected() ? "#333333" : "#B5B5B5"}
              size="9px"
            >
              BMC
            </Text>
          </LinkCustom>
        </IconsGroup>
      </IconsContainer>
    </NavbarContainer>
  );
};

export default Navbar;
