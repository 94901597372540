import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { FirebaseContext } from "../../context/FirebaseContext";
import InputButtonWithProps from "../../components/InputButton";
import {
  Text,
  IconAbsolute,
  Title,
  Container,
  AuthCard,
  BorderBottom,
  BlackFilter,
} from "../../genericComponents";
import EyeIcon from "../../icons/eye-icon";
import InputWithLegend from "../../components/InputWithLegend";
import ModalCusstom from "../../components/ModalCustom";
import ModalResetPass from "../../components/ModalResetPass";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useContext(FirebaseContext);

  const [image, setImage] = useState(null);
  const navigation = useNavigate();
  const [userData, setUserData] = useState(null);
  const { sendingPasswordResetMail } = useContext(FirebaseContext);
  const [sendingStatus, setSendingStatus] = useState(false);
  const [emailRememberPass, setEmailRememberPass] = useState("");
  const [message, setMessage] = useState("");
  const [seePass, setSeePass] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalResetStatus, setModalResetStatus] = useState(false);

  const logIn = async (e: any) => {
    setMessage("Iniciando sesión...");
    setModalStatus(true);
    e.preventDefault();
    const userDB = await login(userData.email, userData.password);

    if (userDB) {
    } else {
      setModalStatus(false);
      setErrorMessage("Usuario o contraseña incorrecto");
    }
  };
  const handleChange = (e: any) => {
    setErrorMessage("");
    setUserData({ ...userData, [e.target.name]: e.target.value });
    e.preventDefault();
    switch (e.target.name) {
      case "image":
        setImage(e.target.files[0]);
        break;
      default:
        setUserData({ ...userData, [e.target.name]: e.target.value });
        break;
    }
  };

  const sendRequest = async () => {
    
      try {
        setModalResetStatus(false)
        setModalStatus(true)
        setMessage("Enviando...");
        await sendingPasswordResetMail(emailRememberPass);
        setMessage('Enviado correctamente');
        
      } catch (error) {
        setMessage('Error enviando correo de recuperación, por favor, prueba de nuevo más tarde.');
        
      }finally{
        setTimeout(() => {
          resetRequest();
        }, 2000);
        setSendingStatus(true);

      }
    
  };
  const handleChangePassword = (e: any) => {
    e.preventDefault();
    setEmailRememberPass(e.target.value);
  };

  const resetRequest = () => {
    setSendingStatus(false);
    setEmailRememberPass("");
    setModalStatus(false)
    setMessage("");
  };
  const handlePass = () => {
    setSeePass(!seePass);
  };
  
  const closeModalReset = () => {
    setModalResetStatus(false);
  };
  return (
    <Container>
      <AuthCard autoComplete="off" onSubmit={(e: any) => logIn(e)}>
        <BorderBottom color="#EAEAEA">
          <Title size="20px" weight="300">
            INICIO DE SESIÓN
          </Title>
        </BorderBottom>

        <InputWithLegend
          type="email"
          name="email"
          title="Nombre de usuario"
          onChange={handleChange}
          legendColor="#5F5F5F"
        />
        <InputWithLegend
          legendColor="#5F5F5F"
          type={seePass ? "text" : "password"}
          name="password"
          title="Contraseña"
          onChange={handleChange}
          icon={
            <IconAbsolute
              onClick={() => handlePass()}
              color="#fff"
              size="auto"
              pointer
              top="8px"
              right="5px"
            >
              <EyeIcon opacity={!seePass ? "0.4" : "1"} />
            </IconAbsolute>
          }
        />
        <Text
          align="center"
          size="12px"
          weight="400"
          style={{ color: "#cc2a2a" }}
        >
          {errorMessage}
        </Text>

        <Text
          onClick={() => setModalResetStatus(true)}
          pointer
          top="10px"
          decoration="underline"
          color="#777777"
          size="10px"
          align="left"
          bottom="20px"
        >
          ¿Has olvidado tu contraseña?
        </Text>
        <InputButtonWithProps type="submit" text="Iniciar sesión" />
      </AuthCard>

      <ModalCusstom message={message} open={modalStatus} />
      <ModalResetPass
        handleChangePassword={handleChangePassword}
        email={emailRememberPass}
        resetFunct={sendRequest}
        close={closeModalReset}
        open={modalResetStatus}
      />

      <BlackFilter></BlackFilter>
    </Container>
  );
};

export default Login;
