import styled from "@emotion/styled";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { Text } from "../../../genericComponents";

const Container = styled.div`
  padding-bottom: 150px;
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
`;
const ItemProfile = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background: #f6f6f6;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.5px solid #000;
`;

const Notis = () => {
  const { userNotis } = useContext(FirebaseContext);
  const navigation = useNavigate();
  const notisArr = [
    { type: "canteen", title: "Cafetería", name: "canteen" },
    { type: "events-bmc", title: "Eventos BMC", name: "eventsBmc" },
    { type: "events-business", title: "Eventos empresas", name: "events" },
    { type: "packages", title: "Paquetería", name: "packages" },
    { type: "tickets", title: "Mantenimiento", name: "tickets" },
    { type: "carsharing", title: "Carsharing", name: "carsharing" },
    { type: "activitysharing", title: "Activitysharing", name: "activitysharing" },
    { type: "sala", title: "Salas", name: "sala" },
  ];
  const checkNotisLength = () => {
    if (
      userNotis?.canteen?.unviewed?.length === 0 &&
      userNotis?.canteen?.viewed?.length === 0 &&
      userNotis?.eventsBmc?.unviewed?.length === 0 &&
      userNotis?.eventsBmc?.viewed?.length === 0 &&
      userNotis?.events?.unviewed?.length === 0 &&
      userNotis?.events?.viewed?.length === 0 &&
      userNotis?.packages?.unviewed?.length === 0 &&
      userNotis?.packages?.viewed?.length === 0 &&
      userNotis?.tickets?.unviewed?.length === 0 &&
      userNotis?.tickets?.viewed?.length === 0 &&
      userNotis?.carsharing?.unviewed?.length === 0 &&
      userNotis?.carsharing?.viewed?.length === 0 &&
      userNotis?.activitysharing?.unviewed?.length === 0 &&
      userNotis?.activitysharing?.viewed?.length === 0 &&
      userNotis?.sala?.unviewed?.length === 0 &&
      userNotis?.sala?.viewed?.length === 0
    ) {
      return false;
    } else return true;
  };

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="/notis.jpeg"
          title="NOTIFICACIONES"
          subtitle="Consulta y gestiona las notificaciones recibidas en las distintas secciones de la App BMC."
          showBackButton
        />
        <ItemsContainer>
          {notisArr.map((notification, idx) => {
            return userNotis?.[notification.name]?.unviewed?.length ||
              userNotis?.[notification.name]?.viewed?.length ? (
              <ItemProfile
                key={`${idx}-${notification.name}`}
                onClick={() =>
                  navigation(`/notis-details`, {
                    state: {
                      type: notification.type,
                    },
                  })
                }
              >
                <Text
                  width="80%"
                  weight={
                    userNotis?.[notification.name]?.unviewed?.length > 0
                      ? "600"
                      : "200"
                  }
                  size="14px"
                  left="25px"
                >
                  {notification.title}
                </Text>

                <Text width="15%" weight="600">
                  {userNotis?.[notification.name]?.unviewed?.length > 0 &&
                    `(${userNotis?.[notification.name]?.unviewed?.length})`}
                </Text>
              </ItemProfile>
            ) : null;
          })}
          {checkNotisLength() ? null : (
            <Text
              size="13px"
              width="80%"
              left="auto"
              right="auto"
              top="20px"
              weight="200"
              align="center"
            >
              No tienes ninguna notificación pendiente.
            </Text>
          )}
        </ItemsContainer>
      </Container>
    </Layout>
  );
};

export default Notis;
