import styled from "@emotion/styled";
import { useContext } from "react";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { Title, Text } from "../../../genericComponents";

const HeroColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  flex-basis: 33%;
`;

const HomeHero = () => {
  const { user } = useContext(FirebaseContext);

  return (
    <HeroColumn>
      <Title
        bottom="0"
        size="25px"
        top="20px"
        left="20px"
        align="left"
        weight="200"
        width="100%"
      >
        {user && `¡HOLA ${user?.name.toUpperCase()}!`}
      </Title>
      <Text
        top="5px"
        bottom="10px"
        size="18px"
        left="20px"
        align="left"
        weight="200"
        width="100%"
      >
        ¿Has visto las novedades de hoy?
      </Text>
    </HeroColumn>
  );
};

export default HomeHero;
