import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";

import styled from "@emotion/styled";
import FloatButton from "../../../components/FloatButton";
import { useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { EventsContext, IEventsBmc } from "../../../context/EventsContext";
import EventCard from "../../../components/EventCard";
import { Text } from "../../../genericComponents";
import { useSearchParams } from "react-router-dom";

var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;

const Container = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
`;
const ContainerEvents = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 150px;
`;

const EventsBMC = () => {
  const { eventsBmc } = useContext(EventsContext);
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");

  useEffect(() => {
    eventId && scroller.scrollTo(eventId, { duration: 1500, delay: 100, smooth: true, offset: 50 });
  }, [eventId]);

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fcabecera_eventosBMC.jpg?alt=media&token=4a054293-28af-4ba7-8e84-46266a4c3025"
          title="EVENTOS BMC"
          subtitle="Consulta los eventos programados por BMC para todos los integrantes del edificio."
          showBackButton
        />

        <ContainerEvents>
          {eventsBmc?.length > 0 ? (
            eventsBmc.map((doc, idx) => {
              return <EventCard key={doc.title + idx} eventData={doc} />;
            })
          ) : (
            <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
              Todavía no hay ningún evento programado. Estáte atento, ¡pronto tendrás noticias nuestras!
            </Text>
          )}
        </ContainerEvents>
      </Container>
    </Layout>
  );
};

export default EventsBMC;
