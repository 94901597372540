import { Modal, Box } from "@mui/material";
import { Text } from "../../genericComponents";
import { modalStyle } from "../../utils/modalStyle";
import InputButtonWithProps from "../InputButton";
import styled from "@emotion/styled";
import InputWithLegend from "../InputWithLegend";

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

const ModalResetPass = ({ open, close, resetFunct, email, handleChangePassword }) => {
  return (
    <Modal open={open} onClose={close}>
      <Box sx={modalStyle}>
       
        <Text top="10px"  weight="600" align="center" size="18px">
          Recuperar contraseña
        </Text>
        <InputWithLegend title="Email" name="email" type="email" onChange={(e) => handleChangePassword(e)} />
        <Row>
          <InputButtonWithProps disabled={!email} onClick={() => resetFunct()} right="5px" type="submit" text="Enviar" top="20px" />
          <InputButtonWithProps type="submit" onClick={() => close()} text="Cancelar" top="20px" left="5px" color="#fff" textColor="#000" border="0.5px solid #000" />
        </Row>
      </Box>
    </Modal>
  );
};

export default ModalResetPass;
