import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { useNavigate } from "react-router";
import styled from "@emotion/styled";
import { useContext, useEffect } from "react";
import { EventsContext } from "../../../context/EventsContext";
import EventCardBusiness from "../../../components/EventCardBusiness";
import { Text } from "../../../genericComponents";
import { createSearchParams, useSearchParams } from "react-router-dom";
var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;
const Container = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
`;
const ContainerEvents = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
`;

const Offers = () => {
  const navigation = useNavigate();
  const { events } = useContext(EventsContext);
 const offers = events.filter((event) => event?.isOffer)
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");

  useEffect(() => {
    eventId && scroller.scrollTo(eventId, { duration: 1500, delay: 100, smooth: true, offset: 50 });
  }, [eventId]);


  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fcabecera_eventos.jpg?alt=media&token=1068ac47-25d3-4e9a-89db-a2142fde50bb"
          title="OFERTAS"
          subtitle="Consulta ofertas creadas por las empresas de BMC."
          showBackButton
        />

        <ContainerEvents>
          {offers?.length > 0 ? (
            offers.map((doc, idx) => {
              return <EventCardBusiness key={doc.title + idx} eventData={doc} />;
            })
          ) : (
            <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
              Todavía no hay ninguna oferta programado. Estáte atento, ¡pronto tendrás noticias nuestras!
            </Text>
          )}
        </ContainerEvents>
      </Container>
    </Layout>
  );
};

export default Offers;
