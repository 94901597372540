import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";

const Container = styled.div`
  background-color: white;
  position: relative;
  padding-bottom: 150px;
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  a {
    padding: 30px;
    background-color: #f6f6f6;
    border-bottom: 1px solid grey;
    width: 100%;
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 300;
  }
`;

const InfoLegal = () => {
  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/statistics%2Fcabecera_estadisticas.png?alt=media&token=794cc90f-c01b-4c0a-8c10-24dbdebc6900"
          title="INFO LEGAL"
          subtitle="Consulta toda la información sobre cómo protegemos y tratamos tus datos personales."
          showBackButton
        />
        <ContainerButtons>
          <Link to="/privacity">Política de privacidad</Link>
          <Link to="/cookies-policy">Política de cookies</Link>
          <Link to="/legal/info">Aviso legal LSSI</Link>
        </ContainerButtons>
      </Container>
    </Layout>
  );
};

export default InfoLegal;
