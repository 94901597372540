import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import InputButtonWithProps from "../../../../components/InputButton";
import InputWithLegend from "../../../../components/InputWithLegend";
import Layout from "../../../../components/Layout";
import ModalCusstom from "../../../../components/ModalCustom";

import { FirebaseContext } from "../../../../context/FirebaseContext";
import {
  AuthCardSignUp,
  Card,
  IconAbsolute,
  Text,
  Title,
} from "../../../../genericComponents";
import EyeIcon from "../../../../icons/eye-icon";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const CardContainer = styled.div`
  width: 100%;
  background: #f6f6f6;
  height: 100%;
  padding-top: 20px;
`;
const TitleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
`;

const ChangePass = () => {
  const { updateActualPassword, user } = useContext(FirebaseContext);

  const navigation = useNavigate();

  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [password, setPassword] = useState("");
  const [actualPassword, setActualPassword] = useState("");
  const [seePass, setSeePass] = useState(false);
  const [seeActualPass, setSeeActualPass] = useState(false);

  const sendRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage("Actualizando datos...");
    setModalStatus(true);
    try {
      await updateActualPassword(password, actualPassword, user.email);
      setMessage("Actualizado con éxito");

      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        navigation("/", { replace: true });
      }, 2000);
    } catch (error) {
      setMessage("Contraseña incorrecta");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
      }, 2000);
    }
  };

  const closeModal = () => {
    setModalStatus(false);
  };

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Text top="0" weight="100" size="24px" onClick={() => navigation(-1)}>
            ←
          </Text>
          <Title size="25px" weight="200" top="10px">
            Cambio de contraseña
          </Title>
        </TitleContainer>
        <CardContainer>
          <Card
            autoComplete="off"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => sendRequest(e)}
          >
            <InputWithLegend
              legendColor="#5F5F5F"
              type={seeActualPass ? "text" : "password"}
              name="actualPassword"
              title="Contraseña actual*"
              width="100%"
              value={actualPassword}
              onChange={(e) => setActualPassword(e.target.value)}
              icon={
                <IconAbsolute
                  onClick={() => setSeeActualPass(!seeActualPass)}
                  color="#fff"
                  size="auto"
                  pointer
                  top="8px"
                  right="5px"
                >
                  <EyeIcon opacity={!seeActualPass ? "0.4" : "1"} />
                </IconAbsolute>
              }
            />
            <InputWithLegend
              legendColor="#5F5F5F"
              type={seePass ? "text" : "password"}
              name="password"
              title="Nueva contraseña*"
              width="100%"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              icon={
                <IconAbsolute
                  onClick={() => setSeePass(!seePass)}
                  color="#fff"
                  size="auto"
                  pointer
                  top="8px"
                  right="5px"
                >
                  <EyeIcon opacity={!seePass ? "0.4" : "1"} />
                </IconAbsolute>
              }
            />

            <InputButtonWithProps
              type="submit"
              disabled={!password}
              text="Cambiar"
              top="20px"
            />
          </Card>
        </CardContainer>
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default ChangePass;
