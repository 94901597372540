import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import InputWithLegend from "../../../../components/InputWithLegend";
import Layout from "../../../../components/Layout";
import SectionHeader from "../../../../components/SectionHeader";
import CalendarIcon from "../../../../icons/services/calendarIcon";
import InputCheckbox from "../../../../components/InputCheckbox";

import TextAreaWithLegend from "../../../../components/TextAreaWithLegend";
import InputButtonWithProps from "../../../../components/InputButton";
import SectionFooter from "../../../../components/SectionFooter";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { addDoc, collection } from "firebase/firestore";
import ModalCusstom from "../../../../components/ModalCustom";
import { useNavigate } from "react-router";
import { Text } from "../../../../genericComponents";
import DatePickerCustom from "../../../../components/DatePicker";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.form`
  margin: 20px auto;
  width: 80%;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
export const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 100px;
  width: 100%;
  background: ${(props) =>
    props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const ContainerDiv = styled.div`
  padding: 15px;
`;
const NewEventWebBmc = () => {
  const { db, user, uploadImage } = useContext(FirebaseContext);
  const navigation = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");

  const [newEventBmc, setNewEventBmc] = useState({
    allDay: false,
    hour: "",
    title: "",
    description: "",
    initTimestamp: 0,
    finishTimestamp: 0,
  });

  const [disableButton, setDisableButton] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    switch (e.target.name) {
      case "initTimestamp":
        const init = new Date(e.target.value).getTime();
        setNewEventBmc({ ...newEventBmc, [e.target.name]: init });
        break;
      case "image":
        setImage(e.target.files[0]);
        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      case "finishTimestamp":
        const finish = new Date(e.target.value).getTime();
        setNewEventBmc({ ...newEventBmc, [e.target.name]: finish });
        break;

      default:
        setNewEventBmc({ ...newEventBmc, [e.target.name]: e.target.value });
        break;
    }
  };

  const clickCheckbox = (e) => {
    setNewEventBmc({
      ...newEventBmc,
      allDay: !newEventBmc.allDay,
      hour: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setOpenModal(true);
      setModalMessage("Creando evento...");

      if (image) {
        const url = await uploadImage(image);
        await addDoc(collection(db, "events-bmc-web"), {
          ...newEventBmc,
          image: url,
          businessId: user.businessId,
        });
      } else {
        await addDoc(collection(db, "events-bmc-web"), {
          ...newEventBmc,
          businessId: user.businessId,
        });
      }
      setOpenModal(true);
      setModalMessage("Evento creado correctamente.");
    } catch (error) {
      setOpenModal(true);
      setModalMessage("Ha habido un error, inténtelo de nuevo más tarde.");
    } finally {
      setTimeout(() => {
        setOpenModal(false);
        setModalMessage("");
        navigation("/events-bmc-web-admin");
      }, 3000);
    }
  };

  useEffect(() => {
    if (
      (newEventBmc.hour || newEventBmc.allDay) &&
      newEventBmc.title &&
      newEventBmc.description &&
      newEventBmc.initTimestamp &&
      newEventBmc.finishTimestamp
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [newEventBmc]);

  return (
    <Layout>
      <Container>
        <SectionHeader title="PUBLICAR EVENTO WEB" showBackButton={true} />
        <InnerContainer onSubmit={handleSubmit}>
          <DatePickerCustom
            preIcon={<CalendarIcon />}
            label="initTimestamp"
            data={newEventBmc}
            legendColor="#5F5F5F"
            setFunct={setNewEventBmc}
            title="Fecha inicio"
          />
          <DatePickerCustom
            preIcon={<CalendarIcon />}
            label="finishTimestamp"
            data={newEventBmc}
            initSelected={newEventBmc?.initTimestamp}
            legendColor="#5F5F5F"
            setFunct={setNewEventBmc}
            title="Fecha fin"
          />
          <InputCheckbox
            text="Todo el día"
            id="allDay"
            name="allDay"
            onChange={clickCheckbox}
            checked={newEventBmc.allDay}
          />
          <InputWithLegend
            preIcon={<CalendarIcon />}
            title="Hora*"
            name="hour"
            type="time"
            disabled={newEventBmc.allDay}
            value={newEventBmc.hour}
            onChange={handleChange}
          />

          <InputWithLegend
            title="Título del evento*"
            name="title"
            type="text"
            value={newEventBmc.title}
            onChange={handleChange}
          />

          <TextAreaWithLegend
            title="Descripción del evento*"
            legendColor="#5F5F5F"
            legendSubtitleColor="#5F5F5F"
            name="description"
            value={newEventBmc.description}
            onChange={handleChange}
          />
          <ContainerDiv>
            <Text
              left="10px"
              color="#5F5F5F"
              size="14px"
              top="10px"
              bottom="10px"
            >
              Imagen del evento
            </Text>
            <ImageCircle
              imagePreview={
                preview
                  ? preview
                  : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/default_img.jpeg?alt=media&token=860e4b3e-fe68-4359-9e90-1e7a79fc7a1d"
              }
              onClick={() => document.getElementById("image").click()}
            >
              {!image && !preview && <Image src="/upload.png" />}
            </ImageCircle>
          </ContainerDiv>
          <Invisible>
            <InputInvisible
              name="image"
              type="file"
              id="image"
              onChange={handleChange}
              title="Imagen del evento"
            />
          </Invisible>

          <ContainerButton>
            <InputButtonWithProps
              disabled={disableButton}
              type="submit"
              border="none"
              text="Publicar evento"
            />
          </ContainerButton>
        </InnerContainer>
        <SectionFooter src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fnetwork%2Fpie_network.jpg?alt=media&token=536a2fff-50a1-4ddf-8dc5-fdeca59294f5" />
      </Container>
      <ModalCusstom open={openModal} message={modalMessage} />
    </Layout>
  );
};

export default NewEventWebBmc;
