import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { Text } from "../../genericComponents";

const Container = styled.div`
  background-color: white;
  position: relative;
  padding-bottom: 150px;
`;

const ContainerTexts = styled.div`
  padding: 10px 25px;
`;

const PrivacityPolicy = () => {
  return (
    <Layout>
      <Container>
        <SectionHeader title="POLÍTICA DE PRIVACIDAD" showBackButton />
        <ContainerTexts>
          <Text size="14px" top="20px" weight="500">
            Información adicional de Protección de datos:
          </Text>
          <Text size="14px" top="20px" weight="700">
            1. RESPONSABLE
          </Text>
          <Text size="14px" weight="300" top="10px">
            - EXPO CENTER BMC, S.L. <br />
            - CIF: B60789922 <br />
            - Dirección: Ronda Maiols, 1 <br />
            - Edificio BMC <br />- Correo electrónico: <Link to="mailto:bmc@bmc-moda.com">bmc@bmc-moda.com</Link> <br />- Teléfono: 93 745 45 00
          </Text>
          <Text size="14px" top="20px" weight="700">
            2. FINALIDADES
          </Text>
          <Text size="14px" weight="300" top="10px">
            Los datos personales facilitados serán tratados para las siguientes finalidades según corresponda:
          </Text>
          <Text size="14px" weight="300" top="10px">
            - Desarrollar, cumplir y gestionar la ejecución del contrato entre las partes.
            <br />
            - La supervisión del acceso al centro con fines de seguridad. <br />
            - Atender las solicitudes que nos plantee.
            <br />
            - La utilización de la web app interna para la mejora de atención a los usuarios de BMC.
            <br />
            - Facilitar una respuesta rápida y adecuada en el uso de las instalaciones, con el objetivo final de garantizar y proteger los derechos de los interesados y de la ciudadanía en general.
            <br />
            - Colaboración con las Fuerzas y Cuerpos de Seguridad para fines de prevención, detección, investigación y enjuiciamiento de infracciones penales y de ejecución de sanciones penales.
            <br />
            - Si ha aceptado explícitamente recibir comunicaciones comerciales, sus datos se utilizarán para proporcionarle información sobre las actividades que se lleven a cabo en el centro. <br />
          </Text>
          <Text size="14px" top="20px" weight="700">
            Plazo de conservación
          </Text>
          <Text size="14px" weight="300" top="10px">
            Los datos se conservarán durante el tiempo necesario para atender las finalidades previstas o hasta que el usuario solicite su supresión. Así mismo, se deberá́ tomar en consideración las
            obligaciones legales en materia seguridad y prevención.
          </Text>
          <Text size="14px" top="20px" weight="700">
            Perfiles automatizados
          </Text>
          <Text size="14px" weight="300" top="10px">
            No se tomarán decisiones individuales automatizadas, incluida la elaboración de perfiles en este ámbito.
          </Text>
          <Text size="14px" top="20px" weight="700">
            3. LEGITIMACIÓN
          </Text>
          <Text size="14px" weight="300" top="10px">
            La base legal para el tratamiento de datos es el interés legítimo para la prestación del servicio solicitado. En concreto, EXPO CENTER BMC, S.L. tiene las siguientes bases legítimas para
            poder tratar los datos personales:
          </Text>
          <Text size="14px" top="20px" weight="700">
            Prestación de servicios
          </Text>
          <Text size="14px" weight="300" top="10px">
            La base legal del tratamiento de los datos personales del interesado que se recogen es la ejecución del contrato y/o el consentimiento expreso del usuario.
          </Text>
          <Text size="14px" top="20px" weight="700">
            Obligaciones legales
          </Text>
          <Text size="14px" weight="300" top="10px">
            La gestión y emisión de la factura de prestación de servicio se basa en la obligación legal de la sociedad en su relación con los clientes. La colaboración con las autoridades competentes
            en materia de seguridad.
          </Text>
          <Text size="14px" top="20px" weight="700">
            Consentimiento
          </Text>
          <Text size="14px" weight="300" top="10px">
            En relación a las comunicaciones con finalidad publicitaria, la legitimación proviene del consentimiento explícito del usuario.
          </Text>
          <Text size="14px" top="20px" weight="700">
            4. DESTINATARIOS
          </Text>
          <Text size="14px" weight="300" top="10px">
            Los datos personales del interesado pueden ser cedidos/comunicados a los destinatarios siguientes:
            <br />
            • Administraciones y Organismos Públicos cuando así lo exija la normativa fiscal, laboral, de Seguridad Social o cualquier otra aplicable.
            <br />
            • Fuerzas y cuerpos de seguridad en materia judicial.
            <br />• Empresas encargadas del tratamiento de datos y proveedores que presten su servicio a la sociedad (p.ej., proveedores de servicios informáticos, centros de atención telefónica,
            etc.)
          </Text>
          <Text size="14px" top="20px" weight="700">
            5. DERECHOS DEL USUARIO
          </Text>
          <Text size="14px" weight="300" top="10px">
            Derechos de acceso: Tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le conciernen o no, así como a acceder a sus datos personales.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Derechos de rectificación: Tiene derecho a solicitar la rectificación de los datos personales cuando sean inexactos o que se completen cuando sean incompletos.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Derechos de supresión: Puede solicitarnos que los datos personales sean suprimidos cuando, entre otros motivos, los datos ya no sean necesarios para la finalidad que fueron recogidos.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Derechos de limitación: Tienen derecho a solicitar la limitación del tratamiento de datos, en cuyo caso únicamente los conservaremos para el ejercicio o defensa de reclamaciones.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Derechos de portabilidad: Puede solicitarnos que sus datos personales automatizados sean cedidos o transferidos a cualquier otra persona que nos indique en un formato estructurado, de uso
            común y lectura mecánica.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Derechos de oposición: Puede oponerse a que los datos personales sean objeto de un tratamiento. En este caso, se dejará de tratar los datos, a excepción de motivos legítimos imperiosos o
            el ejercicio o defensa de posibles reclamaciones.
          </Text>
          <Text size="14px" top="20px" weight="700">
            6. EJERCICIO DE DERECHOS
          </Text>
          <Text size="14px" weight="300" top="10px">
            Los interesados podrán ejercitar sus derechos dirigiéndose por escrito al responsable del fichero mediante el contacto indicado en el encabezado e indicando que se trata de un “EJERCICIO
            DEL DERECHO DE PROTECCIÓN DE DATOS”. Para tramitar la solicitud, podremos pedirle que acredite su identidad. Así mismo, les informamos que tiene derecho a presentar una reclamación ante la
            Agencia Española de Protección de Datos.
          </Text>
          <Text size="14px" top="20px" weight="700">
            7. PRINCIPIOS
          </Text>
          <Text size="14px" weight="300" top="10px">
            El tratamiento de datos personales será proporcional, oportuno, mínimo y suficiente para el cumplimiento de los fines que se persiguen basándonos en los siguientes principios:
          </Text>
          <Text size="14px" weight="300" top="10px">
          •	Principio de “licitud, transparencia y lealtad”, que consiste en tratar tus datos de manera lícita, leal y transparente.<br />
•	Principio de “finalidad” que implica, por una parte, la obligación de que los datos sean tratados con una o varias finalidades determinadas, explícitas y legítimas y, por otra, que se prohíbe que los datos recogidos con unos fines determinados, explícitos y legítimos sean tratados posteriormente de una manera incompatible con esos fines.<br />
•	Principio de “minimización de datos”, es decir, aplicar medidas técnicas y organizativas para garantizar que sean objeto de tratamiento los datos que únicamente sean precisos para cada uno de los fines específicos del tratamiento reduciendo, la extensión del tratamiento, limitando a lo necesario el plazo de conservación y su accesibilidad.<br />
•	Principio de “exactitud”, que obliga a los responsables a disponer de medidas razonables para que los datos se encuentren actualizados, se supriman o modifiquen sin dilación cuando sean inexactos con respecto a los fines para los que se tratan.<br />
•	Principio de “limitación del plazo de conservación” que constituye una de las materializaciones del principio de minimización. La conservación de esos datos debe limitarse en el tiempo al logro de los fines que persigue el tratamiento. Una vez que esas finalidades se han alcanzado, los datos deben ser borrados, bloqueados o, en su defecto, anonimizados, es decir, desprovistos de todo elemento que permita identificar a los interesados.<br />
•	Principio de “seguridad” que impone a quienes tratan datos el necesario análisis de riesgos orientado a determinar las medidas técnicas y organizativas necesarias para garantizar la integridad, la disponibilidad y la confidencialidad de los datos personales que traten.<br />
•	Principio de “responsabilidad activa” o “responsabilidad demostrada” que obliga a los responsables a mantener diligencia debida de manera permanente para proteger y garantizar los derechos y libertades de las personas físicas cuyos datos son tratados en base a un análisis de los riesgos que el tratamiento representa para esos derechos y libertades, de modo que el responsable pueda, tanto garantizar como estar en condiciones de demostrar que el tratamiento se ajusta a las previsiones del RGPD y la LOPDGD.<br />

          </Text>
        </ContainerTexts>
      </Container>
    </Layout>
  );
};

export default PrivacityPolicy;
