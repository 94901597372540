import styled from "@emotion/styled";
import React from "react";
import InputButtonWithProps from "../InputButton";

interface IBannerProps {
  title: string;
  onClickCTA: any;
  textCta: string;
}

const Container = styled.div`
  background-color: #474948;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h2 {
    font-size: 16px;
    text-align: center;
    letter-spacing: 1.12px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
  }

  button {
    border: none;
    padding: 5px 15px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    margin: 15px 0px;
  }
`;

const Banner = ({ title, onClickCTA, textCta }: IBannerProps) => {
  return (
    <Container>
      <h2>{title}</h2>
      <InputButtonWithProps color="white" textColor="black" top="10px" text={textCta} onClick={onClickCTA}/>
    </Container>
  );
};

export default Banner;
