const Step3 = ({ width, height }: {width?:string, height?:string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "35"}
      height={height ? height : "35"}
      viewBox="0 0 35 35"
    >
      <path
        id="paso3"
        d="M18.739,21.628a1.127,1.127,0,0,0,.856-.358,1.179,1.179,0,0,0,.338-.836,1.157,1.157,0,0,0-1.194-1.194,1.179,1.179,0,0,0-.836.338,1.127,1.127,0,0,0-.358.856,1.225,1.225,0,0,0,1.194,1.194ZM11.573,34.765V32.377L22.72,30.426V11.874a1.634,1.634,0,0,0-.3-.975,1.184,1.184,0,0,0-.816-.5L11.573,8.849V6.5l10.59,1.831a3.316,3.316,0,0,1,2.11,1.214,3.506,3.506,0,0,1,.836,2.289V32.377ZM6,34.765V32.377H9.185V8.889a2.285,2.285,0,0,1,.7-1.712A2.338,2.338,0,0,1,11.573,6.5H29.09a2.315,2.315,0,0,1,2.389,2.389V32.377h3.185v2.389Zm5.573-2.389H29.09V8.889h0l-17.517-.04h0Z"
        transform="translate(-6 -6.5)"
        fill="#b5b5b5"
      />
    </svg>
  );
};

export default Step3;
