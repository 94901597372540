import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import DatePickerCustom from "../../../components/DatePicker";
import InputButtonWithProps from "../../../components/InputButton";
import InputWithLegend from "../../../components/InputWithLegend";
import Layout from "../../../components/Layout";
import ModalCusstom from "../../../components/ModalCustom";
import SectionHeader from "../../../components/SectionHeader";
import { CanteenContext, IOffer } from "../../../context/CanteenContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { Text, Title } from "../../../genericComponents";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.form`
  margin: 20px auto;
  width: 80%;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
export const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 100px;
  width: 100%;
  background: ${(props) =>
    props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const ContainerDiv = styled.div`
  padding: 15px;
`;
const ContainerBtn = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  input {
    margin: 0 auto;
  }
`;

const NewCanteenOffer = () => {
  const navigation = useNavigate();
  const [offer, setOffer] = useState<IOffer>(null);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const { uploadImage } = useContext(FirebaseContext);
  const { addOffer } = useContext(CanteenContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    switch (e.target.name) {
      case "initTimestamp":
        const init = new Date(e.target.value).getTime();
        setOffer({ ...offer, [e.target.name]: init });
        break;
      case "finishTimestamp":
        const finish = new Date(e.target.value).getTime();
        setOffer({ ...offer, [e.target.name]: finish });
        break;
      case "image":
        setImage(e.target.files[0]);
        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      default:
        setOffer({ ...offer, [e.target.name]: e.target.value });
        break;
    }
  };

  const addNewOffer = async () => {
    setModalOpen(true);
    setMessage("Creando oferta...");
    try {
      const url = await uploadImage(image);
      const eventData = { ...offer, image: url };
      await addOffer(eventData);
      setMessage("Oferta creada con éxito.");
    } catch (error) {
      setModalOpen(true);
      setMessage("Error creando una oferta, prueba de nuevo más tarde.");
    } finally {
      setTimeout(() => {
        setModalOpen(false);
        setMessage("");
        navigation("/canteen-admin");
      }, 2000);
    }
  };

  return (
    <Layout>
      <Container>
        <SectionHeader title="NUEVA OFERTA" showBackButton={true} />
        <InnerContainer>
          <InputWithLegend
            name="title"
            type="text"
            legendColor="#5F5F5F"
            onChange={handleChange}
            title="Título de la oferta"
          />
          <InputWithLegend
            name="price"
            type="text"
            legendColor="#5F5F5F"
            onChange={handleChange}
            title="Oferta"
          />
          <Text
            width="90%"
            left="auto"
            right="auto"
            top="0"
            bottom="10px"
            size="smaller"
          >
            *Si la oferta es monetaria añadid €
          </Text>
          <DatePickerCustom
            label="initTimestamp"
            data={offer}
            legendColor="#5F5F5F"
            setFunct={setOffer}
            title="Fecha de inicio"
          />
          <DatePickerCustom
            label="finishTimestamp"
            data={offer}
            legendColor="#5F5F5F"
            setFunct={setOffer}
            title="Fecha de fin"
            initSelected={offer?.initTimestamp}
          />
          {/* <InputWithLegend
            width="90%"
            name="image"
            type="file"
            legendColor="rgb(95, 95, 95)"
            onChange={handleChange}
            title="Imagen de la oferta"
          /> */}
          <ContainerDiv>
            <Text
              left="10px"
              color="#5F5F5F"
              size="14px"
              top="10px"
              bottom="10px"
            >
              Imagen de la oferta
            </Text>
            <ImageCircle
              imagePreview={
                preview
                  ? preview
                  : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/default_img.jpeg?alt=media&token=860e4b3e-fe68-4359-9e90-1e7a79fc7a1d"
              }
              onClick={() => document.getElementById("image").click()}
            >
              {!image && !preview && <Image src="/upload.png" />}
            </ImageCircle>
          </ContainerDiv>
          <Invisible>
            <InputInvisible
              name="image"
              type="file"
              id="image"
              onChange={handleChange}
              title="Imagen de la oferta"
            />
          </Invisible>
          <ContainerBtn>
            <InputButtonWithProps
              disabled={
                !image ||
                !offer?.title ||
                !offer?.initTimestamp ||
                !offer?.finishTimestamp ||
                !offer?.price
              }
              onClick={() => addNewOffer()}
              text="Crear oferta"
              top="20px"
              left="5px"
            />
          </ContainerBtn>
        </InnerContainer>
        <ModalCusstom message={message} open={modalOpen} />
      </Container>
    </Layout>
  );
};

export default NewCanteenOffer;
