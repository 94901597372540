import styled from "@emotion/styled";
import { Text } from "../../genericComponents";

const Image = styled.img`
width:150px;`;
const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding:20px 0;
`;

const DevelopedByPevitech = () => {
  return (
    <ContainerFooter>
        <Text weight="200" size="14px" color='#5F5F5F'>Creado por:</Text>
      <Image src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/Horizontal_color.png?alt=media&token=a6b85322-ffc2-45e6-8cb8-0f5b8f81b79d" alt="logo-pevitech" />
    </ContainerFooter>
  );
};

export default DevelopedByPevitech;
