import { useContext, useState } from "react";
import InputButtonWithProps from "../../../components/InputButton";
import { Text, AuthCardSignUp } from "../../../genericComponents";
import styled from "@emotion/styled";
import InputWithLegend from "../../../components/InputWithLegend";
import SectionHeaderLogin from "../../../components/SectionHeaderLogin";
import { BusinessContext } from "../../../context/BusinessContext";
import ModalCusstom from "../../../components/ModalCustom";
import Layout from "../../../components/Layout";
import { IBusiness } from "../../../interfaces/IBusiness";
import TextAreaWithLegend from "../../../components/TextAreaWithLegend";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { normalizeString } from "../../../utils/functions";
import SelectCategory from "../../../components/SelectCategory";

export const SignupCard = styled.form`
  width: 70%;
  margin: 0 auto;
  background: #f6f6f6;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const CardContainer = styled.div`
  width: 100%;
  background: #f6f6f6;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 150px;
`;

const Row = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 82px;
  width: 82px;
  background: ${(props) => (props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;

export type IBussinesNew = {
  name: string;
  nameToFilter: string;
  nif: string;
  locals: any[];
  description?: string;
};

const CreateBusiness = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { createBusiness, getFirebaseAllBusiness, categories } = useContext(BusinessContext);
  const { uploadImage } = useContext(FirebaseContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [localNew, setLocalNew] = useState("");

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");

  const [businessNew, setBusinessNew] = useState<IBusiness>({
    name: "",
    nameToFilter: "",
    nif: "",
    description: "",
    logo: "",
    locals: [],
    phone:'',
    category:'',
    web:'',
    showInBMC:true
  });

  const [message, setMessage] = useState("");

  const handleChangeShowInBMC=(e:any)=>{
    setErrorMessage("");
    setBusinessNew({ ...businessNew, [e.target.name]: e.target.checked });
    //e.preventDefault();
  }
  const handleChange = (e: any) => {
    setErrorMessage("");
    setBusinessNew({ ...businessNew, [e.target.name]: e.target.value });
    e.preventDefault();
    switch (e.target.name) {
      case "image":
        setImage(e.target.files[0]);

        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      case "name":
        const copyStr = e.target.value;
        setBusinessNew({ ...businessNew, [e.target.name]: e.target.value, nameToFilter: normalizeString(copyStr) });
        break;

      default:
        setBusinessNew({ ...businessNew, [e.target.name]: e.target.value });
        break;
    }
  };

  const create = async (e: any) => {
    e.preventDefault();
    setMessage("Creando empresa...");
    setIsOpenModal(true);
    if (image) {
      const url = await uploadImage(image);

      await createBusiness({ ...businessNew, logo: url as string });
      setMessage("Empresa creada con éxito");
      setTimeout(() => {
        setMessage("");
        setIsOpenModal(false);
      }, 3000);
      await getFirebaseAllBusiness();
    } else {
      await createBusiness(businessNew);
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        resetForm();
      }, 3000);
    }
  };

  const resetForm = () => {
    setBusinessNew({
      name: "",
      nameToFilter: "",
      nif: "",

      locals: [],
    });
    setMessage("");
    setIsOpenModal(false);
  };

  return (
    <Layout>
      <Container>
        <SectionHeaderLogin title="Nueva empresa" />
        <CardContainer>
          <AuthCardSignUp autoComplete="off" onSubmit={(e: any) => create(e)}>
            <Text width="100%" color="#5F5F5F" size="14px" top="10px" bottom="5px">
              Foto de perfil
            </Text>
            <ImageCircle imagePreview={preview ? preview : businessNew?.logo && businessNew.logo} onClick={() => document.getElementById("image").click()}>
              {!image || (!businessNew?.logo && <Image src="/upload.png" />)}
            </ImageCircle>
            <Invisible>
              <InputInvisible name="image" type="file" id="image" onChange={handleChange} title="Imagen de perfil" />
            </Invisible>
            <InputWithLegend legendColor="#5F5F5F" type="text" name="name" title="Nombre*" width="100%" value={businessNew?.name} onChange={handleChange} />
            <InputWithLegend legendColor="#5F5F5F" type="text" name="nif" title="NIF*" width="100%" value={businessNew?.nif} onChange={handleChange} />
            <TextAreaWithLegend legendColor="#5F5F5F" name="description" title="Description" width="100%" value={businessNew?.description} onChange={handleChange} />
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="text" name="phone" title="Teléfono" value={businessNew?.phone} onChange={handleChange} />
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="text" name="web" title="Página web" value={businessNew?.web} onChange={handleChange} />
            <SelectCategory
              legend="Categoría"
              options={categories}
              value={categories?.find((category) => (businessNew?.category === category.id)) ? businessNew?.category : "unselected"}
              handleChange={handleChange}
            />
            <InputWithLegend legendColor="#5F5F5F" type="text" name="local" title="Local*" width="100%" value={localNew} onChange={(e) => setLocalNew(e.target.value)} />

            <InputButtonWithProps
              disabled={!localNew}
              type="button"
              onClick={() => {
                const copy: IBussinesNew = JSON.parse(JSON.stringify(businessNew));
                copy.locals.push(localNew);
                setBusinessNew(copy);
                setLocalNew("");
              }}
              top="20px"
              text="Añadir local"
            />
            <Text size="14px" color="#5F5F5F" top="10px" align="left" width="100%">
              Locales*
            </Text>
            {businessNew?.locals.length === 0 && (
              <Text size="14px" color="#5F5F5F" top="10px" align="center" width="100%">
                No se ha añadido ningún local.
              </Text>
            )}
            {businessNew?.locals.length > 0 &&
              businessNew.locals.map((local, idx) => {
                return (
                  <Row key={`${local}-${idx}`}>
                    <Text
                      pointer
                      onClick={() => {
                        const copy: IBussinesNew = JSON.parse(JSON.stringify(businessNew));
                        const deletedCopy = copy.locals.filter((doc) => doc !== local);
                        setBusinessNew({ ...businessNew, locals: deletedCopy });
                        setLocalNew("");
                      }}
                      size="14px"
                      color="#822d2d"
                      top="10px"
                      weight="bolder"
                      align="right"
                      width="10%"
                    >
                      X
                    </Text>
                    <Text size="14px" color="#5F5F5F" top="10px" align="left" left="5%" width="85%">
                      {local}
                    </Text>
                  </Row>
                );
              })}
<InputWithLegend legendColor="#5F5F5F" type="checkbox" title="Mostrar en la web de BMC" name="showInBMC" checked={businessNew?.showInBMC} onChange={handleChangeShowInBMC}/>
            <InputButtonWithProps disabled={!businessNew?.name || !businessNew?.nif || !businessNew.locals.length} type="submit" top="20px" text="Crear empresa" />
            {errorMessage && <Text color="red">{errorMessage}</Text>}
          </AuthCardSignUp>
        </CardContainer>
        <ModalCusstom message={message} open={isOpenModal} />
      </Container>
    </Layout>
  );
};

export default CreateBusiness;
