import styled from "@emotion/styled";

interface IInputCheckbox {
  text: string;
  name: string;
  id: string;
  checked: any;
  onChange: any;
  width?: string;
  padding?:string;
  disabled?: boolean;
}

const Container = styled.div<{ width?: string; disabled?: boolean,padding?:string }>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width && props.width};
  padding: ${(props) => props.padding ? props.padding:"10px 20px"};

  p {
    margin-left: 5px;
    font-weight: 300;
    opacity: ${(props) => props.disabled && 0.5};
  }
`;

const InputCheckbox = ({
  text,
  name,
  id,
  checked,
  onChange,
  width,
  disabled,
  padding,
}: IInputCheckbox) => {
  return (
    <Container width={width} disabled={disabled} padding={padding}>
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <p>{text}</p>
    </Container>
  );
};

export default InputCheckbox;
