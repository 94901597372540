import styled from "@emotion/styled";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";

import SalaWorker from "./SalaWorker";

const CanteenContainer = styled.div`
  padding-bottom: 150px;
`;

const Salas = () => {
  return (
    <Layout>
      <CanteenContainer>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/services%2Fsalas%2Fcabecera_salas.jpg?alt=media&token=85f89697-553d-4ab9-9c16-62797b2ede0f"
          title="SALAS"
          subtitle="Reserva una de nuestras salas y lleva tus reuniones a otro nivel. Tus necesidades quedarán cubiertas con nosotros."
          showBackButton
        />

        <SalaWorker />
      </CanteenContainer>
    </Layout>
  );
};

export default Salas;
