import styled from "@emotion/styled";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { ContainerInvisible, ContainerGeneral, ContainerTexts, ContainerButton } from "../../components/ModalCookies";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Text } from "../../genericComponents";
import { normalizeString } from "../../utils/functions";

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: #00000090;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
`;

const ContainerModal = styled.div`
  opacity: 1;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0 auto;
  width: 70%;
  padding: 20px;
`;

const Legal = () => {
  const { updateUser, user } = useContext(FirebaseContext);
  //   const navigation = useNavigate();
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await updateUser({ ...user, acceptLegal: true, nameToFilter: normalizeString(user.name) });

      //   navigation('/');
    } catch (error) {}
  };
  return (
    <Container>
      <ContainerModal>
        <ContainerTexts>
          <Text size="14px" top="20px" weight="700">
            Aviso legal
          </Text>
          <Text size="14px" weight="300" top="10px">
            Expo Center BMC, S.L. es el responsable del tratamiento de los datos que nos hayas proporcionado en relación a la utilización de la web app interna de BMC.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Tus datos se utilizarán para la gestión de seguridad, administrativa y legal del centro, proporcionándote acceso e información a:
          </Text>
          <Text size="14px" weight="300" top="10px">
            - Actividades <br />
            - Colaboraciones entre empresas <br />
            - Inscripción y alta de eventos <br />
            - Servicio de paquetería <br />
            - Carsharing <br />
            - Seguridad en el control de acceso <br />
          </Text>
          <Text size="14px" weight="300" top="10px">
            Tus datos se utilizarán para la gestión de seguridad, administrativa y legal del centro, proporcionándote acceso e información a:
          </Text>
          <Text size="14px" weight="300" top="10px">
            Tus datos personales no serán cedidos a terceros excepto por obligación legal y serán debidamente almacenados por el tiempo mínimo imprescindible necesario.
          </Text>
          <Text size="14px" weight="300" top="10px">
            Puedes ejercitar los derechos de acceso, rectificación, supresión, oposición, limitación o portabilidad <Link to="mailto:bmc@bmc-moda.com">bmc@bmc-moda.com</Link>
          </Text>
          <Text size="14px" weight="300" top="10px">
            Puedes obtener información adicional en nuestra <Link to="/legal/info">+info legal</Link>
          </Text>
        </ContainerTexts>
        <ContainerButton>
          <button onClick={handleSubmit}>Aceptar</button>
        </ContainerButton>
      </ContainerModal>
    </Container>
  );
};

export default Legal;
