import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../../context/FirebaseContext";
import styled from "@emotion/styled";
import { IUser } from "../../../interfaces/IUser";
import { formatText } from "../../../utils/functions";
import { Text } from "../../../genericComponents";
import {
  MENU_ACTIVE_BUSINESS,
  MENU_ACTIVE_USERS,
} from "../../../utils/constants";
import SearchBarCustom from "../../../components/SearchbarCustom";
import { BusinessContext } from "../../../context/BusinessContext";
import { IBusiness } from "../../../interfaces/IBusiness";
import SendParcelSection from "../../../components/SendParcelSection";
import TicketsDisplayer from "../../../components/ticketsDisplayer";
import { ParcelContext } from "../../../context/ParcelContext";
import PackagesDisplayer from "../../../components/packagesDisplayer";
import ModalCusstom from "../../../components/ModalCustom";
import PackagesDisplayerClient from "../../../components/packagesDisplayer";

const ContainerSearchbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: Center;
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
  padding-bottom: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 20px auto;
`;
const ItemMenu = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: ${(props) =>
    props.active ? "2px solid #000" : "0.5px solid #000"};
  margin: 0 5px;
  width: 50%;
`;
const Form = styled.div`
  padding-bottom: 150px;
  background-color: #f6f6f6;
`;

const ParcelAdminClients = () => {
  const { allUsers, getAllUsersByName } = useContext(FirebaseContext);
  const { business, getAllBusinessByName } = useContext(BusinessContext);
  const {
    ticketsClients,
    selectedTicketsClients,
    addTicketClient,
    addTicketBusinessClient,
    getTicketsByBusinessIdClient,
    getTicketsByUserIdClient,
  } = useContext(ParcelContext);

  const [value, setValue] = useState("");

  const [showedTickets, setShowedTickets] = useState(null);

  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [filteredBusiness, setFilteredBusiness] = useState<IBusiness[]>([]);

  const [selectedUser, setSelectedUser] = useState<IUser>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<IBusiness>(null);

  const [activeElement, setActiveElement] = useState(MENU_ACTIVE_BUSINESS);

  const filterUsers = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
    if (e.target.value) {
      await getAllUsersByName(e.target.value.toUpperCase())
    } else {
      setFilteredUsers([]);
    }
  };
  const filterBusiness = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
    if (e.target.value) {
      await getAllBusinessByName(e.target.value.toUpperCase())
      
    } else {
      setFilteredBusiness([]);
    }
  };

  useEffect(() => {
    allUsers && value && setFilteredUsers(allUsers);
  }, [allUsers]);
  useEffect(() => {
    business && value && setFilteredBusiness(business);
  }, [business]);

  const clearSelections = () => {
    setSelectedBusiness(null);
    setSelectedUser(null);
    setShowedTickets(null);
    setValue("");
  };
  const selectBusinessFunc = (doc) => {
    setSelectedBusiness(doc);
    setFilteredBusiness([]);
  };
  const selectUserFunc = (doc) => {
    setSelectedUser(doc);
    setFilteredUsers([]);
  };

  const sendUserMessage = async (comments:string) => {
    setMessage("Creando ticket...");
    setModalStatus(true);

    const timestamp = new Date();
    const time = timestamp.getTime();
    await addTicketClient({
      reason: "Un cliente ha dejado un paquete",
      userId: selectedUser.id,
      businessId: selectedUser.businessId,
      type:'user',
      timestamp: time,
      comments:comments,
      status: "pending",
    });
    setMessage("Ticket creado con éxito");

    setTimeout(() => {
      setMessage("");
      setModalStatus(false);
    }, 1000);
  };
  const sendBusinessMessage = async (comments:string) => {
    setMessage("Creando ticket...");
    setModalStatus(true);
    const timestamp = new Date();
    const time = timestamp.getTime();

    await addTicketBusinessClient({
      reason: "Un cliente ha dejado un paquete",
      businessId: selectedBusiness.id,
      timestamp: time,
      comments:comments,
      type:'business',
      status: "pending",
    });
    setMessage("Ticket creado con éxito");

    setTimeout(() => {
      setMessage("");
      setModalStatus(false);
    }, 1000);
  };

  const getTicketsBusiness = async () => {
    await getTicketsByBusinessIdClient(selectedBusiness.id);
  };
  const getTicketsUser = async () => {
    await getTicketsByUserIdClient(selectedUser.id);
  };

  useEffect(() => {
    selectedUser && getTicketsUser();
  }, [selectedUser, ticketsClients]);

  useEffect(() => {
    selectedBusiness && getTicketsBusiness();
  }, [selectedBusiness, ticketsClients]);

  useEffect(() => {
    (selectedBusiness || selectedUser) && setShowedTickets(selectedTicketsClients);
  }, [selectedTicketsClients]);

  return (
    <Form>
      <ContainerSearchbar>
        <Row>
          <ItemMenu
            onClick={() => {
              clearSelections();
              setActiveElement(MENU_ACTIVE_BUSINESS);
            }}
            active={activeElement === MENU_ACTIVE_BUSINESS}
          >
            <Text
              weight={
                activeElement === MENU_ACTIVE_BUSINESS ? "bolder" : "normal"
              }
            >
              Empresas
            </Text>
          </ItemMenu>
          <ItemMenu
            onClick={() => {
              clearSelections();
              setActiveElement(MENU_ACTIVE_USERS);
            }}
            active={activeElement === MENU_ACTIVE_USERS}
          >
            <Text
              weight={activeElement === MENU_ACTIVE_USERS ? "bolder" : "normal"}
            >
              Usuarios
            </Text>
          </ItemMenu>
        </Row>
        {activeElement === MENU_ACTIVE_USERS && !selectedUser && (
          <SearchBarCustom
            selectFunction={selectUserFunc}
            data={filteredUsers}
            filterFunction={filterUsers}
            value={value}
          />
        )}
        {activeElement === MENU_ACTIVE_BUSINESS && !selectedBusiness && (
          <SearchBarCustom
            data={filteredBusiness}
            filterFunction={filterBusiness}
            selectFunction={selectBusinessFunc}
            value={value}
          />
        )}
        {selectedUser && (
          <SendParcelSection
            data={selectedUser}
            clearFunct={clearSelections}
            sendFunct={sendUserMessage}
          />
        )}
        {selectedBusiness && (
          <SendParcelSection
            data={selectedBusiness}
            clearFunct={clearSelections}
            sendFunct={sendBusinessMessage}
          />
        )}
      </ContainerSearchbar>
      {showedTickets && (
        <div>
          <PackagesDisplayerClient admin tickets={showedTickets?.pending} />
          <PackagesDisplayerClient admin tickets={showedTickets?.closed} />
        </div>
      )}

      <ModalCusstom message={message}  open={modalStatus} />
    </Form>
  );
};

export default ParcelAdminClients;
