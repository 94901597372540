import { getMessaging } from "firebase/messaging/sw";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyD3a5S2L7QOXdQK4zozA9DIR-0o_4FrQ_A",
  authDomain: "bmc-app-87a78.firebaseapp.com",
  projectId: "bmc-app-87a78",
  storageBucket: "gs://bmc-app-87a78.appspot.com",
  messagingSenderId: "922502980821",
  appId: "1:922502980821:web:77c6120fa7d0148272ee5c",
  measurementId: "G-F6YNXKC5ZR",
  messageKey: "BHLSGWWoKikPtNjSZeZSPul_R5iBczOoVTx9Bu3YHOHrLU_iM5A6gKV_QPpvxk93eRaxgHUGjefpBQZ2ZcoLmSQ",
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
