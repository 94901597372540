import { useContext } from "react";
import styled from "@emotion/styled";
import Layout from "../../components/Layout";
import { FirebaseContext } from "../../context/FirebaseContext";
import SectionHeader from "../../components/SectionHeader";
import { CardRow } from "../Services";
import CardWithImages from "../../components/CardWithImages";
const Container = styled.div`
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Account = () => {
  const { permissions, user } = useContext(FirebaseContext);

  const sections = [
    {
      title: "MI PERFIL",
      subtitle: "Modificar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/my-profile%2Fdes_miPerfil.jpg?alt=media&token=fa6c134d-190c-4441-be8b-28fee3fe57b4",
      route: "/my-profile",
    },
    user?.responsable && {
      title: "MIS EVENTOS",
      subtitle: "Gestionar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fdes_eventos.jpg?alt=media&token=56e6544b-d078-4fae-b882-3ac6309c8bb0",
      route: "/my-events",
    },

    {
      title: "PRIVACIDAD",
      subtitle: "Consultar",
      img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/my-profile%2Fdes_infoLegal.jpg?alt=media&token=70b1e02a-34ed-4e88-89bf-c842c7693619",
      route: "/info-legal",
    },
  ];

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/account%2Fdistribuidora%2Fcabecera_miCuenta.jpg?alt=media&token=cba276da-2914-479b-b21e-8592d558e717"
          title="MI CUENTA"
          subtitle="Todos tus datos en un mismo espacio. Consulta tus notificaciones y todos tus servicios."
          showBackButton
        />
        <CardRow>
          {permissions && (
            <CardWithImages
              key={`id-/signup`}
              item={{
                title: "ADMINISTRADOR",
                subtitle: "Gestionar",
                img: "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/account%2Fdistribuidora%2Fdes_administracion.jpg?alt=media&token=ccb908b1-9be6-4b99-a112-4fe12af4a658",
                route: "/admin",
              }}
            />
          )}
          {sections.map((section, i) => {
            return <CardWithImages key={`id-${section?.route}-${i}`} item={section} />;
          })}
        </CardRow>
      </Container>
    </Layout>
  );
};

export default Account;
