import styled from "@emotion/styled";
import { addDoc, collection } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import InputButtonWithProps from "../../components/InputButton";
import InputWithLegend from "../../components/InputWithLegend";
import Layout from "../../components/Layout";
import LazyImage from "../../components/LazyImage";
import ModalCusstom from "../../components/ModalCustom";
import SectionHeader from "../../components/SectionHeader";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Text } from "../../genericComponents";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
  min-height: 100vh;
`;

const InnerContainer = styled.form`
  /* width: 100%; */
  padding: 40px 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:auto;
`;

const FooterImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 30px;
  z-index: 10;
 
`;

const Invitation = () => {
  const [detailsInvitation, setDetailsInvitation] = useState({
    name: "",
    email: "",
  });
  const [messageModal, setMessageModal] = useState("");
  const { db } = useContext(FirebaseContext);
  const navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessageModal("Enviando invitación...");
    await addDoc(collection(db, "temporalAccess"), {
      email: detailsInvitation.email,
      name: detailsInvitation.name,
    });
    setMessageModal("Invitación enviada.");
    setTimeout(() => {
      setMessageModal("");
      navigation("/");
    }, 2000);
  };

  const handleChange = (e) => {
    setDetailsInvitation({ ...detailsInvitation, [e.target.name]: e.target.value });
  };

  return (
    <Layout>
      <Container>
        <SectionHeader src="/car.png" title="INVITACIÓN" subtitle="Envía por mail invitaciones a socios, clientes o colaboradores de manera rápida y sencilla." showBackButton />

        <InnerContainer onSubmit={handleSubmit}>
          <InputWithLegend type="text" name="name" required={true} legendColor="#5F5F5F" onChange={handleChange} value={detailsInvitation.name} title={"Introducir el nombre del invitado"} />
          <InputWithLegend type="email" name="email" required={true} legendColor="#5F5F5F" onChange={handleChange} value={detailsInvitation.email} title={"Introducir mail del invitado"} />
          <InputButtonWithProps disabled={!detailsInvitation.name || !detailsInvitation.email} type="submit" text="Enviar invitación" />
        </InnerContainer>
        <ModalCusstom message={messageModal} open={messageModal.length > 0} />
        <FooterImageContainer>
          <LazyImage animation="wave" variant="rectangular" width="100%" height="160px" src={"/car.png"} />
        </FooterImageContainer>
      </Container>
    </Layout>
  );
};

export default Invitation;
